import React, { Component } from 'react';
import './loader.scss'

class Loader extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    render() {
        return (
            <div className="loader-wrapper">
                <div className="loader-box alert alert-primary">
                    <div className="text-center mb-2">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    <small>{this.props.message}</small>
                </div>
            </div>
        )
    }
}

export default Loader;