import React from 'react';
import './App.scss';
import { Provider } from 'react-redux';
import store from './feature/store';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ProtectedRoute } from './services/protected.route';

// ------------ auth
import Login from './component/pages/login/login';
import Signup from './component/pages/signup/signup';
// ------------ shared
import Header from './component/shared/header/header';
import Footer from './component/shared/footer/footer';

// ------------ independent
import Home from './component/pages/home/home';
import Offers from './component/pages/offers/offers';
import About from './component/pages/aboutus/aboutus';
import Careers from './component/pages/careers/careers';
import Contact from './component/pages/contactus/contactus';
import TermsConditions from './component/pages/termsconditions/termsconditions';
import AllCourses from './component/pages/allcourses/allcourses';

// ------------------- course
import CourseDetails from './component/pages/CourseDetails/CourseDetails';
import Categories from './component/pages/categories/categories';
// import CourseEdit from './component/pages/CourseEdit/CourseEdit';
import CourseListing from './component/pages/Listing/CourseListing/CourseListing';

// ------------------- others
import Courseplayer from './component/pages/courseplayer/courseplayer';
import Accountsettings from './component/pages/accountsettings/accountsettings';

import Demo from './component/pages/demo/demo';
import BlogWriterDemo from './component/pages/demo/BlogWriterDemo';



import Learning from './component/pages/learning/learning';
import Beteacher from './component/pages/beteacher/beteacher';
import Courses from './component/pages/courses/courses';
import Video from './component/pages/demo/video';
import HelpSupport from './component/pages/helpsupport/helpsupport';
import Affiliate from './component/pages/affiliate/affiliate';
import FeaturedCourse from './component/pages/featuredcourse/featuredcourse';
import Sitemap from './component/pages/sitemap/sitemap';
import Privacypolicy from './component/pages/privacypolicy/privacypolicy';
import ForgotPassword from './component/pages/forgotPassword/forgotPassword';
import UpdatePassword from './component/pages/updatePassword/updatePassword';
import SetPassword from './component/pages/setPassword/setPassord';
import ApforLearner from './component/pages/apforlearner/apforlearner';
import Certificate from './component/pages/accountsettings/certificate/certificate';
import LearnerSurvey from './component/pages/learnersurvey/learnersurvey';
import ActionPlan from './component/pages/actionplan/actionplan';
import GoogleSignUpTermsAndCondition from './component/pages/googleSignUpTermsAndCondition/googleSignUpTermsAndCondition';
import ChangePassword from './component/pages/ChangePassword/ChangePassword'
import Wishlist from './component/pages/wishlist/wishlist';



const App = () => {
  return (
    <Provider store={store}>
      <Header></Header>
      <Router>
        <Switch>
          <Route exact path="/" component={() => (<Home />)} />
          <Route exact path="/home" component={() => (<Home />)} />
          <Route exact path="/categories" component={() => (<Categories />)} />
          <Route exact path="/upvideo" component={() => (<Video />)} />

          {/* acccount settings */}
          <ProtectedRoute path="/account/:id" component={() => (<Accountsettings />)} />
          <Route exact path="/offers" component={() => (<Offers />)} />

          {/* course routes */}
          <Route exact path="/search" component={() => (<CourseListing />)} />
          <Route exact path="/courseDetails/:id" component={() => (<CourseDetails />)} />
          <ProtectedRoute exact path="/courseplayer/:id" component={() => (<Courseplayer />)} />
          {/* <ProtectedRoute exact path="/courseEdit/:id" component={() => (<CourseEdit />)} /> */}

          <Route exact path="/learning" component={() => (<Learning />)} />
          <Route exact path="/be-a-teacher" component={() => (<Beteacher />)} />
          <Route exact path="/courses" component={() => (<Courses />)} />

          <Route exact path="/demo" component={() => (<Demo />)} />
          <Route exact path="/BlogWriterDemo" component={() => (<BlogWriterDemo />)} />

          <ProtectedRoute exact path="/offers" component={() => (<Offers />)} />
          <Route exact path="/aboutus" component={() => (<About />)} />
          <Route exact path="/careers" component={() => (<Careers />)} />
          <Route exact path="/contactus" component={() => (<Contact />)} />
          <Route exact path="/termsconditions" component={() => (<TermsConditions />)} />
          <Route exact path="/all-courses" component={() => (<AllCourses />)} />
          <Route exact path="/helpsupport" component={() => (<HelpSupport />)} />
          <Route exact path="/affiliate" component={() => (<Affiliate />)} />
          <Route exact path="/featuredcourse" component={() => (<FeaturedCourse />)} />
          <Route exact path="/sitemap" component={() => (<Sitemap />)} />
          <Route exact path="/privacypolicy" component={() => (<Privacypolicy />)} />
          <Route exact path="/apforlearner" component={() => (<ApforLearner />)} />
          <Route exact path="/certificate/:id" component={() => (<Certificate />)} />
          <Route exact path="/learnersurvey" component={() => (<LearnerSurvey />)} />
          <Route exact path="/actionplan/:id" component={() => (<ActionPlan />)} />
          <Route exact path="/wishlist" component={() => (<Wishlist />)} />


          {/* <Redirect from="/" exact to="/home" /> */}
          <Route from="**" to="/not-found" />
        </Switch>
        <Route exact path="/login" component={() => (<Login />)} />
        <Route exact path="/forgotPassword" component={() => (<ForgotPassword />)} />
        <Route exact path="/reset_pw" component={() => (<UpdatePassword />)} />
        <Route exact path="/signup" component={() => (<Signup />)} />
        <Route exact path="/set_pw" component={() => (<SetPassword />)} />
        <Route exact path="/terms_condtion" component={() => (<GoogleSignUpTermsAndCondition />)} />
        <Route exact path="/read_terms_and_condtion" component={() => (<TermsConditions />)} />
        <ProtectedRoute exact path="/changePassword" component={() => (<ChangePassword />)} />
      </Router>
      <Footer></Footer>
    </Provider>
  );
}

export default App;
