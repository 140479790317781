import { GET_USER, REMOVE_USER, UPDATE_USER } from './UserTypes';

const userState = {
    profile: null
}

const UserReducer = (state = userState, { type, payload }) => {

    switch (type) {
        case GET_USER: return {
            ...state,
            profile: payload
        }
        case UPDATE_USER: return {
            ...state,
            profile: payload
        }
        case REMOVE_USER: return {
            profile: null
        }
        default: return state

    }
}

export default UserReducer;