import React from 'react';
import './beteacher.scss';
import api from '../../../services/Api/Api';
import { Modal, ApiModal } from '../../shared/modal/modal';
import Loader from '../../shared/loader/loader';
import { v4 as uuidv4 } from 'uuid';

class Beteacher extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      loader: false,
      resMessage: '',
      resType: '',
      resTitle: '',
      resCode: '',

      courseCategoryList: [
        "Functional (Ex: Project Management, Finance etc)",
        "Technical(Ex: J2EE, Metallurgy, etc)",
        "Professional(Ex: Customer Service, Sales, etc)",
        "Soft Skills",
        "Others"
      ],
      workTypeExp: '',
      workYearExp: '',
      courseCategory: [],
      otherSpecify: '',
      linkedInUrl: ''
    }
  }

  sendRequest(e) {
    e.stopPropagation();
    e.preventDefault();
    let category_list = [...this.state.courseCategory];
    if (category_list.includes("Others")) { category_list.push(this.state.otherSpecify) }
    let reqData = {
      reqObj: {
        'workTypeExp': this.state.workTypeExp,
        'yearsExp': this.state.workYearExp,
        // 'courseCategories': this.state.courseCategory !== 'Others' ? this.state.courseCategory : this.state.otherSpecify, // check array
        'courseCategories': category_list, // check array
        'about': this.state.message,
        'linkedIn':this.state.linkedInUrl
      },
      url: window.location.origin
    }
    this.setState({
      loader: true,
      resMessage: '',
      resType: '',
      resTitle: '',
      resCode: ''
    }, () => {
      api.requestAuthor(reqData).then(res => {
        if (res.data.resCode === 200) {
          this.setState({
            message: '',
            loader: false,
            resMessage: res.data.resMessage,
            resType: res.data.resType,
            resTitle: res.data.resTitle,
            resCode: res.data.resCode,

            workTypeExp: '',
            workYearExp: '',
            courseCategory: '',
            otherSpecify: '',
          })
        } else if (res.data.resCode === 400) {
          api.logout().then(res => {
            window.location.replace('/login')
          })
        } else {
          this.setState({
            loader: false,
            resMessage: res.data.resMessage,
            resType: res.data.resType,
            resTitle: res.data.resTitle,
            resCode: res.data.resCode
          })
        }
      }).catch(err => {
        this.setState({
          loader: false,
          resMessage: 'Something went wrong. Please reload the page',
          resType: 'danger',
          resTitle: 'Error',
        })
      })
    })

  }

  render() {
    const loader = this.state.loader ? <Modal><Loader /></Modal> : null;
    const apiModal = this.state.resMessage !== '' ? <Modal>
      <ApiModal
        dismiss={() => { this.setState({ resMessage: '', resType: '', reload: '', resTitle: '' }) }}
        modalData={{
          resMessage: this.state.resMessage,
          resType: this.state.resType,
          resTitle: this.state.resTitle,
          loader: false,
        }}
      />
    </Modal> : null;
    return (
      <React.Fragment>
        <div className="page-content beanAuthor">
          <div className="fluid-banner beateacher-banner"><h1>Be an Author</h1></div>
          <div className="container">
            {/* <h3 className="comingsoon text-danger">Become An Author </h3>
            <p className="comingsoonp">Tell us about you</p> */}
            <div className="row">
              <div className="col-md-10 mx-auto">
                <form onSubmit={(e) => { this.sendRequest(e) }} className="form-beteacher">
                  <h3>Thank you for your interest in being a Course Author.</h3>
                  <p>We would love to get to know you before you start making your own awesome courses on our platform. So, go ahead and fill this form with your contact details, and we will reach out to you soon.</p>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <div className="mb-3">
                          <label htmlFor="twex" className="form-label">Type of Work Experience <span className="mandt-symbol">*</span></label>
                          <span className="select-only">
                            <select id="twex" className="form-control" onChange={(e) => { this.setState({ workTypeExp: e.target.value }) }}>
                              <option value="">Select</option>
                              <option value="Practitioner">Practitioner</option>
                              <option value="Academic">Academician</option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <div className="mb-3">
                          <label htmlFor="ywe" className="form-label">Years of Work Experience <span className="mandt-symbol">*</span></label>
                          <span className="select-only">
                            <select id="ywe" className="form-control" onChange={(e) => { this.setState({ workYearExp: e.target.value }) }}>
                              <option value="">Select</option>
                              <option value=">15years (Sr Practitioner/ Academician)"> &gt;15years (Sr Practitioner/ Academician)</option>
                              <option value="8-14yrs (Practitioner/ Academician)">8-14yrs (Practitioner/ Academician)</option>
                              <option value="<10Years (Millennial Practitioner/ Millennial Academician) ">&lt; 10Years (Millennial Practitioner/ Millennial Academician) </option>
                            </select>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <div className="mb-3">
                          <label htmlFor="pcc" className="form-label">Preferred course categories <span className="mandt-symbol">*</span></label>
                          <div className="multi-select">
                            <ul>

                              {
                                this.state.courseCategoryList.map((item, index) => {

                                  return <li key={uuidv4()}>
                                    <div className="form-check">

                                      <input className="form-check-input" type="checkbox"
                                        checked={this.state.courseCategory.includes(item)} id={"cat" + index}
                                        onChange={() => {
                                          if (this.state.courseCategory.includes(item)) {
                                            let cc_list = [...this.state.courseCategory];
                                            cc_list.splice(cc_list.indexOf(item), 1);
                                            this.setState({
                                              courseCategory: cc_list
                                            })
                                          } else {
                                            let cc_list2 = [...this.state.courseCategory];
                                            cc_list2.push(item);
                                            this.setState({
                                              courseCategory: cc_list2
                                            })
                                          }
                                        }}
                                      />
                                      <label className="form-check-label" htmlFor={"cat" + index}>{item}</label>
                                    </div>
                                  </li>
                                })
                              }

                            </ul>
                          </div>
                          {/* <select id="pcc" className="form-control" onChange={(e) => {
                      console.log('e.target.value', e.target.value);
                      this.setState({ courseCategory: e.target.value })
                    }} multiple>
                      <option value="">Select</option>
                      <option value="Functional"> Functional</option>
                      <option value="Technical"> Technical</option>
                      <option value="Professional"> Professional</option>
                      <option value="Soft Skills"> Soft Skills</option>
                      <option value="Others"> Others</option>

                    </select> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <div className="mb-3">
                          <label htmlFor="brief" className="form-label">Why do you want to be an Author with us? <span className="mandt-symbol">*</span></label>
                          <textarea className="form-control textarea-only" value={this.state.message} onChange={(e) => { this.setState({ message: e.target.value }) }} maxLength="200" placeholder="Ex: Education, Skills, Strong Knowladge etc." />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12">
                      {this.state.courseCategory.includes("Others") &&
                        <div className="mb-3">
                          <label htmlFor="ps" className="form-label">Please Specify <span className="mandt-symbol">*</span></label>
                          <input id="ps" type="text" className="form-control" value={this.state.otherSpecify} onChange={(e) => { this.setState({ otherSpecify: e.target.value }) }} />
                        </div>
                      }
                    </div>
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="mb-3">
                          <label htmlFor="brief" className="form-label">Help us get to know you, through your LinkedIn page <span className="mandt-symbol">*</span></label>
                          <input className="form-control link-text" type="text" placeholder="Copy and Paste your LinkedIn URL here" value={this.state.linkedInUrl} onChange={(e) => { this.setState({ linkedInUrl: e.target.value }) }} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="button-block">
                    <button type="submit" className="btn btn-primary"
                      disabled={
                        this.state.workTypeExp === '' ||
                        this.state.workYearExp === '' ||
                        this.state.courseCategory === '' ||
                        (this.state.courseCategory === 'Others' && this.state.otherSpecify === '') ||
                        this.state.message === ''
                      }
                    >Submit</button>
                  </div>
                  {/* Multi select category
                      Brief with word limit
                  */}

                </form>
              </div>
            </div>
          </div>
        </div>
        {loader}
        {apiModal}
      </React.Fragment>
    );
  }
}

export default Beteacher;