import React, { Component } from 'react';
import './privacypolicy.scss';

class Privacypolicy extends Component {
    render() {
        return (
            <div className="page-content">
                <div class="fluid-banner"><h1>Privacy Policy</h1></div>
                <div className="container inner-content">
                    <p>Coming Soon...</p>
                </div>
            </div>
        )
    }
}

export default Privacypolicy;