import React, { Component } from 'react';
import './adminCourseList.scss';
import { Modal, ApiModal } from '../../../shared/modal/modal';
import Loader from '../../../shared/loader/loader';

import api from '../../../../services/Api/Api';
import { v4 as uuidv4 } from 'uuid';
import Card from '../../../shared/Card/CardApi';

class AdminCourseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            resMessage: '',
            resType: '',

            courseId: '',
            createNew: false,
            newTitle: "",
            myCourses: [],
            userType: '',
            total: 0,

            formdata: [],
            title: '',
            content: {},
            poster: '',
            price: 0,
            isContent: true,
            objectiveCurr: '',
            objectivArr: [],

            libraryTitle: '',
            mediaLibrary: null,
            error: false,
            errorData: {}

        }
    }

    componentDidMount() {
        this.getCourses();
    }


    getCourses() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.courseSearch({ query: { status: 'submitted' } }).then(res => {

                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        myCourses: res.data.data.courses,
                        // total: res.data.data.total
                    }, () => {
                        console.log('myCourses', this.state.myCourses);
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    });
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                console.log('get my courses error', err);
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            })
        })
    }


    getMedia(val, stateId) {
        api.getFileFromDrive(val).then(res => {
            if (!document.getElementById(stateId).src) {
                if (res.data.resCode === 200) {
                    document.getElementById(stateId).src = res.data.data.webContentLink;
                } else {
                    document.getElementById(stateId).src = ''
                    document.getElementById(stateId).style.opacity = 0;
                }
            }
        }).catch(err => {
            console.log('loader err of ' + stateId, ': ', err)
        })
    }


    render() {

        const loader = this.state.loader ? <Loader message={"Loading..."} /> : null;

        const apiDialog = this.state.resMessage !== '' ?
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className={`alert alert-${this.state.resType}`}>
                        <p>{this.state.resMessage}</p>
                        <i className="bi bi-x-square-fill position-absolute top-0 end-0" onClick={() => this.setState({ resMessage: '', resType: '' })}></i>
                    </div>
                </div>
            </div>
            : null;



        const apiModal = this.state.error ? <Modal>
            <ApiModal
                dismiss={(e) => this.setState({ error: false, errorData: {} })}
                modalData={this.state.errorData}

            /> </Modal> : null;
        return (
            <React.Fragment>
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title">
                                <h2>My Courses</h2>
                                {/* <h6>Add your new course or edit your existing course.</h6> */}
                            </div>
                        </div>

                    </div>
                    {loader}
                    {apiDialog}
                </div>
                <div className="page-body">
                    <h3>Contents</h3>
                    <div className="row">
                        {this.state.myCourses.map(item => {


                            return <div className="col-sm-3" key={uuidv4()}><Card id={item._id} /></div>



                        })}

                    </div>
                </div>
                {apiModal}
            </React.Fragment>
        )
    }
}

export default AdminCourseList;