import React from 'react'
import "./actionplan.scss";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const Preview = ({ setDisplayPreview, actionPlanData: { title, keyLearning, actionPlan, requiredResource, successCriteria, recipientMail } }) => {

    function printDocument() {
        const input = document.getElementById('divToPrint');
        html2canvas(input)
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0);
                // pdf.output('dataurlnewwindow');
                pdf.save("Action_Plan.pdf");
            })
            ;
    }
    return (
        <div className="modal actionplan-modal" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content add-review">
                    <div id="divToPrint">
                        <div className="modal-header">
                            <div className="modal-title">
                                <h4><strong>Plan Title:</strong> {title}</h4>
                            </div>
                            <button type="button" onClick={() => setDisplayPreview(false)} className="btn-close btn-sm" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body add-review-body">
                            <div className="table-responsive">
                                <table className="table align-middle table-bordered" style={{ fontSize: "13px", color: "#242424" }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ width: "30%", fontSize: "16px", fontWeight: "normal", backgroundColor: "#242424", color: "#fff", border: "1px solid #484848" }}>Key Learning</th>
                                            <td style={{ verticalAlign: "middle" }}>{keyLearning}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "30%", fontSize: "16px", fontWeight: "normal", backgroundColor: "#242424", color: "#fff", border: "1px solid #484848" }}>Action Plans</th>
                                            <td style={{ verticalAlign: "middle", backgroundColor: "#f2f2f2" }}>{actionPlan}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "30%", fontSize: "16px", fontWeight: "normal", backgroundColor: "#242424", color: "#fff", border: "1px solid #484848" }}>Required Resources</th>
                                            <td style={{ verticalAlign: "middle" }}>{requiredResource}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "30%", fontSize: "16px", fontWeight: "normal", backgroundColor: "#242424", color: "#fff", border: "1px solid #484848" }}>Success Criteria</th>
                                            <td style={{ verticalAlign: "middle", backgroundColor: "#f2f2f2" }}>{successCriteria}</td>
                                        </tr>
                                        <tr>
                                            <th style={{ width: "30%", fontSize: "16px", fontWeight: "normal", backgroundColor: "#242424", color: "#fff", border: "1px solid #484848" }}>Recipient Email</th>
                                            <td style={{ verticalAlign: "middle" }}>{recipientMail}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button type="button" onClick={printDocument} className="btn btn-warning"><i class="bi bi-file-earmark-arrow-down"></i> Download</button>
                        <button type="button" className="btn btn-primary"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                            <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                        </svg> Send</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Preview