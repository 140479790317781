import React from 'react';
import './learning.scss';

function Learning() {
    return (
      <div className="page-content">
         <div className="container">
             <h3 className="comingsoon text-danger">Learning Page: Contents coming soon...</h3>
             <p className="comingsoonp">Thanks for your patience.</p>
         </div>
      </div>
    );
  }

export default Learning;