import React, { useState } from 'react';
import { courseRatings, courseReviewQuestions, mapEmotionToImage } from '../../../utils/constant';
import api from '../../../services/Api/Api';
import './RatingComp.scss';

const RatingComp = (props) => {
  let [reviewText, setReviewText] = useState('');
  let [ratingNumbers, setRatingNumbers] = useState(courseRatings);

  function onClickSubmit() {
    let ratingQuesArr = [];
    for (let i = 0; i < ratingNumbers.length; i++) {
      let obj = {}
      obj['id'] = i;
      obj['title'] = courseReviewQuestions[i];
      obj['rating'] = ratingNumbers[i];
      ratingQuesArr.push(obj);
    }
    const ratingData = {
      coursId: props.course._id,
      ratingValue: ratingNumbers[0],
      overallRating: ratingQuesArr,
      comment: reviewText,
    };
    api.addRating(ratingData).then(res => {
      props.ratingUpdate();
    }).catch(err => console.log("addrating error = ", err))

  }

  function onClickRatingEmoticon(quesIndex, rating) {
    let updatedRating = [...ratingNumbers];
    updatedRating[quesIndex] = rating;
    setRatingNumbers(updatedRating);
  }
  return (
    <React.Fragment>
      <div
        className='modal course-review-modal'
        id='staticBackdropLive'
        data-bs-backdrop='static'
        data-bs-keyboard='false'

        aria-labelledby='staticBackdropLabel'
        aria-hidden='true'
      >
        <div className='modal-dialog modal-lg modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>
                Please give us your valuable feedback
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
                onClick={() => props.closeReviewModal()}
              ></button>
            </div>
            <div className='modal-body add-review-body'>
              {courseReviewQuestions.map((question, index) => (
                <div key={`reviews-question-${index}`} className='option-block'>
                  <h5>{question}</h5>
                  <div className='ratings-block'>
                    {[...Array(5).keys()].map((_, i) => (

                      <button key={`emoticons-index-${index}-${i}`} onClick={() => onClickRatingEmoticon(index, i + 1)} type='button' className={ratingNumbers[index] === i + 1 ? 'btn-filled-rating' : 'btn-rating'}>
                        <img src={mapEmotionToImage(i + 1)} alt="///"></img>
                      </button>
                    ))}
                  </div>
                </div>
              ))}

              <div className='option-block'>
                <h5>We are constantly looking to improve and better the Learner experience on our learning platform, so we would love to hear from you.</h5>
                <div className='ratings-block'>
                  <textarea
                    onChange={(e) => setReviewText(e.target.value)}
                    className='form-control'
                    placeholder='Your comments'
                    rows={4}
                  ></textarea>
                </div>
              </div>
              <div className='option-block'>
                <button
                  type='button'
                  className='btn btn-primary'
                  disabled={ratingNumbers.some(item => item === 0) || !((/^(?!\s*$).+/).test(reviewText))}
                  onClick={onClickSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default RatingComp;
