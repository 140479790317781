import React, { Component } from 'react';
import './contactus.scss';

class Contact extends Component {
    render() {
        return (
            <div className="page-content">
                <div class="fluid-banner"><h1>Contact Us </h1></div>
                <div className="container inner-content">
                    <h4><strong>Contact us on:</strong> info@axllearn.com</h4>
                </div>
            </div>
        )
    }
}

export default Contact;