import React, { useState, useEffect } from 'react';
import Rating from '@material-ui/lab/Rating';
import api from '../../../services/Api/Api';

const Reviewer = (props) => {

    const [reviewerProfile, set_reviewerProfile] = useState(null);


    useEffect(() => {
        api.getProfileById(props.reviewData.userId).then((res) => {
            if (res.data.resCode === 200) {
                set_reviewerProfile(res.data.data)
            }
        }).catch(err => {
            console.log('getting profile error in reviewer comp', err)
        })
    }, []);
    return (
        <React.Fragment>
            <div className="reviewBlock">
                <div className="rb-left">
                    <span>Img</span>
                </div>
                <div className="rb-right">
                    <figure>
                        <h6><Rating name="size-small" defaultValue={props.reviewData.value} size="small" readOnly/></h6>
                        <blockquote class="blockquote">
                            <p>{props.reviewData.review}</p>
                        </blockquote>
                        <figcaption class="blockquote-footer">
                            {reviewerProfile !== null &&
                                <cite title="Source Title">
                                    {reviewerProfile.profile.firstName ? reviewerProfile.profile.firstName : 'Guest'}
                                    {reviewerProfile.profile.lastName ? reviewerProfile.profile.lastName : ''}
                                </cite>
                            }
                        </figcaption>
                    </figure>
                </div>
            </div>
        </React.Fragment>
    )

}

export default Reviewer;