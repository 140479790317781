import React, { Component } from 'react';
import './apforlearner.scss';
import authorImg from '../../../assets/images/author-img1.jpg';

class ApforLearner extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div class="fluid-banner"><h1>Author's Profile</h1></div>
                    <div className="container">
                        <div className="apfor-learner">
                            <div className="page-body">
                                <div className='ap-section1'>
                                    <div className='row'>
                                        <div className='col-sm-3'>
                                            <div class="card profile-img">
                                                <img src={authorImg} alt="///"></img>
                                            </div>
                                        </div>
                                        <div className='col-sm-6'>
                                            <div className='about-author'>
                                                <h4>Mrs. A. T. Whitecotton</h4>
                                                <h5>Full Stack Developer, 12 years Professional Experience </h5>
                                                <div className='review-courses'>
                                                    <div className='row'>
                                                        <div className='col-sm-4'>
                                                            <div className='t-courses'>
                                                                <h4>Total Courses</h4>
                                                                <p>76 Courses</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <div className='reviews'>
                                                                <h4>Learners</h4>
                                                                <p>1,033</p>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-4'>
                                                            <div className='reviews'>
                                                                <h4>Avarage Ratings</h4>
                                                                <p><i class="bi bi-star-fill"></i> 4.6</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-sm-3'>
                                            <div className="card card-social-link">
                                                <ul className='social-link'>
                                                    <li><a href="/#" className='linkedin'><i class="bi bi-linkedin"></i> LinkedIn</a></li>
                                                    <li><a href="/#" className='twitter'><i class="bi bi-twitter"></i> Twitter</a></li>
                                                    <li><a href="/#" className='youtube'><i class="bi bi-youtube"></i> Youtube</a></li>
                                                    <li><a href="/#" className='instagram'><i class="bi bi-instagram"></i> Instagram</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='ap-section2'>
                                    <div className="card axl-card">
                                        <div className="card-body bg-tranparent">
                                            <div className="row">
                                                <div className="col-sm-8">
                                                    <div className="general-info">
                                                        <h4>About Mrs. A.T. Whitecotton</h4>
                                                        <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain.</p>
                                                        <p>These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided.</p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="author-skills">
                                                        <ul>
                                                            <li>Python</li>
                                                            <li>Java</li>
                                                            <li>jQuery</li>
                                                            <li>NodeJS</li>
                                                            <li>ExpressJS</li>
                                                            <li>HTML5</li>
                                                            <li>CSS3</li>
                                                            <li>MongoDB</li>
                                                            <li>MySql</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="best-course">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <h4>Most Viewed Courses</h4>
                                            <p>Coming Soon ...</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default ApforLearner;