import React, { Component } from 'react';
import './wishlist.scss';

class Wishlist extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="page-content bg-smild-grey">
                <div class="fluid-banner"><h1>Wishlisted Course</h1></div>
                    <div className="container">
                        <div className='wishlist-courses'>
                            <div className='row'>
                                <div className='col-md-3'>
                                    <div className="card">
                                        <img src="..." className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h4 className='mb-2'>Course title</h4>
                                            <p className="card-text mb-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" className="btn btn-primary">View Course</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card">
                                        <img src="..." className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h4 className='mb-2'>Course title</h4>
                                            <p className="card-text mb-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" className="btn btn-primary">View Course</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card">
                                        <img src="..." className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h4 className='mb-2'>Course title</h4>
                                            <p className="card-text mb-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" className="btn btn-primary">View Course</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card">
                                        <img src="..." className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h4 className='mb-2'>Course title</h4>
                                            <p className="card-text mb-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" className="btn btn-primary">View Course</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card">
                                        <img src="..." className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h4 className='mb-2'>Course title</h4>
                                            <p className="card-text mb-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" className="btn btn-primary">View Course</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className="card">
                                        <img src="..." className="card-img-top" alt="..." />
                                        <div className="card-body">
                                            <h4 className='mb-2'>Course title</h4>
                                            <p className="card-text mb-3">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                                            <a href="#" className="btn btn-primary">View Course</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Wishlist;