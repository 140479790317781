import React, { useEffect, useState } from 'react';
import './googleSignUpTermsAndCondition.scss';

import axllearnlogo from '../../../assets/images/logo-black.png';
import { Modal } from '../../shared/modal/modal';
import Loader from '../../shared/loader/loader';

import Api from '../../../services/Api/Api';
import { Checkbox } from '@material-ui/core';


const GoogleSignUpTermsAndCondition
    = () => {

        const [checkBoxChecked, setCheckBoxChecked] = useState(false);
        const [userDetails, setUserDetails] = useState(null);
        const [isLoading, setisLoading] = useState(false);


        useEffect(() => {
            const token = window.location.href.split('=')[1];
            if (token) {
                const body = { pwtoken: token }
                checkTermsAndConditionLink(body)
            }
        }, [])

        async function checkTermsAndConditionLink(payload) {
            try {
                setisLoading(true);
                const res = await Api.checkTermsAndConditionLinkValidity(payload);
                setUserDetails(res.data.userDetails)

            } catch (error) {

            } finally {
                setisLoading(false);
            }
        }


        async function signUpWithGoogle() {

            try {
                setisLoading(true)
                const res = await Api.signUpWithGoogle(userDetails);
                if (res.data.resCode === 200)
                    window.location.replace('/')

            } catch (error) {

            } finally {
                setisLoading(false);
            }

        }

        const loader = () => {
            return (
                isLoading && <Modal>
                    <Loader message={"Loading..."} />
                </Modal>

            )
        }
        return (
            <React.Fragment>
                {loader()}
                <div className='signupContainer googleSignup'>
                    <div className="innerContainer">
                        <div className="signupBox">
                            <a href="/#" className="signupLogo">
                                <img src={axllearnlogo} alt="///"></img>
                            </a>
                            <div className="signinForm">
                                <div className='gtermsC'>
                                    <div className='form-check'>
                                        {userDetails && (<>  <Checkbox checked={checkBoxChecked} onClick={() => setCheckBoxChecked(!checkBoxChecked)} />
                                            <label className="form-check-label">Accept Axllearn</label>
                                            <a href="/read_terms_and_condtion" target="_blank" rel="noreferrer noopener">
                                                Terms &amp; Condition
                                            </a>
                                            <div className="fieldRow accept-signup">
                                                <button disabled={!checkBoxChecked} onClick={() => signUpWithGoogle()} className='btn btn-primary'>SIGN IN</button>
                                            </div></>)}
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </React.Fragment>




        )
    }

export default GoogleSignUpTermsAndCondition


// const apiDialog = this.state.resMessage !== '' ? <Modal>
//             <div className={`text-center alert alert-${this.state.resType}`} style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, width: '200px', height: '200px', margin: 'auto', zIndex: 11 }}>
//                 <p>{this.state.resMessage}</p>
//                 <br />
//                 <button type="button" className={`btn btn-${this.state.resType}`} onClick={() => { this.setState({ resMessage: '', resType: '' }) }}>Okay</button>
//             </div>
//         </Modal> : null;

//         const loader = this.state.isLoading ? <Modal>
//             <Loader message={"Loading..."} />
//         </Modal> : null;