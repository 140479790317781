import React from 'react';
import './courses.scss';

function Courses() {
    return (
      <div className="page-content">
         <div className="container">
             <h3 className="comingsoon text-danger">Courses Page: Contents coming soon...</h3>
             <p className="comingsoonp">Thanks for your patience.</p>
         </div>
      </div>
    );
  }

export default Courses;