import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../services/Api/Api';

export class MediaLibrary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            media: null
        }
    }

    componentDidMount() {
        this.setState({
            media: this.props.media
        })
    }

    removeVideo(info) {
        console.log('info', info, 'removevideo');
        this.setState({
            video_loader: true,
            video_saved: false,
            vides_resMessage: ''
        }, () => {
            let reqObj = {
                courseId: info.courseId,
                data: {
                    chapter_id: info.chapter,
                    video_name: info.name
                }
            }
            api.removeVideo(reqObj).then(res => {
                console.log('remove video res', res);
                if (res.data.resCode === 200) {
                    this.setState(prev => ({
                        media: {
                            ...prev.media,
                            videos: [...prev.media.videos, prev.media.videos[prev.media.videos.map(ve => { return ve.name }).indexOf(info.name)] = '']
                        },

                        video_loader: false,
                        video_saved: true,
                    }), () => {
                        setTimeout(() => {
                            this.setState({
                                video_saved: false
                            })
                        }, 2000);
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        video_loader: false,
                        vides_resMessage: res.data.resMessage
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    video_loader: false,
                    vides_resMessage: "Something went wrong! Please try again"
                });
            })
        })

    }

    fileRemove(type, data) {

        console.log('remvoe file');
        api.fileRemove(data).then(res => {
            if (type === 'poster') {
                let courseData = {
                    poster: ''
                };
                api.updateCourse({ data: courseData, courseId: this.props.courseId }).then(res => {
                    if (res.data.resCode === 200) {
                        this.setState(prev => ({
                            media: {
                                ...prev.media,
                                poster: ''
                            },
                        }))

                    }
                    if (res.data.resCode !== 200 && res.data.resCode !== 400) {

                    }
                    if (res.data.resCode === 400) {
                        api.logout().then(res => {
                            window.location.replace('/login')
                        })
                    }
                }).catch(err => {
                    this.setState({
                        loader: false,
                        resMessage: "Something went wrong! Please try again",
                        resType: "danger"
                    });
                });
            }
            if (type === 'video') {
                if (res.data.resCode === 200) {
                    this.setState(prev => ({
                        media: {
                            ...prev.media,
                            videos: [...prev.media.videos, prev.media.videos[prev.media.videos.map(ve => { return ve.name }).indexOf(data.name)] = '']
                        },
                    }))

                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {

                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }
        }).catch(err => {
            console.log(err)
        })
    }
    render() {
        console.log('media', this.props);
        const props = this.props;
        return (
            <React.Fragment>
                <div className="modal">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className={`modal-content alert-light`}>
                            <div className="modal-header border-0">
                                <h5 className={`modal-title text-secondary`}><span className="text-capitalize">{this.props.title}</span></h5>
                                {/* <p className="text-secondary">{this.props.title}</p> */}
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => props.dismiss()}></button>
                            </div>
                            {this.state.media !== null &&
                                <div className="modal-body border-0">

                                    {Object.keys(this.state.media).map((keyName, i) => {
                                        return <React.Fragment key={uuidv4()}>
                                            {keyName === 'poster' && this.state.media[keyName] !== '' &&
                                                <>
                                                    <p className="pb-0 mb-0 mt-2">{keyName}</p>
                                                    <ul className="list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-start">
                                                            <img alt="///" className="ms-2 me-auto" width="50" src={api.getFileByName(this.state.media[keyName])} />
                                                            <button type="button" className="btn btn-danger pt-0 pb-0 ps-1 pe-1" onClick={() => this.fileRemove('poster', { name: this.state.media[keyName] })}>
                                                                <i className="bi bi-trash"></i>
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                            {keyName !== 'poster' && this.state.media[keyName].length > 0 &&
                                                <>
                                                    <p className="pb-0 mb-0 mt-2">{keyName}</p>
                                                    <ul className="list-group">
                                                        {this.state.media[keyName].map((media, mi) => {
                                                            return <li key={i + '-' + mi} style={{ display: media.name === '' ? 'none' : '' }} className="list-group-item d-flex justify-content-between align-items-start">
                                                                {/* <small className="ms-2 me-auto">{media}</small> */}

                                                                {keyName === 'videos' &&
                                                                    <video width="80" height="50" controls className="ms-2 me-auto">
                                                                        <source src={api.getFileByName(media.name)} type="video/mp4" />
                                                                    </video>
                                                                }
                                                                {keyName !== 'videos' &&
                                                                    <small className="ms-2 me-auto">{media}</small>
                                                                }
                                                                <button type="button" className="btn btn-danger pt-0 pb-0 ps-1 pe-1"
                                                                    onClick={() => { if (keyName === 'videos') { this.removeVideo({ courseId: this.props.courseId, name: media.name, chapter: media.chapter }) } }}
                                                                >
                                                                    <i className="bi bi-trash"></i>
                                                                </button>
                                                            </li>
                                                        })}
                                                    </ul>
                                                </>
                                            }

                                        </React.Fragment>
                                    })}



                                </div>
                            }
                            <div className="modal-footer border-0">
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}