import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Api from '../../../services/Api/Api';

class Quiz extends Component {
    constructor(props) {
        super(props)

        this.state = {
            chapterSubmitPage: null,
            score: 0,
            max_questions: 0,
            currentQuizIndex: 0,
        }
    }
    componentWillUnmount = () => {
        console.log("will unmount")
    }

    onNextQuestions = () => {
        if (this.state.currentQuizIndex < this.props.quiz_questions.length - 1) {

            this.setState({
                currentQuizIndex: this.state.currentQuizIndex + 1,
                checkedAns: true
            })
        }
        console.log(this.state.score)
    }
    onBackQuestions = () => {
        if (this.state.currentQuizIndex > 0) {

            this.setState({
                currentQuizIndex: this.state.currentQuizIndex - 1

            })
        }
    }


    onSubmitQuestions = () => {
        console.log("ans =", this.props.quiz_questions)
        let score = 0;
        this.props.quiz_questions.forEach(question => {
            const correctIndexes = [];
            question.options.forEach((op, i) => {
                if (op.status) {
                    correctIndexes.push(i);
                }
            });
            if (correctIndexes.length === question.answers.length && question.answers.every(it => correctIndexes.includes(it))) {
                score++;
            }
            console.log(correctIndexes)

        })
        this.setState({
            submitPage: true,
            score: score,
        })

        if (this.props.isFinalAssessment) {
            Api.finalAssesmentFlagChange(this.props.courseId).then(() => {

                this.props.reloadCourseDetails();
            }).catch((error) => {
                console.log(error)
            });
        }
    }

    render() {
        return (
            <div className="quiz-container">
                {/* <div>
                    <p>Are you sure you want to play this chapter quiz ? </p>
                    <button className={`btn-highlight-success`} onClick={() => {
                        this.setState({ quizQuestions: this.props.quiz_questions.map(x => ({ ...x, answers: [] })), quizPageOpen: true, currentQuizIndex: 0 })
                        console.log("questions = ", this.props.quiz_questions)
                    }}>Play</button>
                </div> */}
                {
                    this.state.submitPage ?

                        <div className="quiz-container-inner">
                            <h4 className='score-number'><i className="bi bi-check-circle-fill"></i> Your score is: <span className='score'>{this.state.score}</span></h4>
                            <div className='quiz-anspage'><h3>Your Result</h3>
                                {
                                    this.props.quiz_questions.map((q, i) => {
                                        return (
                                            <div key={uuidv4()} className="question-list">
                                                <h4>Q{i + 1}.</h4>
                                                {/* 
                                                    ClassName - "ifyes" for correct answer. 
                                                    ClassName - "ifno" for wrong answer.
                                                */}
                                                <h5>Your Answers: <span className='ifyes'>{q.answers.map(op => q.options[op].text).join(',')}</span></h5>
                                                <h5>Correct Answers: <span className='ifyes'>{q.options.filter(op => op.status).map(op => op.text).join(',')}</span></h5>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        :

                        // this.state.quizPageOpen &&
                        <div className="question-block">
                            <p><span className="q-number">Q{this.state.currentQuizIndex + 1}. </span> {this.props.quiz_questions[this.state.currentQuizIndex].question}</p>
                            {
                                this.props.quiz_questions[this.state.currentQuizIndex].options.map((optionsElement, optionsIndex) => {
                                    return (
                                        <div className="form-check" key={optionsIndex}>
                                            <input className="form-check-input" type="checkbox" checked={this.props.quiz_questions[this.state.currentQuizIndex].answers.includes(optionsIndex)}
                                                onChange={(e) => {
                                                    if (this.props.quiz_questions[this.state.currentQuizIndex].answers.includes(optionsIndex)) {
                                                        const findIndex = this.props.quiz_questions[this.state.currentQuizIndex].answers.indexOf(optionsIndex);
                                                        console.log('findIndex=', this.props.quiz_questions)
                                                        this.props.quiz_questions[this.state.currentQuizIndex].answers.splice(findIndex, 1);
                                                    } else {
                                                        this.props.quiz_questions[this.state.currentQuizIndex].answers.push(optionsIndex);
                                                        console.log('findIndex=', this.props.quiz_questions)
                                                    }
                                                    this.forceUpdate();
                                                }}></input>

                                            <label className="form-check-label" htmlFor="flexRadioDefault1">{optionsElement.text}</label>
                                        </div>
                                    )
                                })
                            }
                            {
                                this.state.currentQuizIndex > 0 &&
                                <button type="button" className="btn btn-highlight-secondary back-button"
                                    onClick={this.onBackQuestions}   ><i className="bi bi-arrow-left"></i> Back</button>

                            }

                            {/* <button onClick={()=>console.log(this.props.quiz_questions)}>quiz props</button> */}
                            <input
                                type="range"
                                min={0} max={this.props.quiz_questions.length - 1}
                                value={this.state.currentQuizIndex}

                                onChange={(e) => {
                                    console.log("render = ", e.target.value)

                                    if (Number(e.target.value) < this.state.currentQuizIndex) {
                                        this.setState({
                                            // max_questions:  this.state.currentQuizIndex ,
                                            currentQuizIndex: Number(e.target.value)
                                        })
                                    }

                                    if (this.props.quiz_questions[this.state.currentQuizIndex].answers.length !== 0) {
                                        console.log("input =", this.props.quiz_questions[Number(e.target.value)].answers)
                                        this.setState(prevState => ({
                                            currentQuizIndex: Number(e.target.value),

                                            // max_questions: Number(e.target.value) > this.state.currentQuizIndex && Number(e.target.value)

                                        }))
                                    }
                                }}
                                onClick={(e) => {
                                    // console.log("click =", e.target.value)
                                }}
                                step={1}
                            />
                            {
                                this.state.currentQuizIndex < this.props.quiz_questions.length - 1 && this.props.quiz_questions[this.state.currentQuizIndex].answers.length !== 0 &&
                                <button type="button" className="btn btn-highlight-success next-button"
                                    onClick={this.onNextQuestions}   >Next Question <i className="bi bi-chevron-right"></i></button>

                            }


                            {
                                this.state.currentQuizIndex === this.props.quiz_questions.length - 1 && this.props.quiz_questions[this.state.currentQuizIndex].answers.length !== 0 &&
                                <button type="button" className="btn btn-highlight-success next-button"
                                    onClick={this.onSubmitQuestions} > Submit </button>

                            }
                            {/* <h1>Percentage of quiz complete : {Math.floor((this.state.currentQuizIndex / (this.props.quiz_questions.length - 1)) * 100)}% </h1> */}
                        </div>


                }
            </div>

        )
    }
}

export default Quiz