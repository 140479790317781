import React, { Component } from 'react';
import './courseLibrary.scss';
import { Modal, ApiModal } from '../../../shared/modal/modal';
import { MediaLibrary } from '../../../shared/MediaLibrary/MediaLibrary';
import Loader from '../../../shared/loader/loader';

import api from '../../../../services/Api/Api';
import { v4 as uuidv4 } from 'uuid';
import Card from '../../../shared/Card/Card';
import axios from "axios";

class CourseLibrary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            resMessage: '',
            resType: '',

            courseId: '',
            createNew: false,
            newTitle: "",
            myCourses: [],
            userType: '',
            total: 0,

            formdata: [],
            title: '',
            content: {},
            poster: '',
            price: 0,
            isContent: true,
            objectiveCurr: '',
            objectivArr: [],

            libraryTitle: '',
            mediaLibrary: null,
            error: false,
            errorData: {},
            category: []

        }
        this.handleFormChange = this.handleFormChange.bind(this);
    }

    componentDidMount() {
        this.getPorfile();
    }
    getPorfile() {
        this.setState({
            loader: true
        }, () => {
            api.getProfile().then(res => {
                if (res.data.resCode === 200) {
                    if (res.data.data.profile.userType === 'learner') {
                        this.setState({
                            loader: false,
                            resMessage: 'You have to become author first',
                            resType: 'warning'
                        })
                    }
                    if (res.data.data.profile.userType === 'author') {
                        this.getCourses();
                        this.getCourseCreateForm();
                    }
                } else if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                } else {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    });
                }

            }).catch(err => {
                console.log('get my courses error', err);
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            })
        })

    }
    getCourseCreateForm() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.getForm('createCourse').then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        formdata: res.data.data.fields,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    }, () => {
                        // console.log('formdata', this.state.formdata);
                    })
                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        reCall: 'getProfileForm'
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        })
    }
    getCourses() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            let data = {
                query: { author: window.location.pathname.split('/')[2] },
                limit: 10,
                skip: 0
            }
            api.getMyCourses(data).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        myCourses: res.data.data.courses,
                        total: res.data.data.total
                    }, () => {
                        console.log('myCourses', this.state.myCourses);
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,

                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    });
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                console.log('get my courses error', err);
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            })
        })
    }
    createCourse(e) {
        e.preventDefault();
        let isError = false;
        let errMessage = '';
        let courseData = {
            poster: this.state.poster
        };
        let formdata = [...this.state.formdata];
        formdata.map(obj =>
        (!obj.error ? Object.assign(obj, {
            error: (() => {
                if ((obj.validation.isEmptyString && obj.value === '') || (obj.validation.isUndefined && !obj.value) || (obj.validation.isRegex && !this.testRegex(obj.regex, obj.value))) return true;
                return false;
            })()
        }) : obj)

        );
        this.state.formdata.forEach((obj) => {
            if (obj.error) {
                isError = true;
                errMessage = `Please fill up ${obj.label} correctly`
            }
            if (obj.name !== 'poster') {
                let val = obj.value;
                if (val === undefined) {
                    val = ''
                }
                courseData[obj.name] = val;
            }
        });
        if (isError === true) {
            this.setState({
                error: true,
                errorData: {
                    resMessage: errMessage,
                    resType: "danger",
                    resTitle: 'Validation error'
                }

            }, () => {
                console.log('this.state.resMessage', this.state.resMessage);
            });
            return;
        }

        this.setState({
            loader: true,
            resMessage: '',
            resType: '',

            error: true,
            errorData: {},

        }, () => {
            courseData.keywords = (() => {
                let keyw_arr = [];
                courseData.title.split(' ').forEach(item => {
                    if (item.length > 2) {
                        keyw_arr.push(item)
                    }
                })
                return keyw_arr;
            })()
            api.createCourse(courseData).then(res => {
                if (res.data.resCode === 200) {
                    // redirect to details page
                    this.setState({
                        loader: false,
                        createNew: false,
                        courseId: res.data.course
                    }, () => {
                        // window.location.replace('/account/' + window.location.pathname.split('/')[2] + '/CourseEdit/' + res.data.course);
                        window.location.replace('/account/' + window.location.pathname.split('/')[2] + '/editCourseInfo/' + res.data.course);
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        error: true,
                        errorData: {
                            resMessage: res.data.errors.message.split(':')[2],
                            resType: res.data.resType,
                            resTitle: "Validation Error"
                        },
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        })

    }

    handleFormChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            formdata: prevState.formdata.map(
                obj => (obj.name === name ? Object.assign(obj, {
                    value: value,
                    error: (() => {
                        if ((obj.validation.isEmptyString && value === '') || (obj.validation.isUndefined && !value) || (obj.validation.isRegex && !this.testRegex(obj.regex, value))) return true;
                        return false;
                    })()
                }) : obj)
            )
        }), () => {
            //console.log('this.state', this.state);
        })
    }

    testRegex = (reg, val) => {
        let regx = new RegExp(reg, 'i');
        return regx.test(val);
    }
    fileUpload(event) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.fileUpload(formData).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        poster: res.data.data.fileName[0],
                        loader: false
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            });
        });
    }

    deleteCourse(info) {
        console.log('info', info);
        let root = this;
        api.courseDetails({ courseId: info.id }).then(res => {
            if (res.data.resCode === 200) {
                let course = res.data.data;
                let media = {
                    videos: [],
                    simulators: course.content.simulators ? course.content.simulators : [],
                    poster: course.poster,
                    quizs: course.content.quizs ? course.content.quizs : []
                };
                for (var i = 0; i < course.content.chapters.length; i++) {
                    let chapter = course.content.chapters[i];
                    if (chapter.videos && chapter.videos.length > 0) {
                        chapter.videos.forEach(video => {
                            media.videos.push({
                                name: video.name,
                                chapter: chapter.id
                            })
                        })
                    }
                }
                if (media.videos.length > 0 || media.simulators.length > 0 || media.quizs.length > 0 || media.poster !== '') {
                    console.log('course ', course);
                    this.setState({
                        mediaPopup: true,
                        mediaLibrary: media,
                        libraryTitle: course.title,
                        courseId: course._id
                    }, () => {
                    })
                    return;
                }

                root.setState({
                    loader: true,
                    resMessage: '',
                    resType: ''
                }, () => {
                    api.deleteCourse({ courseId: info.id }).then(res => {
                        if (res.data.resCode === 200) {
                            let courses = [...root.state.myCourses];
                            courses.splice(courses.map(item => { return item._id }).indexOf(course.id), 1);
                            root.setState({
                                loader: false,
                                resMessage: res.data.resMessage,
                                resType: res.data.resType,
                                myCourses: courses
                            })
                        }
                        if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                            root.setState({
                                loader: false,
                                resMessage: res.data.resMessage,
                                resType: res.data.resType
                            })
                        }
                        if (res.data.resCode === 400) {
                            api.logout().then(res => {
                                window.location.replace('/login')
                            })
                        }
                    }).catch(err => {
                        console.log('err', err);
                        root.setState({
                            loader: false,
                            resMessage: 'Something went wrong! Please try again',
                            resType: 'danger'
                        })
                    })
                })

            }
            if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                this.setState({
                    loader: false,
                    resMessage: res.data.resMessage,
                    resType: res.data.resType,
                })
            }
            if (res.data.resCode === 400) {
                api.logout().then(res => {
                    window.location.replace('/login')
                })
            }
        }).catch(err => {
            this.setState({
                loader: false,
                resMessage: "Something went wrong! Please try again..",
                resType: 'danger'
            })
        })

    }

    fetchData(url, iname) {
        this.setState({
            selectLoader: true,
        }, () => {
            axios.get(url, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                this.setState({
                    selectLoader: false,
                    [iname]: res.data.resdata,

                }, () => {
                    console.log('this.state.', this.state)
                })
            }).catch(err => {
                this.setState({
                    selectLoader: false,
                    resMessage: "Couldn't fetch data! Please try again.",
                    resType: 'danger',
                    resTitle: 'Alert'
                })
            })
        })

    }

    getCategory() {
        api.getCategories().then(res => {
            if (res.data.resCode === 200) {
                this.setState({

                })
            }

        }).catch(err => {
            console.log('category err', err);
        })
    }

    render() {
        const createNew = this.state.createNew ? <Modal>
            <div className={`alert alert-light course-add-modal`} style={{ position: 'fixed', top: 0, left: 'auto', right: 0, bottom: 0, width: '600px', height: '100vh', margin: 'auto', zIndex: 11 }}>
                <div className="create-course-window">
                    <form className="row" onSubmit={(e) => this.createCourse(e)}>

                        {this.state.formdata.length > 0 &&
                            <>
                                {this.state.formdata.map((item, i) => {
                                    return <div key={i} className={`${item.class}`}>
                                        <div className="form-group">
                                            <label htmlFor={item.id}>{item.label} {item.tagline && item.tagline !== '' && <small>({item.tagline})</small>} {item.validation.isUndefined && item.validation.isEmptyString && <span className="text-danger">*</span>}</label>
                                            {item.type === 'text' &&
                                                <>
                                                    <input type={item.type} className={`form-control ${item.error ? 'border-danger' : ''}`} name={item.name} id={item.id} placeholder={item.placeholder} defaultValue={item.value} onChange={item.name !== 'objectives' ? this.handleFormChange : (e) => this.setState({ objectiveCurr: e.target.value })} />

                                                </>
                                            }
                                            {item.type === 'textarea' &&
                                                <textarea className={`form-control`} name={item.name} id={item.id} placeholder={item.placeholder} defaultValue={item.value} rows="3"
                                                    onChange={this.handleFormChange}
                                                />
                                            }
                                            {/* {item.type === 'select' &&
                                                <select className={`form-control ${item.error ? 'border-danger' : ''}`} name={item.name} defaultValue={item.value} onChange={this.handleFormChange}>
                                                    <option value=''>{item.placeholder}</option>
                                                    {item.options.map((option, oi) => {
                                                        return <option key={i + '-' + oi} value={option.value}>{option.label}</option>
                                                    })}
                                                </select>

                                            } */}

                                            {item.type === 'select' &&
                                                <select className="form-select form-control" name={item.name} defaultValue={item.value} onMouseUp={() => !Array.isArray(item.options) ? this.fetchData(item.options, item.name) : null} onChange={this.handleFormChange}>
                                                    <option value=''>{item.placeholder}</option>
                                                    {Array.isArray(item.options) &&
                                                        <>
                                                            {item.options.map((option, oi) => {
                                                                return <option key={i + '-' + oi} value={option.value}>{option.label}</option>
                                                            })}
                                                        </>
                                                    }
                                                    {!Array.isArray(item.options) &&
                                                        <>
                                                            {this.state[item.name].map((option, oi) => {
                                                                return <option key={i + '-' + oi} value={option.value ? option.value : option}>{option.value ? option.value : option}</option>
                                                            })}
                                                        </>
                                                    }
                                                </select>
                                            }
                                            {item.type === 'radio' &&
                                                <><br></br>
                                                    {item.options.map((option, oi) => {
                                                        return <div key={i + '-' + oi} className="form-check form-check-inline">
                                                            <input className="form-check-input" disabled={item.status === 'inactive'} type={item.type} value={option.value} name={item.name} id={option.value}
                                                                checked={item.value === option.value} onChange={this.handleFormChange} />
                                                            <label className="form-check-label" htmlFor={option.value}>{option.label}</label>
                                                        </div>
                                                    })}
                                                </>
                                            }
                                            {item.type === 'file' &&
                                                <React.Fragment key={uuidv4()}>
                                                    <div className="course-thumb">
                                                        <img src={api.getFileByName(this.state.poster)}
                                                            alt="///"
                                                            onError={(e) => {
                                                                e.target.style.opacity = 0;
                                                            }}
                                                            onLoad={(e) => { e.target.style.opacity = 1; }}
                                                        />
                                                    </div>
                                                    <input key={uuidv4()} type="file" className="form-control" id="file" name="file" accept="image/*" onChange={(e) => this.fileUpload(e)} />
                                                </React.Fragment>
                                            }
                                        </div>
                                    </div>
                                })}
                            </>
                        }
                        <div className="form-group course-submit">
                            <button type="button" className="btn btn-highlight-danger me-2" onClick={(e) => { this.setState({ createNew: false }) }}>Cancel</button>
                            <button type="submit" className="btn btn-highlight-primary">submit</button>
                        </div>

                    </form>

                </div>
            </div>
        </Modal> : null;

        // const loader = this.state.loader ? <Loader message={"Loading..."} /> : null;

        // const apiDialog = this.state.resMessage !== '' ?
        //     <div className="row">
        //         <div className="col-md-3"></div>
        //         <div className="col-md-6">
        //             <div className={`alert alert-${this.state.resType}`}>
        //                 <p>{this.state.resMessage}</p>
        //                 <i className="bi bi-x-square-fill position-absolute top-0 end-0" onClick={() => this.setState({ resMessage: '', resType: '' })}></i>
        //             </div>
        //         </div>
        //     </div>
        //     : null;

        const medialibrary = this.state.mediaPopup ? <Modal>
            <MediaLibrary
                media={this.state.mediaLibrary}
                title={this.state.libraryTitle}
                dismiss={() => { this.setState({ mediaPopup: false, libraryTitle: '', medialibrary: null }) }}
                courseId={this.state.courseId}

            />
        </Modal> : null;

        const apiModal = this.state.error ? <Modal>
            <ApiModal
                dismiss={(e) => this.setState({ error: false, errorData: {} })}
                modalData={this.state.errorData}

            /> </Modal> : null;
        return (
            <React.Fragment>
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="page-title">
                                <h2>Courses Library</h2>
                                <h6>Add your new course or edit your existing course.</h6>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="btn-block">
                                <button type="button" className="btn btn-highlight-secondary btn-addNew-course" onClick={() => this.setState({ createNew: true })}><i className="bi bi-plus-circle"></i> Add New Course</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="page-body course-library">
                    {/* <h3>Contents</h3> */}
                    <div className="row">
                        {this.state.myCourses.map(item => {
                            let info = {
                                poster: item.poster,
                                title: item.title,
                                industry: item.industry,
                                publishedOn: item.publishedOn,
                                price: item.price,
                                id: item._id,
                                accountId: window.location.pathname.split('/')[2],
                                content: item.content
                            }
                            return <div className="col-sm-3" key={uuidv4()}><Card type={item.status} info={info} delete={() => this.deleteCourse(info)} /></div>
                        })}

                    </div>
                </div>
                {apiModal}
                {createNew}
                {medialibrary}
            </React.Fragment>
        )
    }
}

export default CourseLibrary;