import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './signup.scss';
import axllearnlogo from '../../../assets/images/logo-black.png';
import googleLogo from '../../../assets/images/google-icon.svg';

import { Modal } from '../../shared/modal/modal';
import Loader from '../../shared/loader/loader';

import api from '../../../services/Api/Api'
import { currentBackendBaseUrl, validateEmail } from '../../../utils/constant';

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            resMessage: '',
            resType: '',
            errors: {},
            email: '',

        }
    }

    signUp(e) {
        e.preventDefault();

        const currentURL = window.location.origin;
        let form = e.target;
        let data = {};

        if (validateEmail(form.elements[0].value)) {
            data = {
                email: form.elements[0].value,
                url: currentURL,
            };
            this.setState({
                isLoading: true
            }, () => {
                api.emailVerification(data).then(res => {
                    this.setState({
                        isLoading: false,
                        errors: res.data.errors,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        email: ''
                    })

                }).catch(err => {
                    this.setState({
                        isLoading: false,
                        resMessage: "Something went wrong! Please try again",
                        resType: 'danger',
                        email: ''
                    })
                })
            })
        }
        else {
            this.setState({ errors: { email: 'Enter valid email' } })
        }


    }

    handleChange(e) {
        this.setState(prevState => ({
            errors: {
                ...prevState.errors,
                [e.target.name]: ''
            }
        }))
    }
    redirectToGoogleSSO = async () => {
        const googleLoginURL = currentBackendBaseUrl + "/user/login/google";
        window.open(
            googleLoginURL,
            "_self",
            "width=500,height=600"
        );
    };
    render() {
        const apiDialog = this.state.resMessage !== '' ? <Modal>
            <div className={`modalAlert signupLinkInfo alert alert-${this.state.resType}`}>
                <div className='alertContentBlock'>
                    <p>{this.state.resMessage}</p>
                    {this.state.resType === "success" && <h4 className="text-danger">Link will expire in 1 hour</h4>}

                    <button type="button" className={`btn btn-${this.state.resType}`} onClick={() => { this.setState({ resMessage: '', resType: '', errors: '' }) }}>Okay</button>
                </div>
            </div>
        </Modal> : null;

        const loader = this.state.isLoading ? <Modal>
            <Loader message={"Loading..."} />
        </Modal> : null;
        return (
            <React.Fragment>

                <div className="signupContainer">
                    <div className="innerContainer">
                        <div className="signinBox">
                            <div className="signupInn">
                                <h2>Hello, Learner!</h2>
                                <p>Enter some of your details and start journey with us. </p>
                                <NavLink className="linkSignup" to="/login">SIGN IN</NavLink>
                            </div>
                        </div>
                        <div className="signupBox">
                            <a href="/#" className="signupLogo">
                                <img src={axllearnlogo} alt="///"></img>
                            </a>
                            <div className="signinForm">
                                <h2 className="mtop-90">Create your Account</h2>
                                <div className="socialLogin d-none">
                                    {/* <a href="#" className="fbSignin"><i className="bi bi-facebook"></i> <span>Facebook</span></a> */}
                                    <a href="#" className="gpSignin" onClick={this.redirectToGoogleSSO}><span className='google-icon'><img src={googleLogo} alt="///"></img>Sign-Up with Google</span> <span className='arrow-right'><i class="bi bi-arrow-right"></i></span></a>
                                    {/* <a href="#" className="lnSignin"><i className="bi bi-linkedin"></i> <span>Linkedin</span></a> */}
                                </div>
                                <h6 className="d-none">or use your email for registration.</h6>
                                <div className="fieldContainer">
                                    <form onSubmit={(e) => this.signUp(e)}>
                                        <div className="fieldRow input-group">
                                            <span className="input-group-text" id="basic-addon1"><i className="bi bi-envelope"></i></span>
                                            <input type="text" value={this.state.email} name="email" className={`form-control ${this.state.errors?.email && this.state.errors?.email !== '' ? 'border-danger' : ''}`} id="email" placeholder="Email"
                                                onChange={(e) => this.setState({ email: e.target.value })}
                                            />
                                            <small className="text-danger error-mgs">{this.state.errors?.email && this.state.errors?.email !== '' ? <>{this.state.errors?.email}</> : ''}</small>
                                        </div>

                                        <div className="fieldRow">
                                            <p><button type="submit" className="linkSignin">Sign Up</button></p>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {apiDialog}
                {loader}
            </React.Fragment>

        )
    }
}

export default Signup;