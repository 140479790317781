import { combineReducers } from "redux";
import UserReducer from "./user/UserReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";



const persistConfig = {
    key: 'root',
    storage
}

const rootReducer = combineReducers({
    user: UserReducer
})

export default persistReducer(persistConfig, rootReducer);



