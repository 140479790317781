import { GET_USER, REMOVE_USER, UPDATE_USER } from './UserTypes';

export const getUser = () => {
    return {
        type: GET_USER
    }
}
export const updateUser = (payload) => {
    return {
        type: UPDATE_USER,
        payload: payload
    }
}

export const deleteUser = () => {

    return {
        type: REMOVE_USER,
    }
}