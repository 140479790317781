import React, { Component } from 'react';
import "./learnersurvey.scss";

import emotion1 from "../../../assets/images/very-sad.png";
import emotion2 from "../../../assets/images/sad.png";
import emotion3 from "../../../assets/images/normal.png";
import emotion4 from "../../../assets/images/happy.png";
import emotion5 from "../../../assets/images/very-happy.png";

class LearnerSurvey extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className='learner-survey'>
                        <div className='survey-card'>
                            <div className='card-header bg-black'>
                                <h4>Please complete this internal Survey</h4>
                                <p>This survey will help us to make more exciting implementation in this platform for you.</p>
                            </div>
                            <div className='card-body'>
                                <div className='option-block'>
                                    <h5>How was the User Experience of this Platform?</h5>
                                    <div className='ratings-block'>
                                        <button type='button' className='btn-rating'><img src={emotion1} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion2} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion3} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion4} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion5} alt="///"></img></button>
                                    </div>
                                </div>
                                <div className='option-block'>
                                    <h5>How was your course experience, is mitigated your expectations?</h5>
                                    <div className='ratings-block'>
                                        <button type='button' className='btn-rating'><img src={emotion1} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion2} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion3} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion4} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion5} alt="///"></img></button>
                                    </div>
                                </div>
                                <div className='option-block'>
                                    <h5>Is that overall user experiences was comfortable? </h5>
                                    <div className='ratings-block'>
                                        <button type='button' className='btn-rating'><img src={emotion1} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion2} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion3} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion4} alt="///"></img></button>
                                        <button type='button' className='btn-rating'><img src={emotion5} alt="///"></img></button>
                                    </div>
                                </div>
                                <div className='option-block'>
                                    <h5>Do you want to add any commennts?</h5>
                                    <div className='ratings-block'>
                                        <textarea className='form-control' placeholder='Your comments' rows={4}></textarea>
                                    </div>
                                </div>
                                <div className='option-block'>
                                    <button type='button' className='btn btn-primary' disabled>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default LearnerSurvey;