import React, { useState } from 'react'
import Api from '../../../services/Api/Api';
import { isValidPassword } from '../../../utils/constant';
import Loader from '../../shared/loader/loader';
import { Modal } from '../../shared/modal/modal';
import axllearnlogo from '../../../assets/images/logo-black.png';
import './ChangePassword.scss'

const ChangePassword = () => {

    const [passwordDetails, setPasswordDetails] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    });
    const [isLoading, setIsLoading] = useState(false);
    const [responseData, setResponseData] = useState({
        resMessage: "",
        resType: "",
    })
    const [errors, setErrors] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    })

    function resetPasswordDetailsData() {
        setPasswordDetails({
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        })
    }
    function resetResponseData() {
        setResponseData({
            resMessage: '',
            resType: ''
        })
    }
    function resetErrorsData() {
        setErrors({
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        })
    }
    function handleChange({ target: { name, value } }) {
        setPasswordDetails({ ...passwordDetails, [name]: value })
        resetErrorsData();
    }

    async function changedPasswordDetails() {
        resetErrorsData();
        try {
            setIsLoading(true);
            const { data: { resMessage, resType } } = await Api.changePassword(passwordDetails);

            setResponseData({
                resMessage: resMessage,
                resType: resType

            })

        } catch (error) {
            console.log(error)
            setResponseData({
                resMessage: "Something went wrong! Please try again",
                resType: 'danger'
            })
        }
        finally {
            setIsLoading(false)
            resetPasswordDetailsData();
            resetErrorsData()
        }
    }


    function onSubmit() {
        if (!isValidPassword(passwordDetails.newPassword)) {
            setErrors({ newPassword: 'Password must be mixed with uppercase, number, special characters & with min 8 characters ' })
            return;
        }
        if (passwordDetails.newPassword !== passwordDetails.confirmNewPassword) {
            setErrors({ confirmNewPassword: 'Confirm password must be same as above' })
            return;
        }

        changedPasswordDetails()
    }
    function closeDialogBox() {
        if (responseData.resType === 'success')
            window.location.replace('/login')
        else
            resetResponseData()

    }
    const apiDialog = () => {
        return (
            <>
                {responseData.resMessage !== '' && <Modal> <div className={`changePwdAlert text-center alert alert-${responseData.resType}`}>
                    <p>{responseData.resMessage}</p>
                    <br />
                    <button type="button" onClick={closeDialogBox} className={`btn btn-${responseData.resType}`}>Okay</button>
                </div>
                </Modal>}
            </>
        )
    }

    const loader = () => {
        return (
            isLoading && <Modal>
                <Loader message={"Loading..."} />
            </Modal>

        )
    }
    return (
        <React.Fragment>
            {loader()}
            {apiDialog()}
            <div className="signupContainer change-password">
                <div className="innerContainer">
                    <div className="signupBox">
                        <a href="/#" className="signupLogo">
                            <img src={axllearnlogo} alt="///"></img>
                        </a>
                        <div className="signinForm">
                            <h2>Change Your Password</h2>
                            <p>Enter your current and new password then<br></br> click on Change Password button.</p>
                            <div className="fieldContainer">
                                <div className="fieldRow input-group">
                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-shield-lock"></i></span>
                                    <input className="form-control" placeholder='Current Password' type="password" onChange={handleChange} name="currentPassword" value={passwordDetails.currentPassword} id="currentPassword" />
                                    <small className="text-danger error-mgs">{errors.currentPassword ? <>{errors.currentPassword}</> : ''}</small>
                                </div>
                                <div className="fieldRow input-group">
                                <span class="pwd-hint"><i class="bi bi-info-circle-fill" title=""></i><span class="hint-content">Your password must be mixed with uppercase, number, special characters &amp; with min 8 characters.</span></span>
                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-shield-lock"></i></span>
                                    <input className="form-control" placeholder='New Password' type="password" onChange={handleChange} name="newPassword" value={passwordDetails.newPassword} id="newPassword" />
                                    <small className="text-danger error-mgs">{errors.newPassword ? <>{errors.newPassword}</> : ''}</small>
                                </div>
                                <div className="fieldRow input-group">
                                <span class="pwd-hint"><i class="bi bi-info-circle-fill" title=""></i><span class="hint-content">Your password must be mixed with uppercase, number, special characters &amp; with min 8 characters.</span></span>
                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-shield-lock"></i></span>
                                    <input className="form-control" placeholder='Confirm Password' type="password" onChange={handleChange} name="confirmNewPassword" value={passwordDetails.confirmNewPassword} id="confirmNewPassword" />
                                    <small className="text-danger error-mgs">{errors.confirmNewPassword ? <>{errors.confirmNewPassword}</> : ''}</small>
                                </div>
                                <div className='btn-block'>
                                    <button type='button' onClick={onSubmit} className="change-passowrd-button">Change Password</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>

    )
}

export default ChangePassword