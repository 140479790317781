import React from 'react';
import api from '../../../services/Api/Api';

class Video extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
//1642748291718-axllearn-file_example_MP4_480_1_5MG.mp4
    uploadFile(event) {
        event.stopPropagation();
        event.preventDefault();
        console.log('event', event.target[0].files[0]);
        // var vid = document.createElement('video');
        // var fileURL = URL.createObjectURL(event.target.files[0]);
        // vid.src = fileURL;
        const formData = new FormData();
        formData.append('file', event.target[0].files[0]);
       
        api.fileUpload_md(formData).then(res => {
            console.log('res',res);
        }).catch(err => {
            console.log('err', err);
        })
    }
    // getVideo(){
    //     api.getFileByName('1642748291718-axllearn-file_example_MP4_480_1_5MG.mp4').then(res=>{
    //         console.log('res', res)
    //     }).catch(err=>{
    //         console.log('err', err)
    //     })
    // }
    componentDidMount(){
        // this.getVideo()
    }
    // fileUpload_md
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 m-auto">
                            <h1 className="text-center display-4 my-4">Upload files to Server</h1>
                            <form onSubmit={(e)=>{this.uploadFile(e)}} >
                                <div className="custom-file mb-3">
                                    <input type="file" id="file" name="file" accept="video/*" className="custom-file-input" />
                                    <label htmlFor="file" className="custom-file-label">Choose File</label>
                                </div>
                                <input type="submit" value="Submit" className="btn btn-primary btn-block" />
                            </form>
                            <hr />
                        </div>
                    </div>
                </div>
                <video controls>
                    <source controller src={api.getFileByName('1642750528079-axllearn-lms training video (1).mp4')} type="video/mp4"/>
                </video>
            </React.Fragment>
        )
    }
}

export default Video;