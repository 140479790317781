import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';
import profileImg from '../../../assets/images/profile-img.png';

class ChildHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // product: [],
            // module: [],
            isLoading: false,
            isAjax: '',
            searchString: '',
            // logo_render: true
        }
    }

    componentDidMount() {
        document.addEventListener("click", this.handleOnclick);
    }
    componentWillUnmount() {
        document.removeEventListener("click", this.handleOnclick);
    }
    handleOnclick = () => {
        this.setState({ isAjax: '' });
    }
    handleAjax(e, apiUrl, code) {
        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }
        if (apiUrl.includes('logout')) {
            sessionStorage.clear();
        }
        this.setState({
            isAjax: ''
        }, () => {
            axios({
                method: 'get',
                url: apiUrl,
                headers: {
                    "Content-Type": "application/json"
                }
            }).then((res) => {
                if (res.data.resCode === 200) {

                    this.setState(prevState => ({
                        ...prevState,
                        isAjax: code,
                        [code]: res.data.data,
                    }), () => {
                        console.log(this.state)
                    })
                }
                if (res.data.resCode === 400) {
                    window.location.replace('/')
                }
            });
        })

    }

    preventClose(e) {
        console.log("event ===", e)
        e.stopPropagation();
        if (e.nativeEvent) {
            e.nativeEvent.stopImmediatePropagation();
        }
    }
    serachAndGo(e) {
        e.preventDefault();
        let searchString = this.state.searchString;
        let qValue = searchString.split(' ').join('+');
        window.location.replace('/search?q=' + qValue);
    }

    render() {
        const { module, getMedia } = this.props
        return (
            <>
                {module.length > 0 &&
                    <ul className="hd-rightBlock">
                        <li className='hd-searchBlock'>
                            <form className="container-fluid" onSubmit={(e) => this.serachAndGo(e)}>
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search by Category, Skill, Course Name" value={this.state.searchString} onChange={(e) => this.setState({ searchString: e.target.value })} />
                                    <button className="input-group-text btn btn-primary" type="submit"><i className="bi bi-search"></i></button>
                                </div>
                            </form>
                        </li>
                        {module.map((me, mi) => {
                            return <li key={uuidv4()} style={{ position: 'relative' }}>
                                {!me.ajax &&
                                    <a href={me.rUrl} target={me.target} className={`${me.cssClass ? me.cssClass : ''}`} title={me.code}>
                                        <i className={`bi ${me.icon}`}></i>{me.label}
                                    </a>
                                }

                                {me.ajax &&
                                    <>
                                        <a href="javascript:void()" onClick={(e) => this.handleAjax(e, me.rUrl, me.code)}>
                                            <i className={`bi ${me.icon}`}></i>{me.label}
                                        </a>
                                        {this.state[me.code] && this.state.isAjax === me.code &&
                                            <ul className={`bg-light list-group`}>
                                                {this.state[me.code].length === 0 &&
                                                    <li className="alert alert-primary">

                                                        <p>There is no notification </p>
                                                        <i className="float-end bi bi-x" style={{ cursor: 'pointer' }} onClick={() => this.setState({ isAjax: '' })} /><br />
                                                    </li>
                                                }
                                                {this.state[me.code].map(adata => {
                                                    return <li key={uuidv4()} className={`list-group-item`} onClick={(e) => {
                                                        console.log("me code =", me.code)
                                                        return this.preventClose(e)
                                                    }}>
                                                        {adata.code === 'user' &&
                                                            <div className="profile-header">
                                                                <span className="profile-img">
                                                                    <img alt="///" id='profileimgHeader' src={getMedia(adata.label.profilePic, 'profileimgHeader')}
                                                                        style={{ width: '100%', height: '100%' }}
                                                                        onError={(e) => {
                                                                            e.target.parentNode.className = e.target.parentNode.className + ' bg-primary text-light';
                                                                            e.target.parentNode.innerText = adata.label.name.charAt(0) + adata.label.name.split(' ')[adata.label.name.split(' ').length - 1].charAt(0);
                                                                            e.target.style.display = 'none';
                                                                        }}
                                                                    />
                                                                </span>
                                                                <span className="name-email">
                                                                    <h6>{adata.label.name}</h6>
                                                                    <a href="javascript:void()">{adata.label.email}</a>
                                                                </span>
                                                            </div>
                                                        }
                                                        {adata.ajax && adata.code !== 'user' && me.code !== 'notification' &&
                                                            <a href="javascript:void()" onClick={(e) => this.handleAjax(e, adata.rUrl, adata.code)} title={adata.code}> <i className={`bi ${adata.icon}`}></i>{adata.label}</a>
                                                        }
                                                        {me.code === 'notification' &&
                                                            <a href={adata.url}> <i className={`bi ${adata.icon}`}></i>{adata.message}</a>
                                                        }
                                                        {!adata.ajax && adata.code !== 'user' &&
                                                            <a href={adata.rUrl} target={adata.target} title={adata.code}> <i className={`bi ${adata.icon}`}></i>{adata.label}</a>
                                                        }
                                                    </li>
                                                })}
                                            </ul>
                                        }
                                    </>
                                }
                                {me.suboptions && me.suboptions.length > 0 &&
                                    <ul className={`bg-light list-group subitems`}>
                                        {/* {me.suboptions.map(subItem => {
                            return <li key={uuidv4()} className={`list-group-item`}>
                              {subItem.ajax &&
                                <a onClick={(e) => this.handleAjax(e, subItem.rUrl, subItem.code)} title={me.code}> <i className={`bi ${subItem.icon}`}></i>{subItem.label}</a>
                              }
                              {!subItem.ajax &&
                                <a href={subItem.rUrl} target={me.target} title={me.code}> <i className={`bi ${subItem.icon}`}></i>{subItem.label}</a>
                              }
                            </li>
                          })} */}
                                        <li>
                                            <p className="profile-header">
                                                <span className="profile-img">
                                                    <img src={profileImg} alt="///"></img>
                                                </span>
                                                <span className="name-email">
                                                    <h6>Alex Watson</h6>
                                                    <a href="javascript:void()">alex.watson@hotmail.com</a>
                                                </span>
                                            </p>
                                        </li>
                                        <li><a href="javascript:void()">View Profile</a></li>
                                        <li><a href="javascript:void()">Account Settings</a></li>
                                        <li><a href="javascript:void()">Sign out</a></li>
                                    </ul>
                                }
                            </li>
                        })}
                    </ul>
                }

            </>
        )
    }
}

export default React.memo(ChildHeader);