import React, { Component } from 'react';
import Loader from '../../../shared/loader/loader';
import api from '../../../../services/Api/Api';
import Card from '../../../shared/Card/Card';
import { v4 as uuidv4 } from 'uuid';
import './CourseListing.scss';

class CourseListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],

            // ------ api related -----
            loader: '',
            resMessage: '',
            resType: '',
            searchQuery: '',

            limit: 10,
            skip: 0,
            total: 0,
            page_num: 1
        }
    }

    componentDidMount() {
        this.searchList();
    }

    searchList() {
        let searchString = this.parseQueryString().q;
        let reqData = {
            query: {
                "status": 'published',
                $and: []
            },
            limit: this.state.limit,
            skip: this.state.skip
        };
        let searchTextArr = searchString ? searchString.split('+').map(e => { return e }) : '';
        if (searchString) {
            reqData.query.$and.push({ keywords: { $in: searchTextArr } });
        }
        if (this.parseQueryString().industry) {
            reqData.query.industry = this.parseQueryString().industry;
        }
        if (this.parseQueryString().audience) {
            reqData.query.audience = this.parseQueryString().audience;
        }
        if (this.parseQueryString().category) {
            reqData.query.category = this.parseQueryString().category.replace('%20', ' ');
        }



        this.setState({
            loader: true,
            resMessage: '',
            resType: '',
            searchQuery: searchTextArr
        }, () => {
            if (reqData.query.$and.length === 0) {
                delete reqData.query.$and
            }
            api.courseSearch(reqData).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        list: res.data.data.courses,
                        total: res.data.data.total
                    });
                } else {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    });
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: 'danger'
                });
            })
        });


    }
    parseQueryString() {
        let str = window.location.search;
        let objURL = {};
        str.replace(
            new RegExp("([^?=&]+)(=([^&]*))?", "g"),
            function ($0, $1, $2, $3) {
                objURL[$1] = $3;
            }
        );
        return objURL;
    };

    page(i) {
        this.setState({
            loader: true,
            resMessage: '',
            resTitle: '',
            resType: '',
            page_num: i
        }, () => {
            let searchString = this.parseQueryString().q;
            let data = {
                query: { "status": "published" },
                limit: this.state.limit,
                skip: i * this.state.limit - this.state.limit,
                $and: []
            }

            let searchTextArr = searchString ? searchString.split('+').map(e => { return e }) : '';
            if (searchString) {
                data.$and.push({ keywords: { $in: searchTextArr } });
            }
            api.courseSearch(data).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        list: res.data.data.courses,
                        total: res.data.data.total,
                        resType: res.data.resType
                    }, () => {
                        // console.log('formdata', this.state.formdata);
                    })
                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/admin/login')
                    })
                }
            }).catch(err => {
                console.log('user get error');
                this.setState({
                    loader: false,
                    resMessage: 'Something went wrong! Please try again',
                    resType: 'danger',
                    resTitle: 'Error'
                })
            })
        })
    }

    pagenations = () => {
        let total_page = Math.ceil(this.state.total / this.state.limit)
        const page = []
        for (let i = 1; i <= total_page; i++) {
            page.push(<li className="page-item"><a href='/#' className="page-link" onClick={() => this.page(i)}>{i}</a></li>)
        }
        return page
    }

    prev_page = () => {

        this.setState({
            loader: true,
            resMessage: '',
            resTitle: '',
            resType: '',
            page_num: this.state.page_num - 1
        }, () => {
            let searchString = this.parseQueryString().q;
            let data = {
                query: { "status": "published" },
                limit: this.state.limit,
                skip: (this.state.page_num) * this.state.limit - this.state.limit,
                $and: []
            }

            let searchTextArr = searchString ? searchString.split('+').map(e => { return e }) : '';
            if (searchString) {
                data.$and.push({ keywords: { $in: searchTextArr } });
            }
            api.courseSearch(data).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        list: res.data.data.courses,
                        total: res.data.data.total,
                        resType: res.data.resType
                    }, () => {
                        // console.log('formdata', this.state.formdata);
                    })
                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/admin/login')
                    })
                }
            }).catch(err => {
                console.log('user get error');
                this.setState({
                    loader: false,
                    resMessage: 'Something went wrong! Please try again',
                    resType: 'danger',
                    resTitle: 'Error'
                })
            })
        })
    }
    next_page = () => {
        this.setState({
            loader: true,
            resMessage: '',
            resTitle: '',
            resType: '',
            page_num: this.state.page_num + 1
        }, () => {
            let searchString = this.parseQueryString().q;
            let data = {
                query: { "status": "published" },
                limit: this.state.limit,
                skip: (this.state.page_num) * this.state.limit - this.state.limit,
                $and: []
            }

            let searchTextArr = searchString ? searchString.split('+').map(e => { return e }) : '';
            if (searchString) {
                data.$and.push({ keywords: { $in: searchTextArr } });
            }
            api.courseSearch(data).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        list: res.data.data.courses,
                        total: res.data.data.total,
                        resType: res.data.resType
                    }, () => {
                        // 
                    })
                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/admin/login')
                    })
                }
            }).catch(err => {
                console.log('user get error');
                this.setState({
                    loader: false,
                    resMessage: 'Something went wrong! Please try again',
                    resType: 'danger',
                    resTitle: 'Error'
                })
            })
        })
    }

    render() {
        const loader = this.state.loader ? <Loader message={"Loading..."} /> : null;
        const apiDialog = this.state.resMessage !== '' ?

            <div className={`course-not-found alert alert-${this.state.resType}`}>
                <p>{this.state.resMessage}</p>
                <i className="bi bi-x-square-fill position-absolute cross-icon" onClick={() => this.setState({ resMessage: '', resType: '' })}></i>
            </div>

            : null;
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="fluid-banner">
                        <h1>
                            Search result for {this.state.searchQuery}
                        </h1>
                    </div>
                    <div className="container">
                        <div className="search-result">
                            <div className="row">
                                {this.state.list.map(item => {
                                    let info = {
                                        poster: item.poster,
                                        title: item.title,
                                        industry: item.industry,
                                        publishedOn: item.publishedOn,
                                        price: item.price,
                                        id: item._id,
                                        accountId: window.location.pathname.split('/')[2],
                                        content: item.content,
                                        introVideo: item.introVideo

                                    }
                                    return <div className="col-sm-3" key={uuidv4()}><Card type={item.status} info={info} delete={() => this.deleteCourse(info)} /></div>
                                })}

                            </div>
                        </div>
                    </div>
                </div>
                <div className='search-list-pagination'>
                    <div className="container">
                        <nav className="table-pagination">
                            <ul className="pagination">
                                <li className="page-item">
                                    <a href='/#' className="page-link" onClick={() => this.prev_page()} aria-label="Previous">
                                        <span aria-hidden="true">&laquo;</span>
                                    </a>
                                </li>
                                {this.pagenations()}

                                <li className="page-item">
                                    <a href='/#' className="page-link" onClick={() => this.next_page()} aria-label="Next">
                                        <span aria-hidden="true">&raquo;</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {apiDialog}
                {loader}
            </React.Fragment>
        )
    }
}

export default CourseListing;