import React, { Component, Suspense } from 'react';
import './courseEdit.scss';

import api from '../../../../services/Api/Api';
import { Link, withRouter } from 'react-router-dom';
import { Modal, ApiModal } from '../../../shared/modal/modal';
import Loader from '../../../shared/loader/loader';
import { v4 as uuidv4 } from 'uuid';
import VimeoPlayer from '../../../shared/vimeoplayer/vimeoplayer';

class CourseEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            resMessage: '',
            resType: '',
            // --------
            formdata: [],
            title: '',
            content: {},
            poster: '',
            price: 0,
            isContent: true,
            objectiveCurr: '',
            objectivArr: [],
            selectedChapter: null,

            // ----- chapter -----
            chapterLoader: false,
            chapterSaved: false,
            resChapterMessage: '',

            // video 
            video_loader: false,
            video_saved: false,
            vides_resMessage: '',

            selected_video: null,
            testvalue: '',
            isCourseInfo: false,
            isVideoTitle: false,
            vtitle: '',
            fileData: {

            },
            vidoeUploader: false,
            docObject: {},
            isDocUp: false,
            uploadDocType: 'upload',
            courseArranger: [],

            upload_video_id: ''
        }
        this.handleFormChange = this.handleFormChange.bind(this);
    }
    componentDidMount() {
        this.getCourseEditForm()
    }
    getCourse() {
        let id = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.myCourseDetails({ courseId: id }).then(res => {
                if (res.data.resCode === 200) {
                    // redirect to details page
                    let topics = [...res.data.data.content.chapters];
                    let topicsArranger = [];
                    for (const topic of topics) {

                        let allVideos = topic?.videos ?? [];
                        let allQuizs = topic?.quizzes ?? [];

                        let allItems = allVideos.concat(allQuizs);
                        allItems.map(function (item, index) {

                            if (item.position === undefined || item.position === null) {
                                item.position = allItems.length + 1;
                            };
                        });
                        allItems.sort((a, b) => a.position - b.position);
                        topicsArranger.push({
                            id: topic.id,
                            allItems: allItems
                        });
                    }

                    this.setState(prevState => ({
                        loader: false,
                        isCourseInfo: true,
                        title: res.data.data.title,
                        content: res.data.data.content,
                        poster: res.data.data.poster,
                        objectivArr: res.data.data.objectives,
                        selectedChapter: res.data.data.content.chapters !== undefined && res.data.data.content.chapters.length > 0 ? res.data.data.content.chapters[0] : null,

                        formdata: prevState.formdata.map(
                            obj => Object.assign(obj, {
                                value: Array.isArray(res.data.data[obj.name]) ? res.data.data[obj.name].join(' ') : res.data.data[obj.name]
                            })),
                        courseStatus: res.data.data.status,
                        courseArranger: topicsArranger
                    }))

                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        })

    }
    getCourseEditForm() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.getForm('courseinfo').then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        formdata: res.data.data.fields,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    }, () => {
                        // console.log('formdata', this.state.formdata);
                        this.getCourse();
                    })
                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        reCall: 'getProfileForm'
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        })
    }

    handleFormChange(e) {
        let name = e.target.name;
        let value = e.target.value;
        this.setState(prevState => ({
            ...prevState,
            formdata: prevState.formdata.map(
                obj => (obj.name === name ? Object.assign(obj, {
                    value: value,
                    error: (() => {
                        if ((obj.validation.isEmptyString && value === '') || (obj.validation.isUndefined && !value) || (obj.validation.isRegex && !this.testRegex(obj.regex, value))) return true;
                        return false;
                    })()
                }) : obj)
            )
        }), () => {
            //console.log('this.state', this.state);
        })
    }

    testRegex = (reg, val) => {
        let regx = new RegExp(reg, 'i');
        return regx.test(val);
    }
    fileUpload(event, type) {
        let root = this;
        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        if (type === 'video') {
            var fileURL = URL.createObjectURL(event.target.files[0]);
            var vid = document.createElement('video');
            vid.src = fileURL;
            formData.append('title', this.state.vtitle);
            let video_duration = 0;
            // wait for duration to change from NaN to the actual duration
            return new Promise((resolve, reject) => {
                vid.ondurationchange = function () {
                    video_duration = this.duration;
                    resolve(video_duration);
                };
            }).then((video_duration) => {
                root.setState({
                    resMessage: '',
                    resType: '',
                    vidoeUploader: true,

                }, () => {
                    api.videoUpload(formData).then(res => {
                        if (res.data.resCode === 200) {
                            root.setState({
                                vidoeUploader: false
                            }, () => {
                                root.addVideo({ name: res.data.data.id, duration: video_duration });
                            });
                        }
                        if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                            root.setState({
                                vidoeUploader: false,
                                resMessage: res.data.resMessage,
                                resType: res.data.resType
                            })
                        }
                        if (res.data.resCode === 400) {
                            api.logout().then(res => {
                                window.location.replace('/login')
                            })
                        }
                    }).catch(err => {
                        root.setState({
                            vidoeUploader: false,
                            resMessage: "Something went wrong! Please try again",
                            resType: "danger"
                        });
                    });
                    // }
                });
            }).catch(err => {
                root.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            })
        }
        if (type === 'docs') {
            this.setState({
                resMessage: '',
                resType: '',
                docLoader: true
            }, () => {
                api.docupload(formData).then(res => {
                    if (res.data.resCode === 200) {
                        this.setState(prev => ({
                            ...prev,
                            docObject: {
                                ...prev.docObject,
                                link: res.data.resData.secure_url,
                                id: res.data.resData.asset_id
                            },
                        }), () => {
                            root.addDocument();
                        })
                    }
                    if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                        this.setState({
                            docLoader: false,
                            docObject: {
                                link: '',
                                id: '',
                                title: ''
                            },
                            resMessage: res.data.resMessage,
                            resType: res.data.resType
                        })
                    }
                    if (res.data.resCode === 400) {
                        api.logout().then(res => {
                            window.location.replace('/login')
                        })
                    }
                }).catch(err => {
                    root.setState({
                        docLoader: false,
                        docObject: {
                            link: '',
                            id: '',
                            title: ''
                        },
                        resMessage: "Something went wrong! Please try again",
                        resType: "danger"
                    });
                });
            })
        }
    }




    updateCourseInfo(e) {
        e.preventDefault();
        let isError = false;
        let courseData = {
            objectives: this.state.objectivArr,
            poster: this.state.poster
        };
        this.state.formdata.map((item) => {
            if (item.error) {
                isError = true;
            }
            if (item.name !== 'objectives' && item.name !== 'poster') {
                let val = item.value;
                if (val === undefined) {
                    val = ''
                }
                courseData[item.name] = val;
            }
        });
        if (isError === true) {
            this.setState({
                resMessage: "Please fill up the form correctly, before you submit",
                resType: "danger"
            });
            return;
        }
        // console.log('courseData', courseData)
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            this.updateCourse(courseData)
        })
    }

    updateCourse(data = {}, courseId = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]) {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.updateCourse({ data: data, courseId: courseId }).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    }, () => {
                        if (data.status && data.status === 'submitted') {
                            this.setState({
                                courseStatus: data.status
                            })
                        }
                    })
                    //this.props.profile(courseData);
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            });
        })
    }

    addChapter() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            let content = { ...this.state.content };
            let id = uuidv4();
            let newChapter = {
                id: id,
                title: 'Topic ' + (this.state.content.chapters.length + 1),
                desc: ""
            }
            content.chapters.push(newChapter);
            let data = {
                data: { chapter: newChapter },
                courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
            }
            api.addChapter(data).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        content: content,
                        selectedChapter: content.chapters[content.chapters.map(item => { return item.id }).indexOf(id)]
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            });
        })
    }
    updateChapter() {
        this.setState({
            chapterLoader: true,
            chapterSaved: false,
            resChapterMessage: ''
        }, () => {
            let reqObj = {
                courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
                data: {
                    chapter_id: this.state.selectedChapter.id,
                    chapter: {
                        title: this.state.selectedChapter.title,
                        desc: this.state.selectedChapter.desc ? this.state.selectedChapter.desc : '',
                    }
                }
            }
            api.updateChapter(reqObj).then(res => {
                if (res.data.resCode === 200) {
                    let chapters = this.state.content.chapters;
                    chapters[chapters.map(item => { return item.id }).indexOf(this.state.selectedChapter.id)].title = this.state.selectedChapter.title;
                    chapters[chapters.map(item => { return item.id }).indexOf(this.state.selectedChapter.id)].desc = this.state.selectedChapter.desc;
                    this.setState(prev => ({
                        chapterLoader: false,
                        chapterSaved: true,
                        content: {
                            ...prev.content,
                            chapters: chapters
                        }
                    }), () => {
                        setTimeout(() => {
                            this.setState({
                                chapterSaved: false,
                                resMessage: res.data.resMessage,
                                resType: res.data.resType,
                                resTitle: res.data.resTitle
                            })
                        }, 2000);
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        chapterLoader: false,
                        resChapterMessage: res.data.resMessage
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    chapterLoader: false,
                    resChapterMessage: "Something went wrong! Please try again"
                });
            });
        })
    }
    removeChapter(id) {
        let chaptersArr = [...this.state.content.chapters];
        let index = chaptersArr.map(item => { return item.id }).indexOf(id);

        this.setState({
            // loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            let data = {
                data: { chapter_id: id },
                courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]
            }


            api.removeChapter(data).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,

                    }, () => {
                        if (index !== -1) {
                            chaptersArr.splice(index, 1);
                            this.setState(prev => ({
                                ...prev,
                                selectedChapter: null,
                                selected_video: null,
                                content:
                                {
                                    ...prev.content,
                                    chapters: chaptersArr
                                }
                            }));
                        }
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            });
        })

    }

    addVideo(file) {
        this.setState({
            video_loader: true,
            video_saved: false,
            vides_resMessage: ''
        }, () => {
            let reqObj = {
                courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
                data: {
                    chapter_id: this.state.selectedChapter.id,
                    videoObj: {
                        "name": file.name,
                        "title": this.state.vtitle,
                        "desc": '',
                        "duration": file.duration,
                        "position": null,
                        "type": "video"
                    }
                }
            }
            api.addVideo(reqObj).then(res => {
                if (res.data.resCode === 200) {
                    let chapters = this.state.content.chapters;
                    let chapter_index = chapters.map(item => { return item.id }).indexOf(this.state.selectedChapter.id);
                    chapters[chapter_index].videos.push(reqObj.data.videoObj);
                    this.setState(prev => ({
                        video_loader: false,
                        video_saved: true,
                        selected_video: reqObj.data.videoObj,
                        content: {
                            ...prev.content,
                            chapters: chapters
                        }
                    }), () => {
                        setTimeout(() => {
                            this.setState({
                                video_saved: false
                            })
                            window.location.reload();
                        }, 2000);
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        video_loader: false,
                        vides_resMessage: res.data.resMessage
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    video_loader: false,
                    vides_resMessage: "Something went wrong! Please try again"
                });
            })
        })
    }
    updateVideo() {
        this.setState({
            video_loader: true,
            video_saved: false,
            vides_resMessage: ''
        }, () => {
            let reqObj = {
                courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
                data: {
                    chapter_id: this.state.selectedChapter.id,
                    videoObj: this.state.selected_video
                }
            }
            api.updateVideo(reqObj).then(res => {
                if (res.data.resCode === 200) {
                    let chapters = this.state.content.chapters;
                    let chapter_index = chapters.map(item => { return item.id }).indexOf(this.state.selectedChapter.id);
                    let vindex = chapters[chapter_index].videos.map(vi => { return vi.name }).indexOf(this.state.selected_video.name);
                    chapters[chapter_index].videos[vindex].title = this.state.selected_video.title;
                    chapters[chapter_index].videos[vindex].desc = this.state.selected_video.desc;
                    this.setState(prev => ({
                        video_loader: false,
                        video_saved: true,
                        content: {
                            ...prev.content,
                            chapters: chapters
                        },
                        selected_video: null
                    }), () => {
                        setTimeout(() => {
                            this.setState({
                                video_saved: false
                            })
                        }, 2000);
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        video_loader: false,
                        vides_resMessage: res.data.resMessage
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    video_loader: false,
                    vides_resMessage: "Something went wrong! Please try again"
                });
            })
        })
    }
    removeVideo(name) {
        this.setState({
            video_loader: true,
            video_saved: false,
            vides_resMessage: ''
        }, () => {
            let reqObj = {
                courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
                data: {
                    chapter_id: this.state.selectedChapter.id,
                    video_name: name
                }
            }
            api.removeVideo(reqObj).then(res => {
                if (res.data.resCode === 200) {


                    let chapters = [...this.state.content.chapters];
                    let chapter_index = chapters.map(item => { return item.id }).indexOf(reqObj.data.chapter_id);

                    let video_index = chapters[chapter_index].videos.map(vi => { return vi.name }).indexOf(reqObj.data.video_name);
                    chapters[chapter_index].videos.splice(video_index, 1);

                    this.fileRemove({ name: reqObj.data.video_name });
                    this.setState(prev => ({
                        video_loader: false,
                        video_saved: true,
                        content: {
                            ...prev.content,
                            chapters: chapters
                        }
                    }), () => {

                        setTimeout(() => {
                            this.setState({
                                video_saved: false
                            })
                        }, 2000);
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        video_loader: false,
                        vides_resMessage: res.data.resMessage
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    video_loader: false,
                    vides_resMessage: "Something went wrong! Please try again"
                });
            })
        })

    }

    fileRemove(data) {
        let uriarr = data.split('/');
        let vid = uriarr[uriarr.length - 1];
        api.deleteVimeoVideo({ vid: vid }).then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err)
        })
    }

    chapterDuration(chapter) {
        let duration = 0;
        if (chapter.videos && chapter.videos.length > 0) {
            chapter.videos.map(citem => {
                duration = duration + citem.duration;
            })
        }

        return duration.toFixed(2);
    }

    submitCourse() {

        api.myCourseDetails({ courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] }).then(res => {
            if (res.data.resCode === 200) {
                let current_course = res.data.data;
                validate(current_course);
            } else if (res.data.resCode === 400) {
                api.logout().then(res => {
                    window.location.replace('/login')
                })
            } else {
                this.setState({
                    resMessage: res.data.resMessage,
                    resType: res.data.resType,
                    resTitle: res.message.resTitle
                })
            }
        }).catch(err => {
            this.setState({
                resMessage: 'Something went wrong! Please try again',
                resType: 'danger',
                resTitle: 'Error'
            })
        })

        const validate = (course) => {
            // Object.keys(course).map((key, i) => {
            //     let type = typeof (course[key]);
            //     if (type != object) {

            //     }
            // });
            for (const [key, value] of Object.entries(course)) {
                let type = typeof (value);
                if (type !== 'object' && (value === '' || value === undefined) && key !== 'isCertificate' && key !== 'isSimulator') {
                    this.setState({
                        resTitle: 'Validation Error',
                        resMessage: `Please add ${key !== 'isSimulator' || key !== 'isCertificate' ? key.toUpperCase() : key.substring(2).toUpperCase()} for your course`,
                        resType: 'danger'
                    });
                    return;
                }
                if (type === 'object' && value && Object.keys(value).length === 0 && key !== 'author' && !Array.isArray(value)) {
                    this.setState({
                        resTitle: 'Validation Error',
                        resMessage: `Please add ${key !== 'isSimulator' || key !== 'isCertificate' ? key.toUpperCase() : key.substring(2).toUpperCase()} for your course`,
                        resType: 'danger'
                    });
                    return;
                }
                if (type === 'object' && Array.isArray(value) && value.length === 0 && key !== 'keywordsSelected' && key !== 'ratings') {
                    this.setState({
                        resTitle: 'Validation Error',
                        resMessage: `Please add ${key !== 'isSimulator' || key !== 'isCertificate' ? key.toUpperCase() : key.substring(2).toUpperCase()} for your course`,
                        resType: 'danger'
                    });
                    return;
                }

            }
            if (course.content.chapters.length === 0) {
                this.setState({
                    resTitle: 'Validation Error',
                    resMessage: 'There is no content in your course. Please add some content there.',
                    resType: 'danger'
                });
                return;
            }
            if (course.content.chapters.length > 0) {
                for (var c = 0; c < course.content.chapters.length; c++) {
                    if (!course.content.chapters[c].desc || course.content.chapters[c].desc === '') {
                        this.setState({
                            resTitle: 'Validation Error',
                            resMessage: `The description of this topic ${course.content.chapters[c].title} is empty, please provide some description`,
                            resType: 'danger'
                        });
                        return;
                    }
                    if (!course.content.chapters[c].videos) {
                        this.setState({
                            resTitle: 'Validation Error',
                            resMessage: `You haven't any video in this chapter: ${course.content.chapters[c].title}, please add some video`,
                            resType: 'danger'
                        });
                        return;
                    }
                    // console.log('course.content.chapters[c]', course.content.chapters[c].videos);
                    if (course.content.chapters[c].videos && course.content.chapters[c].videos.length === 0) {
                        this.setState({
                            resTitle: 'Validation Error',
                            resMessage: `You haven't any video in this chapter: ${course.content.chapters[c].title}, please add some video`,
                            resType: 'danger'
                        });
                        return;
                    }
                    if (course.content.chapters[c].quiz && course.content.chapters[c].quiz.length === 0) {
                        this.setState({
                            resTitle: 'Validation Error',
                            resMessage: `You haven't any question in this topic: ${course.content.chapters[c].title}, please add some questions`,
                            resType: 'danger'
                        });
                        return;
                    }
                }
            }

            if (!course.content.finalAssesment) {
                this.setState({
                    resTitle: 'Final Assesment',
                    resMessage: `You haven't added any final assesment in your course. Please add a quiz / final assesment in your course.`,
                    resType: 'danger'
                });
                return;
            }
            this.updateCourse({ status: 'submitted' })
        }



    }

    format(seconds) {
        if (isNaN(seconds)) {
            return `00:00`;
        }
        const date = new Date(seconds * 1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds().toString().padStart(2, "0");
        if (hh) {
            return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
        }
        return `${mm}:${ss}`;
        // console.log("date=",`${mm}:${ss}`)
    };

    addDocument() {
        // console.log()
        //  {courseId: <courseID from url>, data:{docObject: {id: <asset_id>, link: <url>, title:<user input>}}
        if (!this.state.docObject.id || this.state.docObject === '' || !this.state.docObject.title || this.state.docObject.title === '' || !this.state.docObject.link || this.state.docObject.link === '') {
            this.setState({
                resMessage: "Please provide correct data",
                resType: "danger"
            })
            return;
        }
        const reqObj = {
            courseId: this.props.match.params.course,
            data: { docObject: this.state.docObject }
        }
        api.addCourseDocument(reqObj).then(res => {
            // content
            let content = { ...this.state.content };
            content.courseDocuments.push(this.state.docObject)
            if (res.data.resCode === 200) {
                this.setState(prev => ({
                    ...prev,
                    docObject: {
                        ...prev.docObject,
                        link: '',
                        id: '',
                        title: ''
                    },
                    content: content,
                    resMessage: res.data.resMessage,
                    resType: res.data.resType,
                    resTitle: res.data.resTitle,
                    docLoader: false,
                    isDocUp: false

                }))
            } else if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                this.setState(prev => ({
                    docLoader: false,
                    docObject: {
                        ...prev.docObject,
                        link: '',
                        id: '',
                        title: ''
                    },
                    resMessage: res.data.resMessage,
                    resType: res.data.resType,
                    resTitle: res.dada.resTitle,
                    isDocUp: false

                }))
            } else {
                api.logout().then(res => {
                    window.location.replace('/login')
                })
            }
        }).catch(err => {
            this.setState(prev => ({
                docLoader: false,
                docObject: {
                    ...prev.docObject,
                    link: '',
                    id: '',
                    title: ''
                },
                resMessage: "There are some error! Please try again",
                resType: "danger",
                resTitle: "Document Upload Error"
            }))
        })
    }

    removeDoc(item) {
        const reqObj = {
            // docId
            courseId: this.props.match.params.course,
            data: { docId: item.id }
        }
        api.removeCourseDocument(reqObj).then(res => {
            // content
            let content = { ...this.state.content };
            let itemPosition = content.courseDocuments.findIndex(elem => elem.id === item.id);
            content.courseDocuments.splice(itemPosition, 1)
            if (res.data.resCode === 200) {
                this.setState(prev => ({
                    ...prev,
                    content: content,
                    resMessage: res.data.resMessage,
                    resType: res.data.resType,
                    resTitle: res.data.resTitle,
                    docLoader: false,
                }))
            } else if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                this.setState(prev => ({
                    docLoader: false,
                    resMessage: res.data.resMessage,
                    resType: res.data.resType,
                    resTitle: res.dada.resTitle,

                }))
            } else {
                api.logout().then(res => {
                    window.location.replace('/login')
                })
            }
        }).catch(err => {
            this.setState(prev => ({
                docLoader: false,
                resMessage: "There are some error! Please try again",
                resType: "danger",
                resTitle: "Document Remove Error"
            }))
        })
    }

    changeArragneMent(e, topic_name, chapter_id, index = 0) {
        // let tempArranger = JSON.parse(JSON.stringify(this.state.courseArranger));
        let quizzes = [];
        let videos = [];
        // These two on the above is to update these two arrays on selectedChapter object in the state

        let tempArranger = [...this.state.courseArranger];
        let chapter = tempArranger[tempArranger.findIndex(e => e.id === chapter_id)];
        let tempTopic = chapter.allItems[chapter.allItems.findIndex(e => e.name === topic_name)];


        tempTopic.position = e.target.value;
        chapter.allItems.splice(index, 1);
        chapter.allItems.splice(e.target.value, 0, tempTopic);
        chapter.allItems.map((e, i) => e.position = i);

        for (const item of chapter.allItems) {
            if (item.type) {
                if (item.type === "quiz") {
                    quizzes.push(item)
                } else if (item.type === "video") {
                    videos.push(item);
                }
            } else {
                if (item.name.includes("videos")) {
                    videos.push(item);
                }
            }
        }
        this.setState(prev => ({
            ...prev,
            courseArranger: tempArranger
        }), () => {
            this.setState(prevState => ({
                ...prevState,
                selectedChapter: {
                    ...prevState.selectedChapter,
                    quizzes: quizzes,
                    videos: videos
                }
            }), () => {
                console.log("selectedChapter ", this.state.selectedChapter)
            })
        });
    }

    arrangeChapter() {
        this.setState({
            chapterLoader: true,
            chapterSaved: false,
            resChapterMessage: ''
        }, () => {
            let reqObj = {
                courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
                data: {
                    chapter_id: this.state.selectedChapter.id,
                    chapter: {
                        quizzes: this.state.selectedChapter?.quizzes ?? [],
                        videos: this.state.selectedChapter?.videos ?? [],
                    }
                }
            }
            api.arrangeChapter(reqObj).then(res => {
                if (res.data.resCode === 200) {
                    let chapters = this.state.content.chapters;
                    chapters[chapters.map(item => { return item.id }).indexOf(this.state.selectedChapter.id)].quizzes = this.state.selectedChapter.quizzes;
                    chapters[chapters.map(item => { return item.id }).indexOf(this.state.selectedChapter.id)].videos = this.state.selectedChapter.videos;
                    this.setState(prev => ({
                        chapterLoader: false,
                        chapterSaved: true,
                        content: {
                            ...prev.content,
                            chapters: chapters
                        }
                    }), () => {
                        setTimeout(() => {
                            this.setState({
                                chapterSaved: false,
                                resMessage: res.data.resMessage,
                                resType: res.data.resType,
                                resTitle: res.data.resTitle
                            })
                        }, 2000);
                    })
                } else if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                } else {
                    this.setState({
                        chapterLoader: false,
                        resChapterMessage: res.data.resMessage
                    })
                }

            }).catch(err => {
                this.setState({
                    chapterLoader: false,
                    resChapterMessage: "Something went wrong! Please try again"
                });
            });
        })
    }


    render() {
        // console.log('this.state', this.state.selectedChapter)
        // console.log("courseID:", this.props.match.params.course)
        const apiDialog = this.state.resMessage !== '' ? <Modal>
            <ApiModal
                dismiss={() => { this.setState({ resMessage: '', resType: '', reload: '', resTitle: '' }) }}
                modalData={{
                    resMessage: this.state.resMessage,
                    resType: this.state.resType,
                    resTitle: this.state.resTitle
                }}
            />
        </Modal> : null;

        const documentUploadModal = this.state.isDocUp ? <Modal>
            <div className="modal " >
                <div className="modal-dialog modal-md">
                    <div className="modal-content add-documents">
                        <div className="modal-header">
                            <div className="modal-title">
                                <h3>Add Documents</h3>
                            </div>
                            <button type="button" className="btn-close btn-sm" onClick={() => { this.setState({ isDocUp: false }) }} aria-label="Close"></button>
                        </div>
                        <div className="modal-body add-document-content">
                            <div className="row">
                                <div className="btn-group adc-btn-group" role="group">
                                    <button type="button" className={`btn btn-${this.state.uploadDocType === 'upload' ? 'primary' : 'light border'}`} onClick={() => { this.setState({ uploadDocType: 'upload' }) }}>Upload Document</button>
                                    <button type="button" className={`btn btn-${this.state.uploadDocType === 'url' ? 'primary' : 'light border'}`}
                                        onClick={() => {
                                            this.setState({
                                                uploadDocType: 'url'
                                            }, () => {
                                                console.log('dtype', this.state.uploadDocType)
                                            })
                                        }}
                                    >Add Useful Link</button>
                                </div>
                            </div>
                            <div className='row'>
                                {this.state.uploadDocType === 'upload' &&
                                    <div className='col-12'>
                                        <div className='fileupload'>
                                            <h5>Upload Document file from your system.</h5>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Add Documents Title</label>
                                                <input type="text" className="form-control" placeholder='Add Title' defaultValue=""
                                                    onChange={(e) => {
                                                        this.setState(prev => ({
                                                            docObject: {
                                                                ...prev.docObject,
                                                                title: e.target.value
                                                            }
                                                        }), () => {
                                                            // console.log('doc_data', this.state.docObject)
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Upload your document</label>
                                                <input type="file" className="form-control" placeholder='Add Title'
                                                    onChange={(e) => {
                                                        this.setState(prev => ({
                                                            fileData: {
                                                                ...prev.fileData,
                                                                event: e,
                                                                type: 'docs'
                                                            }
                                                        }), () => {
                                                            // console.log('filedata ', this.state.fileData)
                                                        });
                                                        //this.fileUpload(e, 'video');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.uploadDocType === 'url' &&
                                    <div className='col-12'>
                                        <div className='add-filelinks'>
                                            <h5>Add useful learning link</h5>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Add Link Title</label>
                                                <input type="text" className="form-control" placeholder='Add Title'
                                                    defaultValue=""
                                                    onChange={(e) => {
                                                        this.setState(prev => ({
                                                            docObject: {
                                                                ...prev.docObject,
                                                                title: e.target.value
                                                            }
                                                        }), () => {
                                                            // console.log('doc_data', this.state.docObject)
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="exampleInputEmail1">Copy and Paste Link</label>
                                                <input type="text" className="form-control" placeholder='Add Link'
                                                    defaultValue=""
                                                    onChange={(e) => {
                                                        this.setState(prev => ({
                                                            docObject: {
                                                                ...prev.docObject,
                                                                link: e.target.value
                                                            }
                                                        }), () => {
                                                            // console.log('doc_data', this.state.docObject)
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-default" onClick={() => { this.setState({ isDocUp: false }) }}>Cancel</button>
                            <button type="button" className="btn btn-primary" disabled={this.state.docLoader}
                                onClick={() => {
                                    if (this.state.uploadDocType === 'upload') {
                                        this.fileUpload(this.state.fileData.event, 'docs');
                                    } else {
                                        this.setState(prev => ({
                                            docObject: {
                                                ...prev.docObject,
                                                id: uuidv4()
                                            }
                                        }), () => {
                                            this.addDocument()
                                        })
                                    }
                                }}>Save {this.state.docLoader && <span className="spinner-border spinner-border-sm"></span>}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal> : null;

        const loader = this.state.loader ? <Modal>
            <Loader message={"Loading..."} />
        </Modal> : null;

        const videoTitle = this.state.isVideoTitle ? <Modal>
            <div className="modal " >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Video title</h5>
                            <button type="button" className="btn-close" onClick={() => { this.setState({ isVideoTitle: false }) }}></button>
                        </div>
                        <div className="modal-body input-group">
                            {/* <div className="input-group"> */}
                            <input type="text" className="form-control" value={this.state.vtitle}
                                onChange={(e) => { this.setState({ vtitle: e.target.value }) }}
                                placeholder="Enter video title here" />
                            <span className={`input-group-text bg-${this.state.vtitle !== '' && this.state.vtitle ? 'primary' : 'secondary'} text-light`} onClick={() => {
                                if (this.state.vtitle !== '' && this.state.vtitle) {
                                    this.setState({
                                        isVideoTitle: false
                                    }, () => {
                                        this.fileUpload(this.state.fileData.event, 'video');
                                    })

                                }

                            }}><i className="bi bi-arrow-right" /></span>
                            {/* </div> */}
                        </div>

                    </div>
                </div>
            </div>
        </Modal> : null;
        // const courseInfo = this.state.isCourseInfo ? <Modal>
        //     <div className="alert alert-light courseInfo_modal">
        //         <CourseInfo dismiss={() => { this.setState({ isCourseInfo: false }) }} />
        //     </div>
        // </Modal> : null;
        return (
            <React.Fragment>
                {this.state.isCourseInfo &&
                    <div className="profile-right-inner">
                        <div className="container course-edit">
                            <div className="page-header">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="page-title">
                                            <h2>{this.state.title}</h2>
                                            <h6>Marketing Management for New Manager</h6>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="btn-block">
                                            {/* <button type="button" className="btn btn-highlight-secondary" onClick={() => this.setState({ isCourseInfo: true })}><i className="bi bi-pencil-square"></i> Edit Course</button> */}
                                            <Link to={`/account/${window.location.pathname.split('/')[2]}/editCourseInfo/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className="btn btn-highlight-secondary" ><i className="bi bi-pencil-square"></i> Edit Course</Link>
                                            <button type="button" className="btn btn-highlight-secondary"><i className="bi bi-save"></i> Save Course</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="page-body">
                                <div className="course-edit-container">
                                    <div className="progress-steps-container">
                                        <ul className="progress-steps">
                                            <li className={this.state.content.chapters && this.state.content.chapters.length > 0 ? 'active' : ''}>
                                                <span className="number">1</span>
                                                <h5>Add Content</h5>
                                            </li>
                                            <li className={this.state.content.simulator && this.state.content.simulator.length > 0 ? 'active' : ''}>
                                                <span className="number">2</span>
                                                <h5>Add Simulator</h5>
                                            </li>
                                            <li className={this.state.content.finalAssesment && this.state.content.finalAssesment.length > 0 ? 'active' : ''}>
                                                <span className="number">3</span>
                                                <h5>Add Final Assesment</h5>
                                            </li>
                                            <li className={`${this.state.courseStatus === "submitted" ? 'active' : ''} ${this.state.courseStatus === "rejected" ? 'bg-danger text-light' : ''}`}>
                                                <span className="number">4</span>
                                                {(this.state.courseStatus !== 'rejected' && this.state.courseStatus !== 'published') &&
                                                    <h5>Submitted</h5>
                                                }
                                                {this.state.courseStatus === 'rejected' &&
                                                    <h5>Rejected</h5>
                                                }
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="course-steps">
                                        <div className="accordion" id="accordianPanelOpen">
                                            <div className="accordion-item completed-steps">
                                                <span className={`steps-count ${this.state.content.chapters && this.state.content.chapters.length > 0 ? 'completed-number' : ''}`}><i className="bi bi-check2"></i></span>
                                                <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                                                        <span className="text-highlight">Upload Course Video -</span>  Topic wise
                                                    </button>
                                                </h2>
                                                <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                                                    <div className="accordion-body">
                                                        <div className="row">
                                                            <div className="col-sm-6">
                                                                {this.state.selectedChapter === null &&
                                                                    <div className="card-lg border shadow-none bg-light">
                                                                        <h5>You havn't selected any topic</h5>
                                                                    </div>
                                                                }
                                                                {this.state.selectedChapter !== null &&

                                                                    <React.Fragment>
                                                                        <div className="video-upload">
                                                                            <form className="video-upload-form" >
                                                                                <label type="button" htmlFor="file" className="btn btn-highlight-primary btn-v-upload"><i className="bi bi-cloud-arrow-up"></i>
                                                                                    {!this.state.vidoeUploader ? <>Upload video</> :
                                                                                        <>Uploading...
                                                                                            <div className="spinner-border text-light" role="status">
                                                                                                <span className="visually-hidden">Loading...</span>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </label>
                                                                                <input type="file" style={{ display: 'none' }} id="file" name="file" accept="video/*"
                                                                                    onChange={(e) => {
                                                                                        this.setState(prev => ({
                                                                                            isVideoTitle: true,
                                                                                            fileData: {
                                                                                                ...prev.fileData,
                                                                                                event: e,
                                                                                                type: 'video'
                                                                                            }
                                                                                        }));
                                                                                        //this.fileUpload(e, 'video');
                                                                                    }}
                                                                                />
                                                                                {/* 
                                                                                <div className="video-title">
                                                                                    <input type="text" className="form-control" placeholder="Video Title" name="title" id="title" />
                                                                                    <p className="btnSubmit-block"><button type="submit" className="btn btn-submit">Submit</button></p>
                                                                                </div>
                                                                             */}
                                                                                <div className="form-group input-group mt-2">
                                                                                    <input type="text" value={this.state.upload_video_id} className="form-control"
                                                                                        onChange={(e) => {
                                                                                            this.setState({
                                                                                                upload_video_id: e.target.value
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    <button className="btn btn-primary input-group-text">Add</button>
                                                                                </div>


                                                                            </form>
                                                                            {this.state.selectedChapter.videos && this.state.selectedChapter.videos.length > 0 &&
                                                                                <React.Fragment>
                                                                                    {this.state.selectedChapter.videos.map((vitem, v_index) => {
                                                                                        return <React.Fragment key={`${vitem.name}-${v_index}`}>
                                                                                            <div className="video-block">
                                                                                                <div className="video-stored">
                                                                                                    <Suspense fallback={
                                                                                                        <div >
                                                                                                            Loading...
                                                                                                        </div>
                                                                                                    }>
                                                                                                        <VimeoPlayer
                                                                                                            id={vitem.name}
                                                                                                            options={{
                                                                                                                width: 320,
                                                                                                                byline: false, // vimeo owner's name
                                                                                                                color: 'ff0000',
                                                                                                            }} />
                                                                                                    </Suspense>
                                                                                                </div>
                                                                                                <div className='btn-editDelete'>
                                                                                                    <button type="button" className="btn btn-highlight-danger btn-delete" onClick={() => this.removeVideo(vitem.name)}><span><i className="bi bi-trash"></i></span> Delete</button>
                                                                                                    <button type="button"
                                                                                                        className={`btn btn-highlight-${this.state.selected_video !== null && this.state.selected_video.name === vitem.name ? 'success' : 'primary'} btn-delete`}
                                                                                                        onClick={(e) => this.state.selected_video !== null && this.state.selected_video.name === vitem.name ? this.updateVideo() : this.setState({ selected_video: { ...vitem } })}>
                                                                                                        <span><i className={`bi bi-${this.state.selected_video !== null && this.state.selected_video.name === vitem.name ? 'save' : 'pencil-square'} text-primary`}></i></span>
                                                                                                        {this.state.selected_video !== null && this.state.selected_video.name === vitem.name ? (<small>Save</small>) : (<small>Edit</small>)}
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>
                                                                                            {this.state.selected_video !== null && this.state.selected_video.name === vitem.name &&
                                                                                                <div className="add-input">
                                                                                                    <div className="form-group">
                                                                                                        <input className="form-control" type="text" name="videotitle" placeholder="Add Video Title"
                                                                                                            value={this.state.selected_video.title}
                                                                                                            onChange={(e) => {
                                                                                                                this.setState(prev => ({
                                                                                                                    ...prev,
                                                                                                                    selected_video: {
                                                                                                                        ...prev.selected_video,
                                                                                                                        title: e.target.value
                                                                                                                    }
                                                                                                                }))
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="form-group">
                                                                                                        <textarea className="form-control" placeholder="Leave a comment here"
                                                                                                            value={this.state.selected_video.desc} name="videoDesc"
                                                                                                            onChange={(e) =>
                                                                                                                this.setState(prev => ({
                                                                                                                    selected_video: {
                                                                                                                        ...prev.selected_video,
                                                                                                                        desc: e.target.value
                                                                                                                    }
                                                                                                                }))}
                                                                                                        >

                                                                                                        </textarea>
                                                                                                    </div>
                                                                                                </div>
                                                                                            }

                                                                                        </React.Fragment>
                                                                                    })}
                                                                                </React.Fragment>}
                                                                        </div>
                                                                        <div className="add-interaction">
                                                                            <h5><span>Add Interaction</span> - To make your learner more proficient</h5>
                                                                            <h6>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized.</h6>
                                                                            <div className="btn-block">
                                                                                <a href={`/quiz?courseId=${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}&chapterId=${this.state.selectedChapter.id}`} target="_blank" className={`btn btn-highlight-${this.state.selectedChapter.quiz ? 'success' : 'grey'}`}><i className="bi bi-plus-circle-fill"></i>
                                                                                    Add Interaction
                                                                                </a>
                                                                                <br />

                                                                                {this.state.selectedChapter?.quizzes?.map((quiz, qi) => {
                                                                                    return <React.Fragment key={`quiz-q-${qi * 556}`}><a href={`/quiz?courseId=${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}&chapterId=${this.state.selectedChapter.id}&quizName=${quiz.name}`} target="_blank" className={`btn btn-highlight-primary btn-editQuiz}`}><i className="bi bi-plus-circle-fill"></i>
                                                                                       Edit {/* {quiz?.title || 'Quiz'} */}
                                                                                    </a>
                                                                                        <button className="btn btn-danger btn-deleteQuiz"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                let data = {
                                                                                                    courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1],
                                                                                                    reqObj: {
                                                                                                        chapter_id: this.state.selectedChapter.id,
                                                                                                        quiz: quiz
                                                                                                    }

                                                                                                }
                                                                                                api.removeQuiz(data).then(res => {
                                                                                                    if (res.data.resCode === 200) {
                                                                                                        let quizzes = [...this.state.selectedChapter.quizzes];
                                                                                                        let index = quizzes.findIndex(elem => elem.name === quiz.name);
                                                                                                        quizzes.splice(index, 1);
                                                                                                        this.setState(prev => ({
                                                                                                            ...prev,
                                                                                                            selectedChapter: {
                                                                                                                ...prev.selectedChapter,
                                                                                                                quizzes: quizzes
                                                                                                            }
                                                                                                        }))
                                                                                                    }
                                                                                                })
                                                                                            }}>
                                                                                            <i class="bi bi-trash"></i>Delete
                                                                                        </button>
                                                                                    </React.Fragment>
                                                                                })}
                                                                                &nbsp;
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                }
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <div className="all-chapter">
                                                                    <div className="chapter-title">All Topics</div>
                                                                    <div className="card-lg chapter-mainCard">
                                                                        {this.state.content.chapters &&
                                                                            <React.Fragment>
                                                                                {this.state.content.chapters.map((chapter, c_index) => {
                                                                                    return <div key={uuidv4()} className={`card chapter-card ${this.state.selectedChapter !== null && this.state.selectedChapter.id === chapter.id ? 'bg-primary chapter-active' : ''}`}
                                                                                        onClick={(e) =>
                                                                                            this.setState({ selectedChapter: null }, () => {
                                                                                                // setTimeout(()=>{
                                                                                                this.setState({ selectedChapter: chapter })
                                                                                                // },1000);

                                                                                            })
                                                                                        }>
                                                                                        <div className="card-body dropdown">
                                                                                            <label className="chapter-number">
                                                                                                {c_index < 9 ? <span>{'0' + (c_index + 1)}</span> : <span>{c_index + 1}</span>}
                                                                                            </label>
                                                                                            <h5>{chapter.title}</h5>
                                                                                            <label className="chapter-duration">{this.format(this.chapterDuration(chapter))}</label>
                                                                                            <button type="button" className="btn dots-absolute dropdown-toggle" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                                                <li>Add New Topic</li>
                                                                                                <li><button type="button" className="btn btn-highlight-grey btn-addChapter"><i className="bi bi-plus"></i> Add above</button></li>
                                                                                                <li><button type="button" className="btn btn-highlight-grey btn-addChapter"><i className="bi bi-plus"></i> Add below</button></li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                })}
                                                                            </React.Fragment>
                                                                        }
                                                                        <div className="btn-block">
                                                                            <button type="button" className="btn add-new-chapter" onClick={(e) => this.addChapter()}><i className="bi bi-plus-circle-fill"></i> Add New Topic</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.selectedChapter !== null &&
                                                            <><form className='titleDesc'>
                                                                <div className="row">
                                                                    <div className='col-md-6'>
                                                                        <div className="form-group">
                                                                            <label>Title</label>
                                                                            <input type="text" className="form-control" value={this.state.selectedChapter.title}
                                                                                onChange={(e) => {
                                                                                    this.setState(prev => ({
                                                                                        selectedChapter: {
                                                                                            ...prev.selectedChapter,
                                                                                            title: e.target.value
                                                                                        }
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <div className="form-group">
                                                                            <label>Description</label>
                                                                            <textarea className="form-control" value={this.state.selectedChapter.desc}
                                                                                onChange={(e) => {
                                                                                    this.setState(prev => ({
                                                                                        selectedChapter: {
                                                                                            ...prev.selectedChapter,
                                                                                            desc: e.target.value
                                                                                        }
                                                                                    }))
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="col-md-6"></div> */}
                                                                    {/* <div className="col-md-2">
                                                            <button type="button" className="btn btn-highlight-primary me-2">Save Chapter</button>
                                                            <button type="button" className="btn btn-highlight-danger btn-delete" onClick={(e) => this.removeChapter(this.state.selectedChapter.id)}>Delete Chapter</button>
                                                        </div> */}
                                                                </div>
                                                            </form>
                                                                <div className='aboutQuiz'>
                                                                    <div className="row">
                                                                        <div className="col-md-12">
                                                                            {/* courseArranger */}
                                                                            {this.state.selectedChapter !== null &&

                                                                                <ul className="list-group">
                                                                                    {this.state.courseArranger[this.state.courseArranger.findIndex(e => e.id === this.state.selectedChapter.id)] &&
                                                                                        <>
                                                                                            {this.state.courseArranger[this.state.courseArranger.findIndex(e => e.id === this.state.selectedChapter.id)].allItems.map((topic, ti) => {

                                                                                                return <li key={`topic-${topic.type}-${topic.position + ti}-index-${ti}`} className="float-start list-group-item">
                                                                                                    <p>{topic.title}</p>
                                                                                                    <small>{topic.type}</small>
                                                                                                    <select className="form-control" value={topic.position} onChange={(e) => this.changeArragneMent(e, topic.name, this.state.selectedChapter.id, ti)}>
                                                                                                        <option>Select</option>
                                                                                                        {Array.from(Array(this.state.courseArranger[this.state.courseArranger.findIndex(e => e.id === this.state.selectedChapter.id)].allItems.length), (_, i) => {
                                                                                                            return <option key={uuidv4()} value={i}>Position : {i + 1}</option>
                                                                                                        })}

                                                                                                        {/* return <li key={`topic-${topic.type}-${topic.position + ti}-index-${ti}`} className="float-start list-group-item">
                                                                                                                <p>{topic.title}</p>
                                                                                                                <small>{topic.type}</small>
                                                                                                                <select className="form-control" value={topic.position} onChange={(e) => this.changeArragneMent(e, topic.name, this.state.selectedChapter.id, ti)}>
                                                                                                                    <option>Select</option>
                                                                                                                    {Array.from(Array(this.state.courseArranger[this.state.courseArranger.findIndex(e => e.id === this.state.selectedChapter.id)].allItems.length), (_, i) => {
                                                                                                                        return <option key={uuidv4()} value={i}>Position : {i + 1}</option>
                                                                                                                    })} */}

                                                                                                    </select>
                                                                                                </li>
                                                                                            })}
                                                                                        </>
                                                                                    }

                                                                                </ul>

                                                                            }
                                                                            <div className="d-grid mt-2">
                                                                                <button type="button" className="btn btn-primary" onClick={() => this.arrangeChapter()}>Save Arrangement</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        <div className="btn-block save-delete">
                                                            <button type="button" className="btn btn-highlight-primary" onClick={() => this.updateChapter()}>Save Topic
                                                                {this.state.chapterLoader &&
                                                                    <span className="spinner-border text-light" style={{ width: '1rem', height: '1rem' }}>
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </span>
                                                                }
                                                            </button>
                                                            <button type="button" className="btn btn-highlight-danger btn-delete" onClick={(e) => this.removeChapter(this.state.selectedChapter.id)}>Delete Topic</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <span className={`steps-count ${this.state.content.simulator && this.state.content.simulator.length > 0 ? 'completed-number' : ''}`}>2</span>
                                                <span className="skip-simulator">Skip Simulator</span>
                                                <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                                        <span className="text-highlight">Add Simulator -</span> To make your course more attactive
                                                    </button>
                                                </h2>
                                                <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                                                    <div className="accordion-body">
                                                        <div className="add-interaction common-accord">
                                                            <h6>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized.</h6>
                                                            <div className="btn-block">
                                                                <button type="button" className="btn btn-highlight-grey"><i className="bi bi-plus-circle-fill"></i> Add Simulator</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <span className={`steps-count ${this.state.content.finalAssesment && this.state.content.finalAssesment.length > 0 ? 'completed-number' : ''}`}>3</span>
                                                <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                                        <span className="text-highlight">Add Final Asesment -</span> To verify Learner’s performance
                                                    </button>
                                                </h2>
                                                <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
                                                    <div className="accordion-body">
                                                        <div className="add-interaction common-accord">
                                                            <h6>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized.</h6>
                                                            <div className="btn-block">
                                                                <a href={`/quiz?courseId=${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className={`btn btn-highlight-${this.state.content.finalAssesment ? 'success' : 'primary'}`}><i className="bi bi-plus-circle-fill"></i>
                                                                    {!this.state.content.finalAssesment && <>Add Final Assesment</>}
                                                                    {this.state.content.finalAssesment && <>Edit Final Assesment</>}
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="btn-block btn-submit-block">
                                        <button type="button" onClick={() => { this.setState({ isDocUp: true }) }} className="btn btn-primary btn-add-document"><i className="bi bi-plus-circle-fill"></i>Add Documents</button><br />
                                        {this.state.content.courseDocuments && this.state.content.courseDocuments.length > 0 &&
                                            <>
                                                {this.state.content.courseDocuments.map((doc_item, doc_i) => {
                                                    return <span key={uuidv4()} className="badge bg-primary mx-2 mt-2">{doc_item.title} <i onClick={() => { this.removeDoc(doc_item) }} className="bi bi-x" style={{ cursor: 'pointer', fontSize: '16px' }} /></span>
                                                })}
                                            </>
                                        }
                                    </div>
                                    <div className="btn-block btn-submit-block">
                                        <a href={`/courseDetails/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className="btn btn-highlight-primary btn-preview-course">Preview Course <i className="bi bi-binoculars"></i></a>
                                        <button type="button" onClick={(e) => this.submitCourse()} className="btn btn-highlight-success btn-submit-course">Submit Course <i className="bi bi-arrow-right-short"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {!this.state.isCourseInfo &&
                    <div className={`alert alert-warning`}>
                        <h3>Permission Denied</h3>
                        <p>Please check your access control or permissions for this request. Contact ADMIN for more details</p>
                    </div>
                }
                {apiDialog}
                {/* {courseInfo} */}
                {loader}
                {videoTitle}
                {documentUploadModal}
            </React.Fragment>
        )
    }

}

export default withRouter(CourseEdit);