import React, { Component } from "react";
import "./updatePassword.scss";
import axllearnlogo from "../../../assets/images/logo-black.png";
import sadEmoji from '../../../assets/images/very-sad.png';

import { ApiModal, Modal } from "../../shared/modal/modal";
import Loader from "../../shared/loader/loader";
import api from "../../../services/Api/Api";
import { isValidPassword } from "../../../utils/constant";

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: true,
      formdata: [],
      resMessage: "",
      resType: "",
      resMessageAuth: "",
      resTypeAuth: "",
      errors: { password: '', cpassword: '' },
      type: "password",
      password: "",
      confirmPassword: "",
      passwordtype: "password",
      linkedExpired: false,
    };
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);
  }

  componentDidMount() {
    const token = window.location.href.split('=')[1];
    console.log(token)
    const body = { pwtoken: token }
    this.checkValidityForgotMailLink(body)
  }
  checkValidityForgotMailLink(payload) {
    api.checkForgotPasswordLinkValidity(payload).then(res => {
      if (res.data.resCode === 200) {
        this.setState({
          loader: false,
        })
      } else {
        this.setState({
          loader: false,
          linkedExpired: true,
          resMessage: res.data.resMessage,
          resType: res.data.resType
        })
      }
    }).catch(err => {
      this.setState({
        loader: false,
        resMessage: "Something went wrong! Please try again",
        resType: 'danger'
      })
    })
  }
  handlePasswordChange(e) {
    // let name = e.target.name;
    let password = e.target.value;
    this.setState({
      password: password,
      errors: {
        password: '',
        cpassword: ''
      }
    });
  }
  handleConfirmPasswordChange(e) {
    let confirmPassword = e.target.value;
    this.setState({
      confirmPassword: confirmPassword,
      errors: {
        password: '',
        cpassword: ''
      }
    });
  }
  authenticate(e) {
    e.preventDefault();
    if (!isValidPassword(this.state.password) || this.state.confirmPassword !== this.state.password) {
      if (!isValidPassword(this.state.password)) {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            password: "Password must be mixed with uppercase, number, special characters & with min 8 characters"
          }
        }))
      }
      else {
        this.setState(prevState => ({
          errors: {
            ...prevState.errors,
            cpassword: "Confirm password must be same as above"
          }
        }))
      }
      return;
    }

    let updatePassword = {
      password: this.state.confirmPassword,
      pwtoken: window.location.search.substring(9),
    };
    this.setState(
      {
        loader: true,
        resMessage: "",
        resType: "",
      },
      () => {
        api
          .updatePassword(updatePassword)
          .then((res) => {
            console.log(("res=", res));
            if (res.data.resCode === 200) {
              this.setState({
                loader: false,
                resMessage: res.data.resMessage,
                resType: res.data.resType,
                password: "",
                confirmPassword: "",
              });
            } else {
              this.setState({
                loader: false,
                resMessage: res.data.resMessage,
                resType: res.data.resType,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loader: false,
              resMessage: "Something went wrong! Please try again..",
              resType: "danger",
              password: "",
              confirmPassword: "",
            });
          });
      }
    );
  }
  handleClick = () =>
    this.setState(({ type }) => ({
      type: type === "text" ? "password" : "text",
    }));
  handlePasswordClick = () =>
    this.setState(({ passwordtype }) => ({
      passwordtype: passwordtype === "text" ? "password" : "text",
    }));

  render() {
    const apiDialog =
      this.state.resMessage !== "" ? (
        <Modal>
          <ApiModal
            dismiss={() => {
              if (this.state.resType === 'success')
                window.location.replace('/login');
              else {
                this.setState({
                  resMessage: "",
                  resType: "",
                  reload: "",
                  resTitle: "",
                });

              }
            }}
            modalData={{
              resMessage: this.state.resMessage,
              resType: this.state.resType,
              resTitle: this.state.resTitle,
            }}
          />
        </Modal>
      ) : null;
    const loader = this.state.loader ? (
      <Modal>
        <Loader message={"Loading..."} />
      </Modal>
    ) : null;
    return (
      <React.Fragment>
        <div className="signinContainer updatePassword">
          <div className="innerContainer">
            {this.state.linkedExpired ? (<div className='linkExpired'>
              <div>
                <p><img src={sadEmoji} alt="///"></img></p>
                <h4>Sorry, Your link has expired.</h4>
              </div>
            </div>) : (<>
              <div className="signinBox">
                <a href={`/`} className="signupLogo">
                  <img src={axllearnlogo} alt="///"></img>
                </a>
                <div className="signinForm">
                  <h2>Update Password</h2>
                  <p>Enter your new password and confirm password<br></br> then click on submit button.</p>
                  <div className="fieldContainer position-relative">
                    {this.state.resMessageAuth !== "" && (
                      <div
                        className={`alert alert-${this.state.resTypeAuth} p-0 text-center`}
                      >
                        <small>{this.state.resMessageAuth}</small>
                        <i
                          className="bi bi-x-circle float-end me-2 btn p-0 text-danger"
                          onClick={() => {
                            this.setState({
                              resTypeAuth: "",
                              resMessageAuth: "",
                            });
                          }}
                        ></i>
                      </div>
                    )}

                    <form
                      onSubmit={(e) => {
                        this.authenticate(e);
                      }}
                    >
                      <div className="fieldRow input-group">
                        <span className='pwd-hint'><i class="bi bi-info-circle-fill" title=''></i>
                          <span className='hint-content'>Your password must be mixed with uppercase, number, special characters & with min 8 characters.</span>
                        </span>
                        <span className="input-group-text" id="basic-addon1">
                          <i className="bi bi-shield-lock"></i>
                        </span>
                        <input
                          type={this.state.passwordtype}
                          className="form-control"
                          id="password"
                          value={this.state.password}
                          placeholder="New Password"
                          onChange={this.handlePasswordChange}
                        ></input>
                        <span
                          className="input-group-text password__show"
                          onClick={this.handlePasswordClick}
                        >
                          {/* {this.state.passwordtype === "text" ? "Hide" : "Show"} */}
                          {this.state.passwordtype === "text" ? (
                            <i class="bi bi-eye-slash-fill"></i>
                          ) : (
                            <i class="bi bi-eye-fill"></i>
                          )}
                        </span>
                        <small className="text-danger error-mgs">{this.state.errors.password && this.state.errors.password !== '' ? <>{this.state.errors.password}</> : ''}</small>
                      </div>
                      <div className="fieldRow input-group">
                        <span className='pwd-hint'><i class="bi bi-info-circle-fill" title=''></i>
                          <span className='hint-content'>It must be same as above password.</span>
                        </span>
                        <span className="input-group-text" id="basic-addon1">
                          <i className="bi bi-shield-lock"></i>
                        </span>
                        <input
                          type={this.state.type}
                          value={this.state.confirmPassword}
                          className="form-control"
                          id="confirmPassword"
                          placeholder="Confirm New Password"
                          onChange={this.handleConfirmPasswordChange}
                        ></input>
                        <span
                          className="input-group-text password__show"
                          onClick={this.handleClick}
                        >
                          {/* {this.state.type === "text" ? "Hide" : "Show"} */}
                          {this.state.type === "text" ? (
                            <i class="bi bi-eye-slash-fill"></i>
                          ) : (
                            <i class="bi bi-eye-fill"></i>
                          )}
                        </span>
                        <small className="text-danger error-mgs">{this.state.errors.cpassword && this.state.errors.cpassword !== '' ? <>{this.state.errors.cpassword}</> : ''}</small>
                      </div>
                      <div className="fieldRow resetPassword">
                        <input type="submit" className="linkSignin" value="Submit" />
                        <a href={`/login`} className="returnLogin">Return to Signin</a>
                      </div>
                      {/* <div className="fieldRow">
                      <p>
                        <a href={`/login`}>Return to Signin</a>
                        <a href={`/login`}>Click here to sign up</a>
                      </p>
                    </div>

                    <div className="fieldRow">
                      <p>
                        <input
                          type="submit"
                          className="linkSignin"
                          value="Submit"
                        />
                      </p>
                    </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </>)}

          </div>
        </div>
        {apiDialog}
        {loader}
      </React.Fragment>
    );
  }
}

export default UpdatePassword;
