import React from 'react';
import './accountsettings.scss';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid';

import api from '../../../services/Api/Api';

import Profile from './profile/profile';
import CourseLibrary from './CourseLibrary/CourseLibrary';
import LearnerLibrary from './LearnersLibrary/LearnersLibrary';
import AdminCourseList from './AdminCourseList/AdminCourseList';
import CourseEdit from './CourseEdit/CourseEdit';
import { CourseInfo } from './CourseEdit/CourseInfo/CourseInfo';




class Accountsettings extends React.Component {
    constructor(props) {
        super(props);
        this.sideMenuRef = React.createRef();
        this.state = {
            loader: false,
            resMessage: '',
            resType: '',
            reCall: '',

            name: '',
            profileImage: '',
            links: [],
            userType: '',
            show: '',
            paramvalue: ''
        }
    }
    handleScroll = (e) => {
        // let page_Y = e.path[1].window.pageYOffset;
        let page_Y = window.pageYOffset;
        // console.log('page_Y', page_Y, document.documentElement.scrollHeight - document.getElementsByClassName('axllearn-footer')[0].offsetTop, document.getElementsByClassName('axllearn-footer')[0].offsetTop);
        // console.log( document.getElementsByClassName('axllearn-footer')[0].offsetTop);
        // if (this.sideMenuRef.current) {
        //     if (page_Y > document.documentElement.scrollHeight - document.getElementsByClassName('axllearn-footer')[0].offsetTop) {
        //         this.sideMenuRef.current.style.top = -(document.documentElement.scrollHeight - this.sideMenuRef.current.clientHeight - 100) + 'px';
        //     }else {
        //         this.sideMenuRef.current.removeAttribute('style');
        //     }
        // }
    };

    componentWillUnmount() {
        document.removeEventListener("click", this.handleScroll);
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        this.getAccountSettings();
    }




    getAccountSettings() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: '',
            reCall: '',
        }, () => {
            api.getAccount().then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        links: res.data.data.links,
                        userType: res.data.data.userType,
                        profileImage: res.data.data.profilePic,
                        name: res.data.data.name,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    }, () => {
                        console.log('name', this.state)
                    })
                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        reCall: 'getAccountSettings'
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again..",
                    resType: 'danger'
                })
            })
        })

    }

    parseQueryString() {
        let str = window.location.search;
        let objURL = {};
        str.replace(
            new RegExp("([^?=&]+)(=([^&]*))?", "g"),
            function ($0, $1, $2, $3) {
                objURL[$1] = $3;
            }
        );
        return objURL;
    };
    parseRoute() {
        return window.location.pathname;
    }

    getProfileData(data) {
        console.log('data', data);
        this.setState({
            profileImage: data.profilePic,
            name: data.firstName + ' ' + data.lastName
        })
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content">
                    <div className="user-profile">
                        <div className={`profile-left ${this.state.show ? "active" : ""}`} ref={this.sideMenuRef}>
                            <div className="user-info">
                                <div className="user-photo">
                                    {!this.state.loader &&
                                        <img alt="///" src={api.getFileByName(this.state.profileImage)}
                                            onError={(e) => {
                                                e.target.parentNode.className = e.target.parentNode.className + ' bg-primary text-light';
                                                if (!this.state.name.includes('@')) {
                                                    e.target.parentNode.setAttribute("data-name", this.state.name.charAt(0) + this.state.name.split(' ')[this.state.name.split(' ').length - 1].charAt(0));
                                                }
                                                e.target.style.opacity = 0;
                                            }}
                                            onLoad={(e) => { e.target.style.opacity = 1; }}
                                        />
                                    }
                                </div>
                                <h5>
                                    {this.state.name === '' ? <>{this.state.email}</> : <>{this.state.name}</>}
                                </h5>
                            </div>
                            <ul className="profile-nav">
                                {this.state.links.map(litem => {
                                    return <li key={uuidv4()} className={`${this.parseRoute().includes(litem.code) ? 'active' : ''}`}>
                                        <a href={litem.rUrl}>{litem.label} <span className="menu-icon"><i className={`bi ${litem.icon}`}></i></span></a>
                                    </li>
                                })}
                            </ul>
                            <button onClick={() => { this.setState({ show: !this.state.show }) }} className="btn btn-light-grey left-menu-sm"><i className="bi bi-chevron-right"></i></button>
                        </div>
                        <div className="profile-right">
                            <Router>
                                <Switch>
                                    <Route name='profile' path="/account/:id/profile" >
                                        <Profile profile={(data) => this.getProfileData(data)} />
                                    </Route>
                                    <Route name='edit' path="/account/:id/CourseEdit/:course" >
                                        <CourseEdit />
                                    </Route>
                                    <Route name='info' path="/account/:id/editCourseInfo/:course">
                                        <CourseInfo />
                                    </Route>

                                    <Route name='courses' path="/account/:id/courses" component={() => (<CourseLibrary />)} />
                                    <Route name='mycourses' path="/account/mycourses" component={() => (<LearnerLibrary />)} />
                                    <Route name='adminsubmit' path="/account/admin/submitted" component={() => (<AdminCourseList />)} />
                                    <Redirect from="/account/:id" exact to="/account/:id/profile" />
                                </Switch>
                            </Router>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Accountsettings;