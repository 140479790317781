import React, { Component } from "react";
import "./forgotPassword.scss";
import axllearnlogo from "../../../assets/images/logo-black.png";

import { ApiModal, Modal } from "../../shared/modal/modal";
import Loader from "../../shared/loader/loader";
import api from "../../../services/Api/Api";
import { validateEmail } from "../../../utils/constant";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      email: "",
      resMessage: "",
      resType: "",
      resMessageAuth: "",
      resTypeAuth: "",
    };
    this.handleFormChange = this.handleFormChange.bind(this);
  }
  handleFormChange(e) {

    this.setState({
      email: e.target.value,
    });
  }

  authenticate(e) {
    e.preventDefault();
    const currentURL = window.location.origin;
    let forgotPassword = {
      email: this.state.email,
      url: currentURL,
    };
    console.log("currentURL =", currentURL);

    this.setState(
      {
        loader: true,
        resMessage: "",
        resType: "",
      },
      () => {
        api
          .forgotPassword(forgotPassword)
          .then((res) => {
            console.log(("res=", res));
            if (res.data.resCode === 200) {
              this.setState({
                loader: false,
                resMessage: res.data.resMessage,
                resType: res.data.resType,
                email: "",
              });
            } else {
              this.setState({
                loader: false,
                resMessage: res.data.resMessage,
                resType: res.data.resType,
              });
            }
          })
          .catch((err) => {
            this.setState({
              loader: false,
              resMessage: "Something went wrong! Please try again..",
              resType: "danger",
              email: "",
            });
          });
      }
    );
  }

  render() {
    const apiDialog =
      this.state.resMessage !== "" ? ( <Modal>
            <div className={`modalAlert text-center alert alert-${this.state.resType}`}>
            <div className='alertContentBlock'>
                <p>{this.state.resMessage}</p>              
                
                <button type="button" className={`btn btn-${this.state.resType}`} onClick={() => { this.setState({ resMessage: '', resType: '' }) }}>Okay</button>
            </div>
            </div>
        </Modal>) : null;
    const loader = this.state.loader ? (
      <Modal>
        <Loader message={"Loading..."} />
      </Modal>
    ) : null;
    return (
      <React.Fragment>
        <div className="signinContainer forgetPassword">
          <div className="innerContainer">
            <div className="signinBox">
              <a href={`/home`} className="signupLogo">
                <img src={axllearnlogo} alt="///"></img>
              </a>
              <div className="signinForm">
                <h2>Forgot Password</h2>
                <p>Enter your email and click on Reset Password button, to get your update password link by email.</p>
                <div className="fieldContainer position-relative">
                  {this.state.resMessageAuth !== "" && (
                    <div
                      className={`alert alert-${this.state.resTypeAuth} p-0 text-center`}
                    >
                      <small>{this.state.resMessageAuth}</small>
                      <i
                        className="bi bi-x-circle float-end me-2 btn p-0 text-danger"
                        onClick={() => {
                          this.setState({
                            resTypeAuth: "",
                            resMessageAuth: "",
                          });
                        }}
                      ></i>
                    </div>
                  )}

                  <form
                    onSubmit={(e) => {
                      this.authenticate(e);
                    }}
                  >
                    <div className="fieldRow input-group ">
                      <span className="input-group-text">
                        <i className={`bi bi-envelope `}></i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.email}
                        name="email"
                        id="emailFor"
                        placeholder="Enter your email"
                        onChange={this.handleFormChange}
                      />
                    </div>
                    <div className="fieldRow resetPassword">
                      <button type="submit" className="linkSignin" disabled={!validateEmail(this.state.email)}>Reset Password</button>
                      <a href={`/login`} className="returnLogin">Return to Sign in</a>
                    </div>
                    {/* <div className="fieldRow">
                      <p>
                        <a href={`/login`}>Click here to sign up</a>
                      </p>
                    </div> */}

                    {/* <div className="fieldRow">
                      <p>
                        <NavLink className="linkSignin" to="/home">Sign In</NavLink>
                        <input
                          type="submit"
                          className="linkSignin"
                          value="Submit"
                        />
                      </p>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-modal">{apiDialog}</div>
        {loader}
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
