import React from 'react';
import api from '../../../services/Api/Api';
import VimeoPlayer from '../vimeoplayer/vimeoplayer';

class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    /**
     * type
     * info
     */
    getMedia(val, stateId) {
        api.getFileFromDrive(val).then(res => {
            if (!document.getElementById(stateId).src) {
                console.log('document.getElementById(stateId)', document.getElementById(stateId).src)

                if (res.data.resCode === 200) {
                    document.getElementById(stateId).src = res.data.data.webContentLink;
                } else {
                    document.getElementById(stateId).src = ''
                    document.getElementById(stateId).style.opacity = 0;
                }
            }
        }).catch(err => {
            console.log('loader err of ' + stateId, ': ', err);

        })
    }

    render() {
        return (
            <React.Fragment>
                <div className={`card-lg course-card inprogress ${this.props.type}`}>
                    <div className="course-card-header">
                        {this.props.type === 'published' &&
                            <>
                                {this.props.info.introVideo && this.props.info.introVideo !== '' &&
                                    <VimeoPlayer
                                        id={this.props.info.introVideo}
                                        preId={'axllearn'}
                                        options={{
                                            // width: '320',
                                            byline: false, // vimeo owner's name
                                            color: 'ff0000',
                                            // height: '360',
                                            responsive: false,
                                            quality: 'auto',
                                            portrait: false,
                                            playsinline: true,
                                            transparent: false,
                                            muted: true,
                                            controls: false
                                        }}
                                        isPlayOnOver={true}
                                    />
                                }
                                {(this.props.info.introVideo === '' || !this.props.info.introVideo) &&
                                    <img id={this.props.info.poster + 'axllearn'} src={this.props.info.poster} alt="///"
                                        onError={(e) => {
                                            e.target.parentNode.className = e.target.parentNode.className + ' bg-primary text-light w-100 placeholder';
                                            e.target.parentNode.innerText = this.props.info.title;
                                            e.target.style.display = 'none';
                                        }}
                                    />
                                }

                                {/* <img id={this.props.info.id + '-poster'} src={this.props.info.poster} /> */}
                            </>
                        }
                        {this.props.type !== 'published' &&
                            <>
                                {this.props.type === 'inProgress' &&
                                    <button type="button" className="btn btn-status">In Progress</button>
                                }
                                {this.props.type === 'submitted' &&
                                    <button type="button" className="btn btn-status">Submitted</button>
                                }

                                <button type="button" className="btn btn-flat-default btn-dots" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="bi bi-three-dots-vertical"></i></button>
                                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href={`/account/${this.props.info.accountId}/editCourseInfo/${this.props.info.id}`}>Edit Course</a></li>
                                    <li><a className={`dropdown-item ${this.props.type === 'submitted' ? 'disabled' : ''}`} href="/#">Submit Course</a></li>
                                    <li><a href="/#" className="dropdown-item" onClick={() => this.props.delete()}>Delete</a></li>
                                </ul>
                            </>
                        }
                    </div>
                    {this.props.type !== 'published' &&
                        <div className="course-card-body">
                            <div className="course-img-title">
                                <div className="img-block"><i className="bi bi-brightness-high"></i></div>
                                <h4>{this.props.info.title}</h4>
                            </div>
                            <div className="progress" style={{ height: '12px', borderRadius: '20px' }}>
                                <div className="progress-bar" role="progressbar" style={{ width: '25%', fontSize: '10px' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                            </div>
                        </div>
                    }
                    {this.props.type === 'published' &&
                        <div className="course-card-body">
                            <div className="course-info">
                                <span className="catg">{this.props.info.industry}</span>
                                <span className="date">{this.props.info.publishedOn}</span>
                                <span className="price">{this.props.info.price}</span>

                            </div>
                            <h4><a href={`/courseDetails/${this.props.info.id}`}>{this.props.info.title}</a>
                                {/* <a className="btn btn-danger text-light float-end px-1 py-1 ms-1" style={{ fontSize: '10px' }}><i className="bi bi-plus" /> Add</a> */}
                                <a href={`/courseDetails/${this.props.info.id}`} className="btn btn-primary float-end px-1 py-1">View Course</a>
                            </h4>
                            {/* <div className="course-info bottom">
                                <span className="views"><i className="bi bi-eye-fill"></i> 19 Views</span>
                                <span className="comments"><i className="bi bi-chat-dots-fill"></i> 12 Comments</span>
                                <span className="sell"><i className="bi bi-tags-fill"></i> 32 Sell</span>
                            </div> */}
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }

}

export default Card;