import React, { useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from './Auth/Auth';
export const ProtectedRoute = ({ component: Component, ...rest }) => {
    let [authCheck, setAuthCheck] = useState(null);
    let [count, setCount] = useState(0);

    useEffect(() => {
        auth.isAuthenticated().then((res) => {
            setAuthCheck(res);
        });
        return ()=> setCount(1);
    })
    const returnObject = count ? <Route {...rest} render={
        (props) => {
            console.log('authCheck',authCheck);
            if (authCheck) {
                return <Component {...props} />
            } else {
                console.log('window location ',window.location)
                return <Redirect to={
                    {
                        pathname: "/login",
                        state: {
                            from: props.location
                        }
                    }
                } />
            }
        }
    } /> : null;
    return (
        <React.Fragment>
            {returnObject}
        </React.Fragment>
    )
}