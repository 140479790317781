import { render } from 'react-dom';
import React, { Component } from 'react';
import './affiliate.scss';

class Affiliate extends Component {
    render() {
        return (
            <div className="page-content">
                <div class="fluid-banner"><h1>Affiliate</h1></div>
                <div className="container inner-content">
                    <p>Coming Soon...</p>
                </div>
            </div>
        )
    }
}

export default Affiliate;