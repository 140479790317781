import React, { useState, useEffect } from 'react';
import api from '../../../services/Api/Api';

const Card = (props) => {
    const [data, setData] = useState({ info: null, courseItem: [] });
    const [status, setStatus] = useState(0);

    /**
     * type
     * info
     */
    useEffect(() => {
        renderCourse(props.id);
        if (props.status) {
            getCoursesCompletionDetails();
        }

    }, []);

    const renderCourse = (id) => {
        api.learnerCourseDetails({ courseId: id }).then(res => {
            if (res.data.resCode === 200) {
                if (props.cardPathway) {

                    if (props.cardCourse.findIndex((elem) => elem.id === props.id) === -1) {
                        props.cardCourse.push({
                            // info: {
                            poster: res.data.data.poster,
                            title: res.data.data.title,
                            industry: res.data.data.industry,
                            publishedOn: res.data.data.publishedOn,
                            price: res.data.data.price,
                            id: res.data.data._id,
                            content: res.data.data.content,
                            status: true,
                            message: ''
                            // }
                        })
                    }
                }
                let item = res.data.data;

                setData({
                    ...data,
                    info: {
                        poster: item.poster,
                        title: item.title,
                        industry: item.industry,
                        publishedOn: item.publishedOn,
                        price: item.price,
                        id: item._id,
                        content: item.content,
                        status: true,
                        message: ''
                    }
                });

            } else {
                setData({
                    ...data,
                    info: {
                        message: 'Failed to render',
                        status: false
                    }
                });
            }
        }).catch(err => {
            setData({
                ...data,
                info: {
                    message: 'Failed to render',
                    status: false
                }
            });
        })
    }

    const getCoursesCompletionDetails = () => {
        let total_duration = 0;
        let total_completed = 0;
        props.status.forEach(course => {
            if (course.progress.length > 0) {
                total_duration = total_duration + Number(Math.floor(course.progress[0].duration));
                total_completed = total_completed + course.progress[0].completed;
            }

        });
        setStatus(Math.floor((total_completed / total_duration) * 100));
    }
    return (
        <React.Fragment >

            {
                data.info !== null &&
                <div className={`card-lg course-card inprogress ${props.type}`}>
                    <div className="course-card-header">
                        <img src={data.info.poster}
                            alt="///"
                            onError={(e) => {
                                e.target.parentNode.className = e.target.parentNode.className + ' placeholder default-poster';
                                // e.target.parentNode.innerText = data.info.title;
                                e.target.style.display = 'none';
                            }}
                        />
                    </div>

                    <div className="course-info">
                        <span className="catg">{data.info.industry}</span>
                        <span className="date">{data.info.publishedOn}</span>
                        <span className="price">{data.info.price}</span>

                    </div>
                    <div className="learner-course-title">
                        <h4><a href={`/courseDetails/${data.info.id}`}>{data.info.title}</a>
                            {/* <a className="btn btn-danger text-light float-end px-1 py-1 ms-1" style={{ fontSize: '10px' }}><i className="bi bi-plus" /> Add</a> */}
                            <a href={`/courseDetails/${data.info.id}`} className="btn btn-primary float-end px-1 py-1" style={{ fontSize: '10px' }}>View Course</a>
                        </h4>
                        {/* <div className="course-info bottom">
                            <span className="views"><i className="bi bi-eye-fill"></i> 19 Views</span>
                            <span className="comments"><i className="bi bi-chat-dots-fill"></i> 12 Comments</span>
                            <span className="sell"><i className="bi bi-tags-fill"></i> 32 Sell</span>
                        </div> */}
                    </div>
                    {props.status && <div className='completed-progress-bar'><span className="btn btn-success" title='Course Completed'>{status > 100 ? 100 : status}%</span></div>}
                </div>
            }
        </React.Fragment >
    )
}

export default React.memo(Card);



