import emotion1 from '../assets/images/very-sad.png';
import emotion2 from '../assets/images/sad.png';
import emotion3 from '../assets/images/normal.png';
import emotion4 from '../assets/images/happy.png';
import emotion5 from '../assets/images/very-happy.png';

export const validateEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.toLowerCase()
  );
};

export const validTextField = (text) => {
  return /^(?!\s*$).+/.test(text);
};

export const isValidPassword = (text) => {
  return /(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/.test(
    text
  );
};

export const currentBackendBaseUrl = 'http://localhost:8080';

export const courseCompletionPecentage = (usersCourses, course_id) => {
  let total_duration = 0;
  let total_completed = 0;
  let currentCourse = usersCourses.find((element) => element.id === course_id);

  currentCourse.status.forEach((course_item) => {
    total_duration =
      total_duration + Number(Math.floor(course_item.progress[0].duration));
    total_completed =
      total_completed + Math.floor(course_item.progress[0].completed);
  });

  return Math.floor((total_completed / total_duration) * 100);
};

export const courseDuration = (usersCourses, course_id) => {
  let total_duration = 0;
  let currentCourse = usersCourses.find((element) => element.id === course_id);

  const courseName = currentCourse.courseName;

  currentCourse.status.forEach((course_item) => {
    total_duration =
      total_duration + Number(Math.floor(course_item.progress[0].duration));
  });

  return { total_duration, courseName };
};

export const mapEmotionToImage = (payload) => {
  switch (payload) {
    case 1:
      return emotion1;
    case 2:
      return emotion2;
    case 3:
      return emotion3;
    case 4:
      return emotion4;
    case 5:
      return emotion5;
    default:
      break;
  }
};

export const courseReviewQuestions = [
  'How was the overall course experience?',
  'How was the User Interface / User Experience on the learning platform?',
  'How easy was it to navigate the course on the platform?'
]

export const courseRatings = [0, 0, 0]