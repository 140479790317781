import React from 'react';
import BlogWriter from 'react-blog-writer';

class BlogWriterDemo extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    setNodes(nodes) {
        console.log('nodes ' , nodes);
    }
    render(){
        return<React.Fragment>
            <BlogWriter finalNodes={(nodes) => this.setNodes(nodes)} placeHolder={'Type S'} />
        </React.Fragment>
    }
}

export default BlogWriterDemo;