import React from 'react';
import './learnerLibrary.scss';
import { Modal, ApiModal } from '../../../shared/modal/modal';
import Loader from '../../../shared/loader/loader';

import api from '../../../../services/Api/Api';
import { v4 as uuidv4 } from 'uuid';
import Card from '../../../shared/Card/CardApi';
import VimeoPlayer from '../../../shared/vimeoplayer/vimeoplayer';

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}
const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
class LearnersLibrary extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            resMessage: '',
            resType: '',

            courseId: '',
            createNew: false,
            newTitle: "",
            myCourses: [],
            userType: '',
            total: 0,

            formdata: [],
            title: '',
            content: {},
            poster: '',
            price: 0,
            isContent: true,
            objectiveCurr: '',
            objectivArr: [],

            libraryTitle: '',
            mediaLibrary: null,
            error: false,
            errorData: {},

            pathway: {},
            selectedCourses: {},
            selectedUsers: {},
            pathways: [],
            selectedPathway: null,
            allCourseProgress: [],
            cardCourse: [],
            propsCourse: null,
            cardPathway: true,

            videoComletion: null,

        }
    }

    componentDidMount() {
        this.getCourses();
        this.getPathways();

    }

    addCoursesFromNewPathway() {

    }
    getNewPathway() {
        let root = this;
        let pathId = this.parseQueryString().npw;
        this.setState({
            loader: true,
            resMessage: '',
            resType: '',
            resTitle: ''
        }, () => {
            api.getPathwayById({ id: pathId }).then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        pathway: res.data.data,
                        selectedCourses: res.data.data.courses,
                        selectedUsers: res.data.data.selectedUsers,
                        currentUser: res.data.user
                    }, () => {
                        console.log("this.state.myCourses",this.state.selectedUsers);
                        let events = [];
                        let newCourses = [...this.state.myCourses]
                        console.log("this.state.pathway.startDate",this.state.pathway.startDate)
                        this.state.pathway.courses.map(crs => {

                            let d = new Date(this.state.pathway.startDate);
                            let endd = d.addDays(crs.duration);
                            let event = {
                                productId: crs.id,
                                uid: uuidv4(),
                                startOutputType: "local",
                                start: [endd.getFullYear(), endd.getMonth() + 1, endd.getDate(), 9, 0],
                                end: [endd.getFullYear(), endd.getMonth() + 1, endd.getDate(), 21, 0],
                                title: crs.title
                            }
                            events.push(event);
                            // if (this.state.myCourses.map(e => { return e.id }).indexOf(crs.id) === -1) {
                            let courseIndex = this.state.myCourses.findIndex((e) => e.id === crs.courseId);    
                            if (courseIndex === -1) {
                                newCourses.push(crs);
                            }

                            if (
                                courseIndex > -1 &&
                                this.state.myCourses[courseIndex].startDate !== this.state.pathway.startDate
                            ) {
                                newCourses[courseIndex].startDate = this.state.pathway.startDate
                            }


                        });
                        let startDate = this.state.selectedUsers[this.state.selectedUsers.findIndex(elem => elem.id === this.state.currentUser)].startDate
                        // return;
                        console.log("newCourses",newCourses);
                        api.addMultiCourses({ courses: newCourses, startDate: startDate }).then(resp => {
                            // console.log('res courses', resp);
                            if (resp.data.resCode === 200) {
                                root.setState(prev => ({
                                    myCourses: [...prev.myCourses, ...resp.data.resData],
                                    loader: false
                                }), () => {
                                    api.createIcs({ event: events, uid: root.state.pathway.uid }).then(res => {
                                        if (res.data.resCode === 200) {
                                            window.open(window.location.origin + '/' + res.data.url, "_blank")
                                        }
                                    }).catch(err => {
                                        console.log('err', err);
                                    })
                                })
                            }
                            if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                                root.setState({
                                    loader: false,
                                    resMessage: res.data.resMessage,
                                    resType: res.data.resType,
                                    resTitle: res.data.resTitle
                                })
                            }
                            if (res.data.resCode === 400) {
                                api.logout().then(res => {
                                    window.location.replace('/login')
                                })
                            }
                        }).catch(err => {
                            root.setState({
                                loader: false,
                                resMessage: "Something went wrong! Please try again.",
                                resType: "danger",
                                resTitle: "Error"
                            });
                        })

                    });
                } if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle
                    })
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login?npw=' + pathId)
                    })
                }
            }).catch(err => {
                console.error('get pathway by id err', err);
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again.",
                    resType: "danger",
                    resTitle: "Error"
                });
            });
        })
    }

    getCourses() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.getLearnerCourseList().then(res => {
                if (res.data.resCode === 200) {
                    // console.log("my courses ==", res.data.data.courses)
                    this.setState({
                        loader: false,
                        myCourses: res.data.data.courses,
                    }, () => {
                        console.log('my courses', this.state.myCourses)
                        if (this.parseQueryString().npw) {
                            this.getNewPathway();
                        }
                        let allCourseProgress = [];
                        this.state.myCourses.map((mitem) => { //course
                            let total_course_duration = 0;
                            let complete_course_duration = 0;
                            mitem.status.map(chitem => { // chapter
                                let ch_total_duration = 0;
                                let ch_completed_duration = 0;
                                chitem.progress.map(item => { // videos
                                    ch_total_duration = ch_total_duration + item.duration;
                                    ch_completed_duration = ch_completed_duration + item.completed;
                                })
                                total_course_duration = total_course_duration + ch_total_duration;
                                complete_course_duration = complete_course_duration + ch_completed_duration;
                            });
                            allCourseProgress.push({
                                id: mitem.id,
                                duration: total_course_duration,
                                completed: complete_course_duration

                            })
                        });
                        this.setState({
                            allCourseProgress: allCourseProgress
                        }, () => {
                            // console.log('allCourseProgress', this.state.allCourseProgress);
                        })
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    }, () => {
                        if (this.parseQueryString().npw) {
                            this.getNewPathway();
                        }
                    });
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                console.log('get my courses error', err);
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            })
        })
    }
    getPathways() {
        this.setState({
            loader: true,
            resMessage: '',
            resType: ''
        }, () => {
            api.getLearnerPathwayList().then(res => {
                // console.log("pathways === ", res.data.data.pathways)
                if (res.data.resCode === 200) {
                    this.setState({
                        loader: false,
                        pathways: res.data.data.pathways
                    })
                }
                if (res.data.resCode !== 200 && res.data.resCode !== 400) {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    });
                }
                if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                }
            }).catch(err => {
                console.log('get my courses error', err);
                this.setState({
                    loader: false,
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            })
        })
    }

    parseQueryString() {
        let str = window.location.search;
        let objURL = {};
        str.replace(
            new RegExp("([^?=&]+)(=([^&]*))?", "g"),
            function ($0, $1, $2, $3) {
                objURL[$1] = $3;
            }
        );
        return objURL;
    };

    getAllCoursesDetails() {
        // console.log("card courses = ", this.state.cardCourse)
        let total_duration = 0;
        let total_completed = 0;
        this.state.selectedPathway.courses.forEach(course_element => {
            this.state.myCourses.forEach((all_course_element) => {
                if (all_course_element.id === course_element.courseId) {
                    console.log('all_course_element', all_course_element);
                    all_course_element.status.forEach(video_status => {
                        total_duration = total_duration + Number(Math.floor(video_status.progress[0].duration));
                        total_completed = total_completed + video_status.progress[0].completed;
                    })

                }
            })
        });


        this.setState({
            videoComletion: Math.floor((total_completed / total_duration) * 100),
        })
    }

    checkStatus(id) {
        let course_index = this.state.allCourseProgress.map(e => { return e.id }).indexOf(id);
        if (course_index !== -1) {
            return this.state.allCourseProgress[course_index].completed;
        } else {
            return 0;
        }

    }

    courseHandler() {
        this.setState({ selectedPathway: null })
    }
    // pathwayHandler(){
    //     this.state.pathways.map((pitem) => {
    //         return  this.setState({ selectedPathway: pitem }, () => { this.getAllCoursesDetails() }) 
    //     })

    // }
    // getMedia(val, stateId) {
    //     api.getFileFromDrive(val).then(res => {
    //         // if (!document.getElementById(stateId).src) {
    //         if (res.data.resCode === 200) {
    //             document.getElementById(stateId).src = res.data.data.webContentLink;
    //         } else {
    //             document.getElementById(stateId).src = ''
    //             document.getElementById(stateId).style.opacity = 0;
    //         }
    //         // }
    //     }).catch(err => {
    //         console.log('loader err of ' + stateId, ': ', err)
    //     })
    // }
    card_fn() {
        console.log("card fn = = ")
    }

    // getCoursesCompletionDetails() {
    //     let total_duration = 0;
    //     let total_completed = 0;
    //     let currentCourse = this.state.usersCourses.find(element => element.id == this.state.course._id);

    //     currentCourse.status.forEach(course_item => {
    //         total_duration = total_duration + Number(Math.floor(course_item.progress[0].duration));
    //         total_completed = total_completed + course_item.progress[0].completed;
    //     })

    //     this.setState({
    //         courseCompletion: Math.floor((total_completed / total_duration) * 100),
    //     }, () => {
    //         // console.log('course completion: ', this.state.courseCompletion);
    //     })
    // }

    render() {

        const loader = this.state.loader ? <Loader message={"Loading..."} /> : null;

        const apiDialog = this.state.resMessage !== '' ?
            <div className="row">
                <div className="col-md-3"></div>
                <div className="col-md-6">
                    <div className={`alert alert-${this.state.resType}`}>
                        <p>{this.state.resMessage}</p>
                        <i className="bi bi-x-square-fill position-absolute top-0 end-0" onClick={() => this.setState({ resMessage: '', resType: '' })}></i>
                    </div>
                </div>
            </div>
            : null;



        const apiModal = this.state.error ? <Modal>
            <ApiModal
                dismiss={(e) => this.setState({ error: false, errorData: {} })}
                modalData={this.state.errorData}

            /> </Modal> : null;
        return (
            <React.Fragment>
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-title">
                                <h2>My Courses</h2>
                                {/* <h6>Add your new course or edit your existing course.</h6> */}
                            </div>
                        </div>

                    </div>

                </div>

                <div className="tabs-pathways">
                    <ul>
                        <li onClick={() => { this.setState({ selectedPathway: null }) }} className={`${this.state.selectedPathway === null ? 'active' : ''}`}>My Courses</li>
                        {this.state.pathways.map((pitem) => {
                            return <li key={uuidv4()} className={`${this.state.selectedPathway !== null && this.state.selectedPathway._id === pitem._id ? 'active' : ''}`}
                                onClick={() => {

                                    let courseList = JSON.parse(JSON.stringify(pitem));

                                    courseList.courses.forEach(course_element => {
                                        this.state.myCourses.forEach((all_course_element) => {
                                            if (all_course_element.id === course_element.courseId) {
                                                console.log('al', all_course_element);
                                                console.log('ce', course_element);

                                                let start_date = new Date(all_course_element.startDate);

                                                course_element.startDate = all_course_element.startDate;

                                                let endd = start_date.addDays(course_element.duration);
                                                course_element.endDate = month[endd.getMonth()] + ' ' + endd.getDate() + ', ' + endd.getFullYear();

                                                console.log(course_element.endDate);


                                                let total_video_duration = 0;
                                                let total_video_completed = 0;
                                                all_course_element.status.forEach(video_status => {
                                                    total_video_duration = total_video_duration + Number(Math.floor(video_status.progress[0].duration));
                                                    total_video_completed = total_video_completed + video_status.progress[0].completed;
                                                })
                                                if (total_video_duration === total_video_completed) {
                                                    course_element.video_complete_status = true;
                                                } else {
                                                    course_element.video_complete_status = false;
                                                }
                                            }
                                        })
                                    });
                                    console.log('courseList', courseList)
                                    this.setState({ selectedPathway: courseList, cardPathway: true }, () => { this.getAllCoursesDetails() })
                                }}>{pitem.pathId}</li>
                        })}

                    </ul>

                    {this.state.cardPathway &&
                        <>
                            {this.state.selectedPathway !== null &&
                                <div className="pathway-container">
                                    <div className="pathway-row" data-complete={this.state.videoComletion}>
                                        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: this.state.videoComletion + '%' }} className=""></div>
                                    </div>
                                    <h5>{this.state.selectedPathway.pathname}</h5>
                                    {/* <h3>video completion :- {this.state.videoComletion}% </h3> */}
                                    <div className="pathway-block">
                                        <ul className="pathway-view liststyle">
                                            {this.state.selectedPathway.courses.map((pcitem, pcindex) => {
                                                console.log('item', pcitem);
                                                return <li key={uuidv4()} className={`pathway-card ${this.checkStatus(pcitem.courseId) === 100 ? 'completed' : ''}`}>
                                                    <div className="pno" style={{ backgroundColor: pcitem.video_complete_status ? '#2abb49' : "5D8BF4" }}>{pcindex + 1}</div>
                                                    {/* <p>{pcitem.title}</p> */}
                                                    <Card id={pcitem.courseId} />
                                                    <span className="text-secondary">Due by <strong>{pcitem.endDate}</strong></span>
                                                </li>
                                            })}

                                            {/* passed-2 */}
                                        </ul>
                                    </div>                                    
                                </div>
                            }
                            {this.state.selectedPathway === null &&
                                <div className="page-body learners-courses">
                                    <div className="row">
                                        {this.state.myCourses.map(item => {

                                            return <div className="col-sm-3" key={uuidv4()}>
                                                <Card id={item.id} cardCourse={this.state.cardCourse} cardPathway={this.state.cardPathway} status={item.status} />
                                            </div>
                                        })}
                                        {/* <div className="col-sm-3" key={uuidv4()}><Card myCourses={this.state.myCourses} /></div> */}
                                    </div>
                                </div>
                            }
                        </>
                    }
                    {!this.state.cardPathway &&
                        this.state.cardCourse.map((card_elem) => {
                            return (
                                <div className={`card-lg course-card inprogress ${this.props.type}`}>
                                    <div className="course-card-header">

                                        <div className="product-img">
                                            {card_elem.introVideo && card_elem.introVideo !== '' &&
                                                <VimeoPlayer
                                                    id={card_elem.introVideo}
                                                    preId={'axllearn'}
                                                    options={{
                                                        // width: '320',
                                                        byline: false, // vimeo owner's name
                                                        color: 'ff0000',
                                                        // height: '360',
                                                        responsive: false,
                                                        quality: 'auto',
                                                        portrait: false,
                                                        playsinline: true,
                                                        transparent: false,
                                                        muted: true,
                                                        controls: false
                                                    }}
                                                    isPlayOnOver={true}
                                                />
                                            }
                                            {(card_elem.introVideo === '' || !card_elem.introVideo) &&
                                                <img alt="///" src={card_elem.poster}
                                                    onError={(e) => {
                                                        e.target.parentNode.className = e.target.parentNode.className + ' bg-primary text-light w-100 placeholder';
                                                        e.target.parentNode.innerText = card_elem.title;
                                                        e.target.style.display = 'none';
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>

                                    <div className="course-info">
                                        <span className="catg">{card_elem.industry}</span>
                                        <span className="date">{card_elem.publishedOn}</span>
                                        <span className="price">{card_elem.price}</span>

                                    </div>
                                    <div className="learner-course-title">
                                        <h4><a href={`/courseDetails/${card_elem.id}`}>{card_elem.title}</a>
                                            {/* <a className="btn btn-danger text-light float-end px-1 py-1 ms-1" style={{ fontSize: '10px' }}><i className="bi bi-plus" /> Add</a> */}
                                            <a href={`/courseDetails/${card_elem.id}`} className="btn btn-primary float-end px-1 py-1" style={{ fontSize: '10px' }}><i className="bi bi-binoculars" /> view</a>
                                        </h4>
                                        {/* <div className="course-info bottom">
                    <span className="views"><i className="bi bi-eye-fill"></i> 19 Views</span>
                    <span className="comments"><i className="bi bi-chat-dots-fill"></i> 12 Comments</span>
                    <span className="sell"><i className="bi bi-tags-fill"></i> 32 Sell</span>
                </div> */}
                                    </div>
                                </div>)
                        })
                    }



                </div>
                {loader}
                {apiDialog}
                {apiModal}
            </React.Fragment>
        )
    }
}

export default LearnersLibrary;