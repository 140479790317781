import React, { Component } from 'react';
import './aboutus.scss';

class About extends Component {
    render() {
        return (
            <div className="page-content">
                <div class="fluid-banner"><h1>About Us </h1></div>
                <div className="container inner-content">
                    <p>Axl Learn is an EdTech company that has been developing and delivering different types of learning courses for corporates around the world, since 2013.</p>
                    <p>Alumnus from IIT Kharagpur, XLRI Jamshedpur, and University of Massachusetts, our founders have invested over 30+ years in training and technology, and have built this unique learning platform with the aim to make learning visual, engaging, and localized, to ensure higher retention of learning.</p>
                    <p>Our endeavor began in 2013 with learning technologies that used algorithm based digital learning simulators to deliver techno-leadership courses with the aim of delivering learning through gaming. We transformed the way classroom training was delivered to the corporates. We converted learning programs from "chalk-and-talk" training experience to an immersive learning experience through "learning-by-doing".</p>
                    <p>In 2016 and 2017, our product line SimWorks (www.simworks.online) won multiple global awards in learning for contributing to client's revenue growth through highly engaging content, delivery and measurement methods.</p>
                </div>
            </div>
        )
    }
}

export default About;