import React, { Component } from 'react';
import './home.scss';
import Banner1 from '../../../assets/images/Banner-1.png';
import Banner2 from '../../../assets/images/Banner-2.png';
import Banner3 from '../../../assets/images/Banner-3.png';
import Banner4 from '../../../assets/images/Banner-4.png';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';



import api from '../../../services/Api/Api';

import ListingModule from './modules/ListingModule';


const bannerResponsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1 // optional, default to 1.
    }
};

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            courses: []
        }
    }

    componentDidMount() {
        this.getCourses({
            query: { status: 'published' },
            limit: 10,
            skip: 0
        })
    }

    getCourses(query) {
        api.courseSearch(query).then(res => {
            if (res.data.resCode === 200) {
                this.setState({
                    courses: res.data.data.courses
                })
            }
        }).catch((err) => {

        })
    }

    getMedia(val, stateId) {
        api.getFileFromDrive(val).then(res => {
            if (!document.getElementById(stateId).src) {
                if (res.data.resCode === 200) {
                    document.getElementById(stateId).src = res.data.data.webContentLink;
                } else {
                    document.getElementById(stateId).src = ''
                    document.getElementById(stateId).style.opacity = 0;
                }
            }
        }).catch(err => {
            console.log('loader err of ' + stateId, ': ', err)
        })
    }

    render() {
        return (
            <div className="page-content">
                <div className="main-banner">
                    <Carousel swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={bannerResponsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5s"
                        transitionDuration={3000}
                        containerclassName="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        deviceType={this.props.deviceType}
                        dotListclassName="custom-dot-list-style"
                        itemclassName="carousel-item-padding-40-px">
                        <div>
                            <a href="#contentarea"><img src={Banner1} alt="banner one" /></a>
                        </div>
                        <div>
                            <a href="#contentarea"><img src={Banner2} alt="banner one" /></a>
                        </div>
                        <div>
                            <a href="#contentarea"><img src={Banner3} alt="banner one" /></a>
                        </div>
                        <div>
                            <a href="#contentarea"><img src={Banner4} alt="banner one" /></a>
                        </div>
                    </Carousel>
                </div>


                <div className='home-main-container' id="contentarea">
                    <ListingModule category={"Business"} heading={"Latest Course In"} />
                    {/* <ListingModule category={"IT&Software"} heading={"Latest Course In"} /> */}
                    <ListingModule category={"Project Management"} heading={"Popular Course In"} />
                </div>
                <div className="view-all-courses">
                    <a href={`/search`} className="btn btn-primary">View All Courses <i className="bi bi-arrow-right-short"></i></a>
                </div>

            </div>
        )
    }
}

export default Home;