import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import './allcourses.scss';
import { v4 as uuidv4 } from 'uuid';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';

import Rating from '@material-ui/lab/Rating';

import api from '../../../services/Api/Api';


class AllCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courses: []
        }
    }

    componentDidMount() {
        this.getCourses({ status: 'published' })
    }

    getCourses(query) {
        api.courseSearch(query).then(res => {
            console.log('res', res);
            if (res.data.resCode === 200) {
                this.setState({
                    courses: res.data.data
                })
            }
        }).catch((err) => {

        })
    }

    getMedia(val, stateId) {
        api.getFileFromDrive(val).then(res => {
            if (!document.getElementById(stateId).src) {
                if (res.data.resCode === 200) {
                    document.getElementById(stateId).src = res.data.data.webContentLink;
                } else {
                    document.getElementById(stateId).src = ''
                    document.getElementById(stateId).style.opacity = 0;
                }
            }
        }).catch(err => {
            console.log('loader err of ' + stateId, ': ', err)
        })
    }

    render() {
        return (
            <div className="page-content">
                <div class="fluid-banner all-courses-fixed"><h1>All Courses</h1></div>
                <div className="all-courses">
                    <div className="all-courses-left">
                        <ul>
                            <li><a href="/#" class="catg-link">Business Development</a>
                                <form className="category-filter">
                                    <ul className="submenu">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">BD Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">BD Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">BD Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">BD Option</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </li>
                            <li><a href="/#" class="catg-link">Leadership Skills</a>
                                <form className="category-filter">
                                    <ul className="submenu">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">LS Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">LS Option</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </li>
                            <li><a href="/#" class="catg-link">Team Management</a>
                                <form className="category-filter">
                                    <ul className="submenu">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">TM Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">TM Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">TM Option</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </li>
                            <li><a href="/#" class="catg-link">IT/Software</a>
                                <form className="category-filter">
                                    <ul className="submenu">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">IT/S Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">IT/S Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">IT/S Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">IT/S Option</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </li>
                            <li><a href="/#" class="catg-link">Marketing</a>
                                <form className="category-filter">
                                    <ul className="submenu">
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">MK Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">MK Option</label>
                                            </div>
                                        </li>
                                        <li>
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                                <label class="form-check-label" for="flexCheckDefault">MK Option</label>
                                            </div>
                                        </li>
                                    </ul>
                                </form>
                            </li>
                        </ul>
                    </div>
                    <div className="all-courses-right">
                        <div className="course-container leadership-skills">
                            <h1><span className="catg-text">Business Development</span></h1>
                            <div className="course-list">
                                <ul>
                                    {this.state.courses.length > 0 &&
                                        <React.Fragment>
                                            {this.state.courses.map((item, i) => {
                                                if (i < 4) {
                                                    return <li key={uuidv4()}>
                                                        <Card className="product-card">
                                                            <CardActionArea>
                                                                <div className="product-img">
                                                                    <img alt="///" id={item.poster + 'lc'} src={this.getMedia(item.poster, item.poster + 'lc')}
                                                                        onError={(e) => {
                                                                            e.target.parentNode.className = e.target.parentNode.className + ' bg-primary text-light w-100 placeholder';
                                                                            e.target.parentNode.innerText = item.title;
                                                                            e.target.style.display = 'none';
                                                                        }}
                                                                    />
                                                                </div>
                                                                <CardContent>
                                                                    <h4><NavLink className="product-title" to={`/courseDetails/${item._id}`}>{item.title}</NavLink></h4>
                                                                    <h6><span>Jose Portilla, California</span> <span>Views - 3,213</span></h6>
                                                                    <h6><span>4.6 <Rating name="size-small" defaultValue={4} size="small" /></span> <span className="raters">(3,734)</span></h6>
                                                                    <h5 className="course-price">₹8,640</h5>
                                                                </CardContent>
                                                            </CardActionArea>
                                                        </Card>
                                                    </li>

                                                }
                                            })}
                                        </React.Fragment>
                                    }


                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AllCourses;