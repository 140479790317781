import React, { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import './courseplayer.scss';
import Rating from '@material-ui/lab/Rating';
import api from '../../../services/Api/Api';
import { v4 as uuidv4 } from 'uuid';
import Quiz from '../videoPlayer/Quiz';

import { Modal } from '../../shared/modal/modal';
import Loader from '../../shared/loader/loader';
import Card from '../../shared/Card/CardApi';
import VimeoPlayer from '../../shared/vimeoplayer/vimeoplayer';
// const VimeoPlayer = lazy(() => import("../../shared/vimeoplayer/vimeoplayer"));
import RatingComp from '../../shared/RatingComp/RatingComp';
import Reviewer from '../../shared/Reviewer/Reviewer';
import { courseCompletionPecentage, courseDuration } from '../../../utils/constant';
import Api from '../../../services/Api/Api';

const RelatedCourse = lazy(() => import("./RelatedCourse/RelatedCourse"));


class Courseplayer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            resMessage: '',
            resType: '',
            resTitle: '',
            resCode: '',
            currentTab: "about",
            course: null,
            info: {},
            currentChapter: '',
            currentLecture: '',
            quizQuestions: [],
            finalAssesmentPage: null,
            currentQuizIndex: 0,
            score: 0,
            submitPage: null,
            userAnswer: null,
            quizPageOpen: true,
            videoPageOpen: null,
            finalQuiz: [],
            checkedAns: false,
            chapterSubmitPage: null,
            coursePlayer: [],
            store_count: 0,
            learner_courses: [],
            chapterClick: "",
            category: '',
            subcategory: '',
            video_name: null,
            curr_content: {},
            isVideo: true,
            courseCompletion: 0,
            isFinalAssessmentCompleted:false,
            actionPlanFlag: false,
            displayRatingSection: false,
            displayCourseCompletionModal:true
        }
        this.goToActionPlanPage = this.goToActionPlanPage.bind(this)
        this.goToCertificatePage = this.goToCertificatePage.bind(this)
        this.handleCloseReviewModal = this.handleCloseReviewModal.bind(this)
        this.updateRating = this.updateRating.bind(this)
    }
    componentWillUnmount() {
        this.setState({
            store_count: 0
        })
    }

    componentDidMount() {
        this.getLearnerCourseList();
        this.getCourse();

    }

    getCourse() {

        this.setState({
            loader: true,
            resMessage: '',
            resType: '',
            resTitle: '',
            resCode: ''
        }, () => {
            api.courseDetails({ courseId: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] }).then(res => {
                if (res.data.resCode === 200) {
                    const courseID = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
                    let actionPlanFlag = true;
                    let isFinalAssessmentCompleted=false;
                    for (let i = 0; i < res.data.usersCourses.length; i++) {
                        if (res.data.usersCourses[i].id === courseID) {
                            actionPlanFlag = res.data.usersCourses[i]?.actionPlanFlag;
                            isFinalAssessmentCompleted = res.data.usersCourses[i]?.isFinalAssessmentCompleted;
                            break;
                        }
                    }
                    let total_chapter = res.data.data.content.chapters;
                    let topic_wise = [];

                    for (let i = 0; i < total_chapter.length; i++) {
                        let current_topic = { ...total_chapter[i] };
                        let new_topic = {
                            title: current_topic.title,
                            id: current_topic.id,
                            contents: (() => {
                                let local_arr = [...current_topic.videos].sort((a, b) => a.position - b.position);
                                local_arr.forEach(vi => {
                                    vi.type = "video";
                                    // vi.completion = root.completionStatus(vi.name, current_topic.id)
                                })
                                if (current_topic.quizzes && current_topic.quizzes.length > 0) {
                                    // if (current_topic.quiz[0].pos) {  // current_topic.quiz[0].pos <= this statement is temporary
                                    current_topic.quizzes.forEach(qitem => {
                                        local_arr.splice(qitem.position, 0, qitem)
                                    })
                                    // } 

                                }
                                if (current_topic.quiz) {

                                    local_arr.push({
                                        type: "quiz",
                                        title: "Quiz: " + current_topic.title,
                                        values: current_topic.quiz,
                                        name: current_topic.id
                                    })

                                }
                                return local_arr;
                            })()
                        }
                        topic_wise.push(new_topic);


                    }

                    this.setState({
                        course: res.data.data,
                        resCode: res.data.resCode,
                        quizQuestions: res.data.data.content.chapters[0].quiz,
                        finalQuiz: res.data.data.content.finalAssesment.values,
                        coursePlayer: topic_wise,
                        category: res.data.data.category,
                        subcategory: res.data.data.subcategory,

                        curr_content: {
                            name: topic_wise[0].contents[0].name,
                            type: topic_wise[0].contents[0].type,
                            value: topic_wise[0].contents[0].type === "quiz" ? topic_wise[0].contents[0].values : null,
                            topicId: topic_wise[0].id,

                        },
                        isVideo: topic_wise[0].contents[0].type === 'video' ? true : false,
                        userId: res.data.userId,
                        usersCourses: res.data.usersCourses,
                        actionPlanFlag: actionPlanFlag,
                        isFinalAssessmentCompleted:isFinalAssessmentCompleted,
                        userType: res.data.userType
                    }, () => {
                        this.getCoursesCompletionDetails();
                        this.setState({
                            loader: false,
                            info: {
                                tl: (() => {
                                    let totalLength = 0;
                                    res.data.data.content.chapters.forEach((ch) => {
                                        let tl = 0;
                                        ch.videos ? ch.videos.forEach(cv => {
                                            tl = tl + Number((cv.duration.toFixed(2)))
                                        }) : tl = 0;
                                        totalLength = totalLength + tl;
                                    })
                                    return totalLength;
                                })()
                            },
                            ratingObject: this.checkRatingAuth()
                        }, () => {

                            let reqQuery = {
                                query: { status: 'published', author: this.state.course.author._id },
                                limit: 10,
                                skip: 0
                            }
                            api.courseSearch(reqQuery).then(res => {
                                if (res.data.resCode === 200) {
                                    this.setState({
                                        authoursTotalCourses: res.data.data.total
                                    })
                                }
                            }).catch((err) => {
                                // set_resMessage("Something wrong is there, please reload after sometime");

                            })

                        })
                    })
                } else if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                } else {
                    this.setState({
                        loader: false,
                        resMessage: res.data.resMessage,
                        resType: res.data.resType,
                        resTitle: res.data.resTitle,
                        resCode: res.data.resCode
                    })
                }

            }).catch(err => {
                this.setState({
                    loader: false,
                    resMessage: 'Something went wrong. Please reload the page',
                    resType: 'danger',
                    resTitle: 'Error',
                })
            })
        })
    }

    getLearnerCourseList() {
        this.setState({
            resMessage: '',
            resType: ''
        }, () => {
            api.getLearnerCourseList().then(res => {
                if (res.data.resCode === 200) {
                    this.setState({
                        learner_courses: res.data.data.courses
                    }, () => {
                        // console.log("learner_Courses", this.state.learner_courses);

                    })
                } else if (res.data.resCode === 400) {
                    api.logout().then(res => {
                        window.location.replace('/login')
                    })
                } else {
                    this.setState({
                        resMessage: res.data.resMessage,
                        resType: res.data.resType
                    });
                }

            }).catch(err => {

                this.setState({
                    resMessage: "Something went wrong! Please try again",
                    resType: "danger"
                });
            })
        })
    }

    createMarkup(content) {
        return { __html: content };
    }
/**
 * 
 * @param {*} arr 
 * @param {*} key 
 * @returns formated duration
 */
    totalDuration(arr, key) {
        let filtered_videos = arr.filter(elem => elem.type === 'video');
        let video_duration = filtered_videos.reduce((a, b) => a + (b[key] || 0), 0);
        return this.format(video_duration)
    }
    // Next or Forward functionality
    nextContent() {
        let current_content = this.state.curr_content.name;
        let topicId = this.state.curr_content.topicId;
        let coursePlayer = [...this.state.coursePlayer];


        let currTopicIndex = coursePlayer.findIndex(elem => elem.id === topicId);
        let currentTopic = coursePlayer[currTopicIndex];
        // let currContPos = currentTopic.contents.findIndex(elem => type === "video" ? elem.name === current_content : elem.name === topicId);
        let currContPos = currentTopic.contents.findIndex(elem => elem.name === current_content);

        // return;
        if (currContPos < currentTopic.contents.length - 1) {

            this.setState(prev => ({
                isVideo: false,
                curr_content: {
                    ...prev.curr_content,
                    name: currentTopic.contents[currContPos + 1].name,
                    type: currentTopic.contents[currContPos + 1].type,
                    value: currentTopic.contents[currContPos + 1].type === "quiz" ? currentTopic.contents[currContPos + 1].values : null,
                    completion: this.completionStatus(currentTopic.contents[currContPos + 1].name, topicId)
                }
            }), () => {

                this.setState({
                    isVideo: currentTopic.contents[currContPos + 1].type === "video" ? true : false
                })
            })
        } else if (currContPos === currentTopic.contents.length - 1 && currTopicIndex < coursePlayer.length - 1) {

            this.setState(prev => ({
                isVideo: false,
                curr_content: {
                    ...prev.curr_content,
                    name: coursePlayer[currTopicIndex + 1].contents[0].name,
                    type: coursePlayer[currTopicIndex + 1].contents[0].type,
                    value: coursePlayer[currTopicIndex + 1].contents[0].type === "quiz" ? coursePlayer[currTopicIndex + 1].contents[0].values : null,
                    topicId: coursePlayer[currTopicIndex + 1].id,
                    completion: this.completionStatus(coursePlayer[currTopicIndex + 1].contents[0].name, coursePlayer[currTopicIndex + 1].id)
                }
            }), () => {
                this.setState({
                    isVideo: coursePlayer[currTopicIndex + 1].contents[0].type === "video" ? true : false
                })
            })
        } else if (currContPos === currentTopic.contents.length - 1 && currTopicIndex === coursePlayer.length - 1) {
            this.setState({
                finalAssesmentPage: true,
                isVideo: false
            })
        }

    }
    // previos or back functionality
    prevContent() {

        let current_content = this.state.curr_content.name;
        let topicId = this.state.curr_content.topicId;
        let coursePlayer = [...this.state.coursePlayer];

        let currTopicIndex = coursePlayer.findIndex(elem => elem.id === topicId);
        let currentTopic = coursePlayer[currTopicIndex];
        // let currContPos = currentTopic.contents.findIndex(elem => type === "video" ? elem.name === current_content : elem.name === topicId);
        let currContPos = currentTopic.contents.findIndex(elem => elem.name === current_content);


        if (currContPos > 0) {
            this.setState(prev => ({
                isVideo: false,
                curr_content: {
                    ...prev.curr_content,
                    name: currentTopic.contents[currContPos - 1].name,
                    type: currentTopic.contents[currContPos - 1].type,
                    value: currentTopic.contents[currContPos - 1].type === "quiz" ? currentTopic.contents[currContPos - 1].values : null,
                    completion: this.completionStatus(currentTopic.contents[currContPos - 1].name, topicId)
                }
            }), () => {
                this.setState({
                    isVideo: currentTopic.contents[currContPos - 1].type === "video" ? true : false
                })
            })
        } else if (currContPos === 0 && currTopicIndex > 0) {
            let last_index = coursePlayer[currTopicIndex - 1].contents.length - 1;
            this.setState(prev => ({
                isVideo: false,
                curr_content: {
                    ...prev.curr_content,
                    name: coursePlayer[currTopicIndex - 1].contents[last_index].name,
                    type: coursePlayer[currTopicIndex - 1].contents[last_index].type,
                    value: coursePlayer[currTopicIndex - 1].contents[last_index].type === "quiz" ? coursePlayer[currTopicIndex - 1].contents[last_index].values : null,
                    topicId: coursePlayer[currTopicIndex - 1].id,
                    completion: this.completionStatus(coursePlayer[currTopicIndex - 1].contents[last_index].name, coursePlayer[currTopicIndex - 1].id)
                }
            }), () => {
                this.setState({
                    isVideo: coursePlayer[currTopicIndex - 1].contents[0].type === "video" ? true : false
                })
            })
        }
    }

    open_quiz_page(topicId, arr) {
        // console.log(topicId, arr)
        this.setState(prev => ({
            finalAssesmentPage: false,
            curr_content: {
                ...prev.curr_content,
                name: topicId, // temp settlement
                type: "quiz",
                value: arr
            },
            isVideo: false
        }), () => {
            console.log(this.state.curr_content);
        })
    }
    open_video_page(v_name, vi) {
        this.setState(prev => ({
            finalAssesmentPage: false,
            curr_content: {
                ...prev.curr_content,
                name: v_name,
                type: "video",
                completion: this.completionStatus(v_name, this.state.curr_content.topicId)
            },
            isVideo: false
        }), () => {
            this.setState({
                isVideo: true
            })
        })
    }

    format(seconds) {
        if (isNaN(seconds)) {
            return `00:00`;
        }
        const date = new Date(seconds * 1000);
        const hh = date.getUTCHours();
        const mm = date.getUTCMinutes();
        const ss = date.getUTCSeconds().toString().padStart(2, "0");
        if (hh) {
            return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
        }
        return `${mm}:${ss}`;
    };
    playerEvent(data) {
        /**
        * {
        *  type,
        *  duration,
        *  play
        * }
        */
        //    console.log("data", data);
        if (data.type === 'ended') {
            this.nextContent();
        }
        let update_learner = JSON.parse(JSON.stringify(this.state.learner_courses));
        // ------------- callback hell -------------
        update_learner.forEach((elem, ei) => {
            if (elem.id === this.state?.course?._id) {
                elem.status.forEach((s_elem, si) => {
                    if (s_elem.chapterId === this.state.curr_content.topicId) {
                        s_elem.progress.forEach((pr_elem, pi) => {
                            if (pr_elem.name === this.state.curr_content.name) {
                                if (pr_elem.completed < data.seconds) {
                                    pr_elem.completed = data.seconds
                                };
                            };
                        });
                    };
                });
            };
        });
        //  ---------- getting running status ----------------
        let the_course = update_learner.find(e => e.id === this.state.course._id);

        /* 
            statusItem = {
                chapterId:'', 
                progress:[ {name:'string/vimeo-id', duration:<float/>, completed:<float/>} ]
            } 
        */
        let statusItem = the_course.status.find(elem => elem.chapterId === this.state.curr_content.topicId);
        let progressItem = statusItem.progress.find(pr => pr.name === this.state.curr_content.name); // returns{name:'string/vimeo-id', duration:<float/>, completed:<float/>}
        let completion = Math.round((progressItem.completed / progressItem.duration) * 100);
        this.setState(prev => ({
            curr_content: {
                ...prev.curr_content,
                completion: completion//this.completionStatus(this.state.curr_content.name, this.state.curr_content.topicId)
            }
        }))
        //  ---------- getting running status ----------------

        api.updateCourses({ courses: update_learner }).then((response) => {
            if (response.data.resCode === 200) this.getCoursesCompletionDetails();
        }).catch((error) => {
            console.log("course status update error: ", error);
        });

    }

    getCoursesCompletionDetails() {
        let total_duration = 0;
        let total_completed = 0;
        let currentCourse = this.state.usersCourses.find(element => element.id === this.state.course._id);

        currentCourse.status.forEach(course_item => {
            total_duration = total_duration + Number(Math.floor(course_item.progress[0].duration));
            total_completed = total_completed + course_item.progress[0].completed;
        })

        this.setState({
            courseCompletion: Math.floor((total_completed / total_duration) * 100),
        }, () => {
            // console.log('course completion: ', this.state.courseCompletion);
        })
    }


    checkRatingAuth() {
        let ratingObject = {
            value: 0,
            review: '',
            status: false
        }
        let userID = this.state.userId;
        let userIdRateIndex = this.state.course.ratings.map(item => { return item.userId }).indexOf(userID);
        if (userIdRateIndex > -1) {
            ratingObject.value = this.state.course.ratings[userIdRateIndex].value;
            ratingObject.review = this.state.course.ratings[userIdRateIndex].review;
            ratingObject.status = true;
        }
        return ratingObject;
    }
    goToActionPlanPage() {

        const { total_duration, courseName } = courseDuration(this.state.usersCourses, this.state.course._id)
        const userName = this.props.profile?.firstName + " " + this.props.profile?.lastName;

        Api.actionPlanFlagChange(this.state.course._id).then(() => {
            this.setState({ loader: true })

            this.props.history.push({
                pathname: `/actionplan/${this.state.course._id}`,
                state: {
                    courseName: courseName,
                    userName: userName,
                    totalDuration: total_duration,
                    courseId: this.state.course._id
                },
            });
        }).catch(error => {
            this.setState({ loader: false })
            this.setState({ resMessage: "Something went wrong! Please try again", resType: 'danger' })
        })


    }
    goToCertificatePage() {
        const { total_duration, courseName } = courseDuration(this.state.usersCourses, this.state.course._id)
        const userName = this.props.profile.firstName + " " + this.props.profile.lastName;
        Api.actionPlanFlagChange(this.state.course._id).then(() => {
            this.setState({ loader: true })
            this.props.history.push({
                pathname: `/certificate/${userName}-${this.state.course._id}`,
                state: {
                    courseName: courseName,
                    userName: userName,
                    totalDuration: total_duration
                },
            })
        }).catch(error => {
            this.setState({ loader: false })
            this.setState({ resMessage: "Something went wrong! Please try again", resType: 'danger' })
        })


    }
    /**
     * 
     * @param {*} video_name 
     * @param {*} topic_id 
     * @returns completed percentage
     */

     completionStatus(video_name, topic_id) {
        const { userId, course, userType } = this.state;
        if (userId === course.author._id || userType === 'admin' || !video_name.includes('videos')) {
            return 0;
        }


        let update_learner = JSON.parse(JSON.stringify(this.state.learner_courses));
        let the_course = update_learner.find(e => e.id === this.state.course._id);
        /* 
            statusItem = {
                chapterId:'', 
                progress:[ {name:'string/vimeo-id', duration:<float/>, completed:<float/>} ]
            } 
        */
        let statusItem =  the_course.status.find(elem => elem.chapterId === topic_id);

        let progressItem =  statusItem.progress.find(pr => {
            console.log('pr', pr,' --- ', video_name)
            if(pr.name === video_name){
                console.log('selected PR', pr);
                return pr;
            }
            
        }); // returns{name:'string/vimeo-id', duration:<float/>, completed:<float/>}
        let completion =  Math.round((progressItem.completed / progressItem.duration) * 100);

        return completion;
    }

    handleCloseReviewModal() {
        this.setState({ displayRatingSection: !this.state.displayRatingSection })
    }
    updateRating() {
        this.handleCloseReviewModal();
        this.getCourse();
    }
    render() {
        const loader = this.state.loader ? <Modal><Loader /></Modal> : null;
        let course = this.state.course;
        let info = this.state.info;

        const actionPlanDialog = (this.state.courseCompletion >= 100 && this.state.actionPlanFlag && this.state.isFinalAssessmentCompleted) ?(<div><Modal> <div className={`alertModalsm alert alert-success`} style={{  }}>
            <p>Thank you for filling up final assessment. Do you want to take action plan or navigate to certificate page?</p>
          
            <div className='modalFootersm'>
                <button type="button" onClick={this.goToActionPlanPage} className={`btn btn-primary`}>Yes</button>
                <button type="button" onClick={this.goToCertificatePage} className={`btn btn-primary`}>No</button>
            </div>

        </div>
        </Modal> </div> ):(null);

        const courseCompletionDialog=(this.state.courseCompletion >= 100 && this.state.isFinalAssessmentCompleted===false && this.state.displayCourseCompletionModal===true)? <Modal> <div className={`alertModalsm alert alert-success`} style={{  }}>
        <p>Congratulations, you have completed the course. Please take final assessment.</p>
      
        <div className='modalFootersm'>
            <button type="button" onClick={()=>this.setState({displayCourseCompletionModal:false})} style={{ marginRight: '10px' }} className={`btn btn-primary`}>ok</button>
    
        </div>

    </div>
    </Modal>:(null)
        return (
            <React.Fragment>
                <div className="page-content">
                    {!this.state.loader && course !== null &&
                        <div className="container">
                            <div className="row row-courseplayer">
                                <div className="col-sm-9 course-player-left">
                                    <div className="row row-axl ">
                                        {/* {this.state.usersCourses.find(elem => elem.id == this.state.course._id) ? */}
                                        <>
                                            <div className="col-sm-4 col-xs-axl player-left-one">
                                                <div className="player-col-left">
                                                    <a href={`/courseDetails/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className="btn-light-primary"><i className="bi bi-arrow-left"></i> Back</a>

                                                    <div className="course-playlist">
                                                        <h4>Contents <span className="total-hours">{this.format(info.tl)}</span> </h4>
                                                        <div className="playlist">
                                                            <ul>
                                                                {this.state.coursePlayer.map((topic, ti) => {
                                                                    return <React.Fragment key={`course-contents-id-n-${ti}`}>
                                                                        <li key={`course-contents-id-n-${ti}`}
                                                                            className={`btn-highlight-${this.state.curr_content.topicId === topic.id ? 'opened' : 'primary'} ch-default`} // ch-finished
                                                                            onClick={() => {
                                                                                this.setState(prev => ({
                                                                                    curr_content: {
                                                                                        ...prev.curr_content,
                                                                                        topicId: prev.curr_content.topicId === '' || prev.curr_content.topicId !== topic.id ? topic.id : ''
                                                                                    }
                                                                                }))
                                                                            }}
                                                                        >
                                                                            <span className="chapterIcon"><i className="bi bi-play-circle" /></span>
                                                                            <span className='chapterTitle'>{topic.title}</span>
                                                                            <span className="time">{topic.contents ? this.totalDuration(topic.contents, 'duration') : 0}</span>

                                                                        </li>
                                                                        
                                                                        <ul className="material-list" style={{ height: this.state.curr_content.topicId === topic.id ? 'auto' : '0px' }}>
                                                                            {topic.contents.map((cont, cont_i) => {
                                                                                return <li key={`course-contentin-id--icon-${cont_i}-${ti}`}
                                                                                    className={`maintitle btn-highlight${this.state.curr_content.name === cont.name ? '-warning' : '-stop'} ${cont.type === "quiz" ? 'quizonly' : ''} ${this.completionStatus(cont.name, topic.id) >= 90 ? 'tp-finished':''}`}
                                                                                    style={{ background: cont.type === 'video' ? `linear-gradient(90deg, #006ea1 ${cont.type === 'video' && this.state.curr_content.name !== cont.name ? this.completionStatus(cont.name, topic.id) : this.state.curr_content?.completion}%, #0088c7 0%)` : '' }}
                                                                                    onClick={() =>
                                                                                        cont.type === 'video' ? this.open_video_page(cont.name, cont_i) : this.open_quiz_page(topic.id, cont.values) // later it will be quizID
                                                                                    }>
                                                                                    {cont.type === "video" &&
                                                                                        <>
                                                                                            <span className="icon-notstarted">
                                                                                                <i className="bi bi-play-fill" />
                                                                                            </span>
                                                                                        </>
                                                                                    }
                                                                                    {cont.type === "quiz" &&
                                                                                        <>
                                                                                            <span className="icon-notstarted">
                                                                                                <i className="bi bi-lightbulb-fill" />
                                                                                            </span>
                                                                                        </>
                                                                                    }


                                                                                    <span className='chapterTitle'>{cont.title}</span>
                                                                                    {/* {cont.type === 'video' && this.state.curr_content.name !== cont.name && <>{this.completionStatus(cont.name, topic.id)}</>} */}
                                                                                    {/* not working => {cont.type === 'video' && this.state.curr_content.name !== cont.name  && <>{cont.completion}</>} */}
                                                                                    {/* {cont.type === 'video' && this.state.curr_content.name === cont.name && <>{this.state.curr_content?.completion}</>} */}
                                                                                    {cont.type === 'video' && <span className="time">{this.format(cont.duration)}</span>}
                                                                                    {/* Tooltip need to be added */}
                                                                                    {/* <span className="titleTooltip">{cont.title} | {this.completionStatus(cont.name, topic.id)}</span> */}
                                                                                    <span className="titleTooltip">Completed: {this.completionStatus(cont.name, topic.id)}%</span>
                                                                                </li>
                                                                            })}
                                                                        </ul>
                                                                    </React.Fragment>
                                                                })}
                                                                <li className={`btn-highlight-success final-assessment`}
                                                                    onClick={() => { this.setState({ finalAssesmentPage: true, curr_content: {}, currentQuizIndex: 0, video_name: '', currentChapter: '' }) }}>
                                                                    <span className="chapterIcon"><i className="bi bi-play-circle"></i></span>
                                                                    <span className="chapterTitle">Final Assessment</span>
                                                                </li>
                                                            </ul>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-8 col-xs-axl player-left-two">
                                                <div className="player-col-center">
                                                    <h5><i className="bi bi-play-circle"></i> {this.state.course.title}</h5>
                                                    {this.state.finalAssesmentPage ?
                                                        <Quiz quiz_questions={this.state.finalQuiz.map(x => ({ ...x, answers: [] }))}    
                                                        isFinalAssessment={true}
                                                        courseId={this.state.course._id}
                                                        reloadCourseDetails={() =>
                                                            this.getCourse()
                                                        } /> :
                                                        <>
                                                            {/* Else part */}
                                                            {this.state.isVideo && this.state.curr_content.type === 'video' &&
                                                                <Suspense fallback={
                                                                    <div>
                                                                        Loading...
                                                                    </div>
                                                                }>
                                                                    <VimeoPlayer
                                                                        id={this.state.curr_content.name}
                                                                        options={{
                                                                            width: '620',
                                                                            byline: false, // vimeo owner's name
                                                                            color: 'ff0000',
                                                                            height: '360',
                                                                            responsive: false,
                                                                            quality: 'auto',
                                                                            portrait: false,
                                                                            playsinline: true,
                                                                            transparent: false,
                                                                            // maxheight:'350'
                                                                        }}
                                                                        playerEvent={(data) => {
                                                                            if (this.state.userId !== this.state.course.author._id) {
                                                                                // this.getCoursesCompletionDetails()
                                                                                this.playerEvent(data);
                                                                            }
                                                                        }} />
                                                                </Suspense>
                                                            }

                                                            {!this.state.isVideo && this.state.curr_content.type === "quiz" && this.state.curr_content.value.length > 0 &&
                                                                <Quiz quiz_questions={this.state.curr_content.value.map(x => ({ ...x, answers: [] }))}   
                                                                isFinalAssessment={false}
                                                                courseId={this.state.course._id}
                                                                reloadCourseDetails={() =>
                                                                    this.getCourse()
                                                                }/>
                                                            }

                                                        </>
                                                    }
                                                </div>
                                                <div className="move-section-ctrls">
                                                    <div className="next-back">
                                                        <button type="button" onClick={() => this.prevContent()} className="btn btn-light-back"><i className="bi bi-arrow-left"></i> Back to previous section</button>
                                                        <button type="button" onClick={() => this.nextContent()} className="btn btn-light-next">Proceed to next section <i className="bi bi-arrow-right"></i> </button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row row-btns-only">
                                                <div className="col-sm-4 ">
                                                    <div className="buttons-block d-none">
                                                        {this.state.course.author.profile.social.length !== 0 &&
                                                            <button type="button" className="btn-light-primary"><i className="bi bi-linkedin"></i> Author's LinkedIn</button>
                                                        }
                                                        <button type="button" className="btn-light-primary"><i className="bi bi-share"></i> Share Course</button>
                                                        <button type="button" className="btn-light-danger"><i className="bi bi-heart"></i> {this.state.course.author.profile.firstName} {this.state.course.author.profile.lastName}</button>
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    {/* Blank for the time being */}
                                                </div>
                                            </div>
                                        </>
                                        {/* : */}
                                        {/* <>
                                                <div className="col-sm-4 col-xs-axl player-left-one">
                                                    <div className="player-col-left">
                                                        <a href={`/courseDetails/${window.location.pathname.split('/')[window.location.pathname.split('/').length - 1]}`} className="btn-light-primary"><i className="bi bi-arrow-left"></i> Back</a>
                                                    </div>
                                                </div>

                                                <div className="col-md-8 alert alert-warning">
                                                    <h4>This course is not added to your library</h4>
                                                    <p>Please click go to course details page by clicking back button and add this course to your library</p>
                                                </div>
                                            </> */}
                                        {/* } */}

                                    </div>

                                    <div className="row">
                                        {/* {this.state.usersCourses.find(elem => elem.id == this.state.course._id) && */}
                                        <div className="col-sm-12">

                                            <div className="course-details-tabs">
                                                <button type="button" className={`btn ${this.state.currentTab === 'about' ? 'active' : ''}`} onClick={() => { this.setState({ currentTab: 'about' }) }}>About Course</button>
                                                <button type="button" className={`btn ${this.state.currentTab === 'doccuments' ? 'active' : ''}`} onClick={() => { this.setState({ currentTab: 'doccuments' }) }}>Documents</button>
                                                {/* <button type="button" className={`btn ${this.state.currentTab === 'discussion' ? 'active' : ''}`} onClick={() => { this.setState({ currentTab: 'discussion' }) }}>Usefull Links</button> */}
                                                <button type="button" className={`btn ${this.state.currentTab === 'author' ? 'active' : ''}`} onClick={() => { this.setState({ currentTab: 'author' }) }}>About Author</button>
                                                <button type="button" className={`btn ${this.state.currentTab === 'review' ? 'active' : ''}`} onClick={() => { this.setState({ currentTab: 'review' }) }}>Reviews</button>

                                            </div>
                                            {this.state.currentTab === 'about' &&
                                                <div className="course-description">
                                                    <div className="dynamicContent" dangerouslySetInnerHTML={this.createMarkup(course.description)} />
                                                </div>
                                            }
                                            {this.state.currentTab === 'author' &&
                                                <div className="course-description">
                                                    <h5>{this.state.course.author.profile.firstName} {this.state.course.author.profile.lastName}</h5>
                                                    <p>{this.state.course.author.profile.about}</p>
                                                </div>
                                            }
                                            {this.state.currentTab === 'doccuments' &&
                                                <div className="course-description">

                                                    {this.state.course.content.courseDocuments && this.state.course.content.courseDocuments.length > 0 &&
                                                        <ul>
                                                            {this.state.course.content.courseDocuments.map((doc_item, doc_index) => {
                                                                return <li key={`course-doc-list-key-${doc_index}`}><a href={doc_item.link} target="_blank">{doc_item.title}</a></li>
                                                            })}
                                                        </ul>
                                                    }
                                                    {(!this.state.course.content.courseDocuments || this.state.course.content.courseDocuments.length == 0) &&
                                                        <h5>No Document or Reference Link by the Author</h5>
                                                    }
                                                </div>
                                            }
                                            {this.state.currentTab === 'review' &&
                                                <div className="course-description">
                                                    {/* <h5>Course reviews goes here</h5> */}

                                                    {console.log("hello", this.state.course.ratings)}
                                                    <div className="all-reviews">
                                                        <h2>Average Rating</h2>
                                                        <Rating name="size-large"
                                                            defaultValue={
                                                                this.state.course.ratings.reduce((previousValue, currentValue) => previousValue + currentValue.value, 0) / this.state.course.ratings.length
                                                            } size="large" readOnly />

                                                        {!this.state.ratingObject.status && (<><button type='button' className='btn btn-warning btn-add-review' onClick={() => this.setState({ displayRatingSection: !this.state.displayRatingSection })}><span><i className="bi bi-star-half"></i></span> Add your review</button>
                                                            {this.state.displayRatingSection && <><RatingComp course={this.state.course} ratingUpdate={(data) => this.updateRating(data)} closeReviewModal={()=>this.handleCloseReviewModal()} />
                                                            </>}</>)}


                                                        <p className='rfl-title'>Reviews from Learners</p>
                                                        <div className='learner-all-review'>{this.state.course.ratings.map((r_item, index) => {
                                                            return <Reviewer key={uuidv4()} reviewData={r_item} />
                                                        })}</div>
                                                    </div>
                                                </div>
                                            }
                                            {/* {this.state.currentTab === 'discussion' &&
                                                <div className="course-description">
                                                    <h5></h5>
                                                    <p>There are many variations of passages of Lorem Ipsum available, slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary.</p>
                                                    <p>Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt.</p>
                                                </div>
                                            } */}

                                        </div>
                                    </div>
                                    <div className="related-course">
                                        <h4>My Other Courses</h4>
                                        <div className="row">

                                            {this.state.learner_courses.map((item, i) => {
                                                if (this.state.course._id !== item.id) {
                                                    return <div className="col-sm-4 course-block" key={`my-other-courses-outer-div-${i}`}>
                                                        <Card id={item.id} />
                                                    </div>
                                                }

                                            })}
                                            {this.state.learner_courses.length === 1 && this.state.learner_courses[0].id === this.state.course._id &&
                                                <p>You haven't added any other courses. Please add some <a href={`/search`} className="btn btn-primary">Here</a></p>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-3 course-player-right">
                                    <div className="player-col-right">
                                        <div className="other-courses">
                                            <h4>Related Courses</h4>
                                            <div className="course-block">
                                                {!this.state.loader &&
                                                    <Suspense fallback={
                                                        <div className="text-center mb-2">
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                    }>
                                                        <RelatedCourse data={{ category: this.state.category, subcategory: this.state.subcategory, currentId: this.state.course._id }} />
                                                    </Suspense>
                                                }
                                            </div>
                                        </div>

                                        <div className="about-author">
                                            <h4 className="about-title">About Author</h4>
                                            <div className="author-intro">
                                                <div className={`img-block ${!this.state.course.author.profile.profilePic ? 'bg-secondary text-light text-center' : ''}`}>
                                                    {this.state.course.author.profile.profilePic &&
                                                        <img src={this.state.course.author.profile.profilePic} alt="///"
                                                            onError={(e) => {
                                                                e.target.parentNode.className = e.target.parentNode.className + ' bg-secondary text-light text-center';
                                                                e.target.parentNode.innerText = this.state.course.author.profile.firstName + this.state.course.author.profile.lastName;
                                                                e.target.style.display = 'none';
                                                            }} />
                                                    }
                                                    {!this.state.course.author.profile.profilePic &&
                                                        <>{this.state.course.author.profile.firstName.charAt(0) + this.state.course.author.profile.lastName.charAt(0)}</>
                                                    }

                                                </div>
                                                <div className="name-role">
                                                    <h5>{this.state.course.author.profile.firstName} {this.state.course.author.profile.lastName}</h5>
                                                    <h6>Courses : {this.state.authoursTotalCourses ? this.state.authoursTotalCourses : 0}</h6>
                                                    {/* <h6>{this.state.course.author.profile.tagline}</h6>
                                                    <p>
                                                        {this.state.course.author.profile.social.map(item => {
                                                            return <a href={`https://${item.value}`} key={uuidv4()} target="_blank"><i className={`bi bi-${item.name}`}></i></a>
                                                        })}

                                                    </p> */}
                                                    {!this.state.loader &&
                                                        <span className='author-linkedin'>
                                                            <a target="_blank" rel="noopener noreferrer" href={`https://${course.author.profile.social && course.author.profile.social.findIndex(elem => elem.name === "linkedIn") > -1 ? course.author.profile.social[course.author.profile.social.findIndex(elem => elem.name === "linkedIn")].value : '#'} `}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMinYMin meet" focusable="false" className="lazy-loaded">
                                                                    <g className="scaling-icon">
                                                                        <g className="logo-21dp">
                                                                            <g className="dpi-1">
                                                                                <g className="inbug" fill="none" fillRule="evenodd">
                                                                                    <path d="M82.479 0H64.583C63.727 0 63 .677 63 1.511v17.977c0 .835.477 1.512 1.333 1.512h17.896c.857 0 1.771-.677 1.771-1.512V1.511C84 .677 83.336 0 82.479 0" className="bug-text-color" fill="#FFF"></path>
                                                                                    <path d="M82.479 0H64.583C63.727 0 63 .677 63 1.511v17.977c0 .835.477 1.512 1.333 1.512h17.896c.857 0 1.771-.677 1.771-1.512V1.511C84 .677 83.336 0 82.479 0zM71 8h2.827v1.441h.031c.431-.777 1.704-1.566 3.278-1.566C80.157 7.875 81 9.479 81 12.45V18h-3v-5.003c0-1.33-.531-2.497-1.773-2.497-1.508 0-2.227 1.021-2.227 2.697V18h-3V8zm-5 10h3V8h-3v10zm3.375-13.5a1.874 1.874 0 11-3.749.001 1.874 1.874 0 013.749-.001z" className="background" fill="currentColor"></path>
                                                                                </g>
                                                                            </g></g></g></svg>
                                                            </a>
                                                        </span>
                                                    }

                                                </div>
                                            </div>
                                            {/* <div className="additional-info">
                                                <h6>Course : 32</h6>
                                                <h6>Total viewed : 26</h6>
                                                <h6><Rating name="size-small" defaultValue={4} size="small" /></h6>
                                            </div> */}
                                            <div className="about-description">
                                                <p>{this.state.course.author.profile.about}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {loader}
                {actionPlanDialog}
                {courseCompletionDialog}
            </React.Fragment>
        )
    }
}
function mapStateToProps(state) {
    return {
        profile: state.user.profile
    }
}

export default connect(mapStateToProps)(withRouter(Courseplayer));