import React from "react";
import { Link } from "react-router-dom";
import './footer.scss';
import api from '../../../services/Api/Api';

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        this.getFooter()
    }

    getFooter() {
        api.getFooter().then(res => {
            this.setState({
                text: res.data.data.text,
                logo: res.data.data.logo,
                copyright: res.data.data.copyright
            })
        }).catch(err => {
            console.log(err);
        })
    }

    getMedia(val, stateId) {
        api.getFileFromDrive(val).then(res => {
            // if (!document.getElementById(stateId).src) {
            if (res.data.resCode === 200) {
                document.getElementById(stateId).src = res.data.data.webContentLink;
            } else {
                document.getElementById(stateId).src = ''
                // document.getElementById(stateId).style.opacity = 0;
            }
            // }
        }).catch(err => {
            console.log('loader err of ' + stateId, ': ', err)
        })
    }

    render() {
        return (
            <footer className="footer axllearn-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-100">
                            <p><img id="footerlogo" src={this.state.logo} alt="logo" /></p>
                            <p>{this.state.text}</p>
                        </div>
                        <div className="col-sm-3 col-33">
                            <ul>
                                <li><Link target="_blank" className="nav-item nav-link" to="/aboutus">About Us</Link></li>
                                <li><Link target="_blank" className="nav-item nav-link" to="/careers">Careers</Link></li>
                                <li><Link target="_blank" className="nav-item nav-link" to="/contactus">Contact Us</Link></li>
                                <li><Link target="_blank" className="nav-item nav-link" to="/helpsupport">Help and Support</Link></li>
                                <li><Link target="_blank" className="nav-item nav-link" to="/affiliate">Affiliate</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-3 col-33">
                            <ul>
                                <li><Link target="_blank" className="nav-item nav-link" to="/featuredcourse">Featured Courses</Link></li>
                                <li><Link target="_blank" className="nav-item nav-link" to="/sitemap">Site Map</Link></li>
                                <li><Link target="_blank" className="nav-item nav-link" to="/privacypolicy">Privacy Policy</Link></li>
                                <li><Link target="_blank" className="nav-item nav-link" to="/termsconditions">Terms & Conditions</Link></li>
                            </ul>
                        </div>
                        <div className="col-sm-2 col-33">
                            <ul>
                                <li><a target="_blank" rel="noopener noreferrer" className="nav-item nav-link" href="mailto:support@axllearn.com"><i className="bi bi-envelope-fill"></i> Send email</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" className="nav-item nav-link" href="https://wa.me/919449150110"><i className="bi bi-whatsapp"></i> WhatsApp</a></li>
                                {/* <li><Link target="_blank" className="nav-item nav-link" to="/linkedin"><i className="bi bi-linkedin"></i> Linkedin</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <h6>© {this.state.copyright}</h6>
                </div>
            </footer>
        );
    }
};

export default Footer;
