import React, { Component } from 'react';
import './categories.scss';

class Categories extends Component {
    render() {
        return (
            <div className="page-content">
                <div className="container">
                    <h3 className="comingsoon text-danger">Categories: Contents coming soon...</h3>
                    <p className="comingsoonp">Thanks for your patience.</p>
                </div>
            </div>
        )
    }
}

export default Categories;