import React, { Component } from 'react';

import nwsltrImg from '../../../assets/images/nwsltr-header.jpg';
import nwsltrImg3 from '../../../assets/images/nwsltr-header-2.jpg';
import nwsltrImg4 from '../../../assets/images/nwsltr-header-3.jpg';
import nwsltrImg5 from '../../../assets/images/nwsltr-header-4.jpg';
import nwsltrImg6 from '../../../assets/images/nwsltr-header-5.jpg';
import nwsltrImg1 from '../../../assets/images/nwsltr-img1.jpg';
import nwsltrImg2 from '../../../assets/images/nwsltr-img2.jpg';
import linkedIn from '../../../assets/images/linkedin.png';
import youTube from '../../../assets/images/youtube.png';



class Demo extends Component {

    render() {
        return (
            <React.Fragment>
                <div className="page-content bg-smild-grey">
                    <div className="container">
                        <div className='notificationBlock'>
                            <div className='nBlock'>
                                <span className='nNumber'>1.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                                <p>Blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>2.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs.</p>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>4.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>3.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <ul>
                                    <li>The charms of pleasure of the moment, so blinded by desire, that they cannot foresee.</li>
                                    <li>Pleasure of the moment, so blinded by desire.</li>
                                    <li>Charms of pleasure of the moment, that they cannot foresee so blinded by desire.</li>
                                </ul>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>5.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                            <div className='nBlock'>
                                <span className='nNumber'>6.</span>
                                <span className='dateTime'>19.09.2022 | 9.48am</span>
                                <h4>On the other hand, we denounce with righteous indignation.</h4>
                                <p>Who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness.</p>
                            </div>
                        </div>
                        {/* <div className='email-template'>
                            <h2>1. Greetings Template.</h2>
                            <table style={{ fontSize: "14px", fontWeight: "normal", border: "none", width: "600px", textAlign: "justify" }}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}><img src={nwsltrImg} alt="///"></img></th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "#fff" }}>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "30px 15px 15px 15px" }}>
                                            <h5 style={{ fontSize: "18px", fontWeight: "700", color: "#000", margin: "0 0 10px 0" }}>Just relax and go with the flow.</h5>
                                            <p style={{ fontSize: "14px", fontWeight: "400", color: "#242424", margin: "0 0 15px 0" }}>Axl Learn is an EdTech company that has been developing and delivering different types of learning courses for corporates around the world, since 2013.</p>
                                            <p style={{ fontSize: "14px", fontWeight: "400", color: "#242424", margin: "0" }}>Alumnus from IIT Kharagpur, XLRI Jamshedpur, and University of Massachusetts, our founders have invested over 30+ years in training and technology, and have built this unique learning platform with the aim to make learning visual, engaging, and localized, to ensure higher retention of learning.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "0 15px" }}>
                                            <h5 style={{ fontSize: "18px", fontWeight: "700", color: "#000", margin: "0" }}>Get trained build products.</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{ padding: "10px 15px" }}><img src={nwsltrImg2} alt="///"></img></td>
                                        <td style={{ padding: "10px 15px" }}><img src={nwsltrImg1} alt="///"></img></td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px" }}>
                                            <p style={{ fontSize: "14px", fontWeight: "400", color: "#242424", margin: "0 0 15px 0" }}>Our endeavor began in 2013 with learning technologies that used algorithm based digital learning simulators to deliver techno-leadership courses with the aim of delivering learning through gaming. We transformed the way classroom training was delivered to the corporates. We converted learning programs from "chalk-and-talk" training experience to an immersive learning experience through "learning-by-doing".</p>
                                            <p style={{ fontSize: "14px", fontWeight: "400", color: "#242424", margin: "0 0 10px 0" }}>In 2016 and 2017, our product line SimWorks (www.simworks.online) won multiple global awards in learning for contributing to client's revenue growth through highly engaging content, delivery and measurement methods.</p>
                                            <h5 style={{ fontSize: "15px", color: "#269cd4", fontWeight: "500", marginTop: "10px", marginBottom: "10px" }}>Thank you for joining with us.<br></br> We will keep you posted.</h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px", backgroundColor: "#242424" }}>
                                            <p style={{ padding: "10px 0", textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                                                <span style={{ textAlign: "left" }}><a href='/#' style={{ margin: "0", color: "#fff", fontWeight: "600" }}>axllearn.com</a><br></br><span style={{ color: "#ccc" }}>© 2022 Axllearn. All rights reserved.</span></span>
                                                <span><a href='/#' style={{ margin: "0 10px 0 0", }}><img src={linkedIn} style={{ width: "40px" }} alt="///"></img></a><a href='/#'><img src={youTube} style={{ width: "40px" }} alt="///"></img></a></span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ margin: "30px 0 15px 0" }}></div>
                            <h2>2. Email verification.</h2>
                            <table style={{ fontSize: "14px", fontWeight: "normal", border: "none", width: "600px", textAlign: "justify" }}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}><a href='http://www.axllearn.com/'><img src={nwsltrImg3} alt="///"></img></a></th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "#fff" }}>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "30px 15px 15px 15px" }}>
                                            <h5 style={{ fontSize: "16px", fontWeight: "600", color: "#767676", margin: "0" }}>You're one step away.</h5>
                                            <h3 style={{ fontSize: "36px", fontWeight: "600", color: "#242424", margin: "0" }}>Verify your e-mail address.</h3>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px" }}>
                                            <h4 style={{ fontSize: "20px", fontWeight: "400", color: "#242424", margin: "15px 0" }}>To complete your profile and start taking the course, you'll need to verify your email address.</h4>
                                            <p style={{ fontSize: "14px", fontWeight: "400", color: "#767676", margin: "15px 0 30px" }}>Click on this "Verify Now" button to complete your verification.</p>
                                            <h6 style={{ textAlign: "center", margin: "20px 15px" }}><a href='/#' style={{ fontSize: "16px", backgroundColor: "#e60a16", padding: "15px 30px", borderRadius: "30px", color: "#fff", display: "inline-block" }}>Verify Now</a></h6>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px" }}>
                                            <h4 style={{ fontSize: "18px", fontWeight: "400", color: "#008bcc", margin: "15px 0" }}>Best Regards,<br></br><span style={{ fontWeight: "600" }}>Platform Team</span></h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px", backgroundColor: "#242424" }}>
                                            <p style={{ padding: "10px 0", textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                                                <span style={{ textAlign: "left" }}><a href='http://www.axllearn.com/' style={{ margin: "0", color: "#fff", fontWeight: "600" }}>axllearn.com</a><br></br><span style={{ color: "#ccc" }}>© 2022 Axllearn. All rights reserved.</span></span>
                                                <span><a href='/#' style={{ margin: "0 10px 0 0", }}><img src={linkedIn} style={{ width: "40px" }} alt="///"></img></a><a href='/#'><img src={youTube} style={{ width: "40px" }} alt="///"></img></a></span>
                                            </p>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div style={{ margin: "30px 0 15px 0" }}></div>
                            <h2>3. Reset Password.</h2>
                            <table style={{ fontSize: "14px", fontWeight: "normal", border: "none", width: "600px", textAlign: "justify" }}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}><a href="http://www.axllearn.com/"><img src={nwsltrImg4} alt="///"></img></a></th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "#fff" }}>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "30px 15px 15px 15px" }}>
                                            <h5 style={{ fontSize: "16px", fontWeight: "600", color: "#767676", margin: "0" }}>Hello,</h5>
                                            <h3 style={{ fontSize: "36px", fontWeight: "600", color: "#242424", margin: "0" }}>Mr. Anuj</h3>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px" }}>
                                            <h4 style={{ fontSize: "20px", fontWeight: "400", color: "#242424", margin: "15px 0" }}>We're sending you this email because you requested a password reset.</h4>
                                            <p style={{ fontSize: "14px", fontWeight: "400", color: "#767676", margin: "15px 0 30px" }}>Click on this "Click here to Reset" button to reset your password.</p>
                                            <h6 style={{ textAlign: "center", margin: "20px 15px" }}><a href='/#' style={{ fontSize: "16px", backgroundColor: "#e60a16", padding: "15px 30px", borderRadius: "30px", color: "#fff", display: "inline-block" }}>Click Here to Reset</a></h6>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px" }}>
                                            <h4 style={{ fontSize: "18px", fontWeight: "400", color: "#008bcc", margin: "15px 0" }}>Best Regards,<br></br><span style={{ fontWeight: "600" }}>Platform Team</span></h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px", backgroundColor: "#242424" }}>
                                            <p style={{ padding: "10px 0", textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                                                <span style={{ textAlign: "left" }}><a href='http://www.axllearn.com/' style={{ margin: "0", color: "#fff", fontWeight: "600" }}>axllearn.com</a><br></br><span style={{ color: "#ccc" }}>© 2022 Axllearn. All rights reserved.</span></span>
                                                <span><a href='/#' style={{ margin: "0 10px 0 0", }}><img src={linkedIn} style={{ width: "40px" }} alt="///"></img></a><a href='/#'><img src={youTube} style={{ width: "40px" }} alt="///"></img></a></span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ margin: "30px 0 15px 0" }}></div>
                            <h2>3. Action Plan.</h2>
                            <table style={{ fontSize: "14px", fontWeight: "normal", border: "none", width: "600px", textAlign: "justify" }}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}><a href="http://www.axllearn.com/"><img src={nwsltrImg5} alt="///"></img></a></th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "#fff" }}>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "30px 15px 0px 15px" }}>
                                            <h5 style={{ fontSize: "16px", fontWeight: "600", color: "#242424", margin: "0" }}>Hello Sir,</h5>
                                            <p style={{ fontSize: "14px", fontWeight: "400", color: "#242424", margin: "10px 0" }}>Please review this action plan and try to send me your response.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px" }}>
                                            <table style={{ fontSize: "13px", fontWeight: "normal", border: "none", width: "600px", textAlign: "justify", borderCollapse: "collapse" }}>
                                                <tbody>
                                                    <tr style={{ border: "1px solid #0077ad", borderCollapse: "collapse" }}>
                                                        <td style={{ padding: "10px", backgroundColor: "#008bcc", color: "#fff", lineHeight: "18px" }}>Key Learning</td>
                                                        <td style={{ padding: "10px", lineHeight: "18px" }}>On the other hand, we unce with rightus indignation nd dislike men who.
                                                            Beguiled and demoralized by the charms of pleasure of the moment, so blinded.</td>
                                                    </tr>
                                                    <tr style={{ border: "1px solid #0077ad", borderCollapse: "collapse" }}>
                                                        <td style={{ padding: "10px", backgroundColor: "#008bcc", color: "#fff", lineHeight: "18px" }}>Action Plans</td>
                                                        <td style={{ padding: "10px", lineHeight: "18px" }}>Hand On the other we ace with rightus indignation.
                                                            And dislike men who deed.
                                                            Beguiled and demoralized by the charms of pleasure.</td>
                                                    </tr>
                                                    <tr style={{ border: "1px solid #0077ad", borderCollapse: "collapse" }}>
                                                        <td style={{ padding: "10px", backgroundColor: "#008bcc", color: "#fff", lineHeight: "18px" }}>Required Resources</td>
                                                        <td style={{ padding: "10px", lineHeight: "18px" }}>Team - CAPITAL Team - XYZX
                                                            - Resource name one
                                                            - Resource name two
                                                            - Resource name three
                                                            Reel services to serve the needed facility</td>
                                                    </tr>
                                                    <tr style={{ border: "1px solid #0077ad", borderCollapse: "collapse" }}>
                                                        <td style={{ padding: "10px", backgroundColor: "#008bcc", color: "#fff", lineHeight: "18px" }}>Success Criteria</td>
                                                        <td style={{ padding: "10px", lineHeight: "18px" }}>We ounce with rightus ation and dislike men who.
                                                            Demoralized by the charms of pleasure of the moment, so blinded.
                                                            With righteous indination and dislike men who are.</td>
                                                    </tr>
                                                    <tr style={{ border: "1px solid #0077ad", borderCollapse: "collapse" }}>
                                                        <td style={{ padding: "10px", backgroundColor: "#008bcc", color: "#fff", lineHeight: "18px" }}>Recipient Email</td>
                                                        <td style={{ padding: "10px", lineHeight: "18px" }}>company.manager@mail.com</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px" }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "400", color: "#008bcc", margin: "15px 0" }}>Best Regards,<br></br><span style={{ fontWeight: "600" }}>Plan creator's name.</span></h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px", backgroundColor: "#242424" }}>
                                            <p style={{ padding: "10px 0", textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                                                <span style={{ textAlign: "left" }}><a href='http://www.axllearn.com/' style={{ margin: "0", color: "#fff", fontWeight: "600" }}>axllearn.com</a><br></br><span style={{ color: "#ccc" }}>© 2022 Axllearn. All rights reserved.</span></span>
                                                <span><a href='/#' style={{ margin: "0 10px 0 0", }}><img src={linkedIn} style={{ width: "40px" }} alt="///"></img></a><a href='/#'><img src={youTube} style={{ width: "40px" }} alt="///"></img></a></span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div style={{ margin: "30px 0 15px 0" }}></div>
                            <h2>4. Pathway Generate.</h2>
                            <table style={{ fontSize: "14px", fontWeight: "normal", border: "none", width: "600px", textAlign: "justify" }}>
                                <thead>
                                    <tr>
                                        <th colSpan={2}><a href="http://www.axllearn.com/"><img src={nwsltrImg6} alt="///"></img></a></th>
                                    </tr>
                                </thead>
                                <tbody style={{ backgroundColor: "#fff" }}>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "30px 15px 0px 15px" }}>
                                            <p style={{ fontSize: "15px", color: "#242424", margin: "0 0 10px 0", fontWeight: "500" }}>Download your ICS file template here.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "15px 15px 0px 15px" }}>
                                            <table style={{ width: "100%", fontSize: "14px" }}>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f2f2f2", fontWeight: "500" }}>Pathway Title</td>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#ffffff" }}>Pathway title goes here</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#e8e8e8", fontWeight: "500" }}>Course Title</td>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f7f7f7" }}>Course title goes here</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f2f2f2", fontWeight: "500" }}>Start Date</td>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#ffffff" }}>30/03/2022</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#e8e8e8", fontWeight: "500" }}>End Date</td>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f7f7f7" }}>06/04/2022</td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#f2f2f2", fontWeight: "500" }}>Hours Spent</td>
                                                        <td style={{ border: "1px solid #ccc", padding: "10px", backgroundColor: "#ffffff" }}>6h 24m 56s</td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2} style={{ padding: "15px 0", textAlign: "right" }}><button type='button' style={{ backgroundColor: "#ffc900", fontSize: "14px", color: "#000", padding: "10px 20px", border: "none", borderBottom: "2px solid #242424", borderRadius: "6px", fontWeight: "500" }}>Download ICS File</button></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px" }}>
                                            <h4 style={{ fontSize: "16px", fontWeight: "400", color: "#008bcc", margin: "15px 0" }}>Best Regards,<br></br><span style={{ fontWeight: "600" }}>Author's name.</span></h4>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{ padding: "10px 15px", backgroundColor: "#242424" }}>
                                            <p style={{ padding: "10px 0", textAlign: "center", display: "flex", justifyContent: "space-between" }}>
                                                <span style={{ textAlign: "left" }}><a href='http://www.axllearn.com/' style={{ margin: "0", color: "#fff", fontWeight: "600" }}>axllearn.com</a><br></br><span style={{ color: "#ccc" }}>© 2022 Axllearn. All rights reserved.</span></span>
                                                <span><a href='/#' style={{ margin: "0 10px 0 0", }}><img src={linkedIn} style={{ width: "40px" }} alt="///"></img></a><a href='/#'><img src={youTube} style={{ width: "40px" }} alt="///"></img></a></span>
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div> */}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default Demo;