import api from '../Api/Api';

class Auth {
    constructor() {
        this.authenticated = false
    }

    login(_callback) {
        this.authenticated = true;
        if (_callback) {
            _callback()
        }

    }

    logout(_callback) {
        let wl = window.location;
        this.authenticated = false;
        if (_callback) {
            _callback()
        } else {
            window.location.replace(wl.protocol + '//' + wl.hostname + (wl.port !== '' ? ':' + wl.port : '') + '/login')
        }
    }

    async isAuthenticated() {
        // localStorage.setItem('history', window.location.href)
        let res = await  api.authValidate();
        if(res.data.resCode === 200){
            this.authenticated = true;
            return this.authenticated;
        }else{
            this.authenticated = false;
            return this.authenticated;
        } 
    }
}

export default new Auth()