import React, { Component } from 'react';
import './helpsupport.scss';

class HelpSupport extends Component {
    render() {
        return (
            <div className="page-content">
                <div class="fluid-banner"><h1>Help and Support </h1></div>
                <div className="container inner-content">
                    <div className='row'>
                        <div className='col-12'>
                            <div className='help-text'>
                                <p>For any query or complaints regarding access / content, or to report any technical issue, please log your query or email us at <a href="mailto: support@axllearn.com">support@axllearn.com</a>. We will raise a ticket, and resolve your query within 48hours.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default HelpSupport;