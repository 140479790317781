import React, { Component } from 'react';
import './termsconditions.scss';

class TermsConditions extends Component {
  render() {
    return (
      <div className="page-content">
        <div class="fluid-banner"><h1>Terms and Conditions</h1></div>
        <div className="container inner-content">
          <div className='termsofuse'>
            <h4>Terms of Use</h4>
            <h6>These Terms of Use ("Terms") were last updated on July 13, 2022.</h6>
            <p>Axl Learn’s mission is to make learning fun and engaging and thereby improve performance of the Learner in their personal and professional lives. We enable anyone anywhere to create and share educational content (Course Authors) and to access that educational content to learn (Course Learners). We consider our marketplace model the best way to offer valuable educational content to our users. We need rules to keep our platform and services safe for you, us, and our Learner and Author community. These Terms apply to all your activities on the Axl Learn website, Axl Learn mobile applications, our TV applications, our APIs, and other related services <strong>(“Services”)</strong>.</p>
            <p>If you publish a course on the Axl Learn platform, you must also agree to the Course Author Terms. We also provide details regarding our processing of personal data of our Course Learner and Course Author in our Privacy Policy. If you are using Axl Learn as part of your employer’s Business learning and development program, you can consult our Business Privacy Statement.</p>
            <p><strong>If you live outside of India, by agreeing to these Terms, you agree to resolve disputes with Axl Learn, the jurisdiction will be in the Indian Subcontinent detailed in the Dispute Resolution section.</strong></p>

            <div className='terms-points'>
              <h4>1. Accounts</h4>
              <p>You need an account for most activities on our platform. Keep your password somewhere safe, because you’re responsible for all activity associated with your account. If you suspect someone else is using your account, let us know by contacting our Support Team. You must have reached the age of consent for online services in your country to use Axl Learn.</p>
              <p>You need an account for most activities on our platform, including to purchase and access content or to submit content for publication. When setting up and maintaining your account, you must provide and continue to provide accurate and complete information, including a valid email address. You have complete responsibility for your account and everything that happens on your account, including for any harm or damage (to us or anyone else) caused by someone using your account without your permission. This means you need to be careful with your password. You may not transfer your account to someone else or use someone else’s account. If you contact us to request access to an account, we will not grant you such access unless you can provide us with the information that we need to prove you are the owner of that account. In the event of the death of a user, the account of that user will be closed.</p>
              <p>You may not share your account login credentials with anyone else. You are responsible for what happens with your account and Axl Learn will not intervene in disputes between Learners or Authors who have shared account login credentials. You must notify us immediately upon learning that someone else may be using your account without your permission (or if you suspect any other breach of security) by contacting our Support Team. We may request some information from you to confirm that you are indeed the owner of your account.</p>
              <p>Learners and Authors must be at least 18 years of age to create an account on Axl Learn and use the Services. If you are younger than 18 but above the required age for consent to use online services where you live (for example, 13 in the US or 16 in Ireland), you may not set up an account, but we encourage you to invite a parent or guardian to open an account and help you access content that is appropriate for you. If you are below this age of consent to use online services, you may not create a Axl Learn account. If we discover that you have created an account that violates these rules, we will terminate your account. Under our Author Terms, you may be requested to verify your identity before you are authorized to submit content for publication on Axl Learn.</p>
              <p>You can suspend/ ‘terminate’ your account at any time. </p>
            </div>
            <div className='terms-points'>
              <h4>2. Content Enrolment and Course Access</h4>
              <p>When you enrol in a course or other content, you get a license from us to view it via the Axl Learn Services and no other use. Don’t try to transfer or resell content in any way. We generally grant you a limited duration access license, except when we must disable the content because of legal or policy reasons or for enrolments via Subscription Plans.
                Under our Author Terms, when Authors publish content on Axl Learn platform, they grant Axl Learn a license to offer a license to the content to Learners. This means that we have the right to sublicense the content to enrolled Learners. As a Learner, when you enroll in a course or other content, whether it’s free or paid content, you are getting a license from Axl Learn to view the content via the Axl Learn platform and Services, and Axl Learn is the licensor of record. Content is licensed, and not sold, to you. This license does not give you any right to resell the content in any manner (including by sharing account information with a purchaser or illegally downloading the content and sharing it on torrent sites).
              </p>
              <p>In legal, more complete terms, Axl Learn grants you (as a Learner) a limited, non-exclusive, non-transferable license to access and view the content for which you have paid all required fees, solely for your personal, non-commercial, educational purposes through the Services, in accordance with these Terms and any conditions or restrictions associated with the particular content or feature of our Services. All other uses are expressly prohibited. You may not reproduce, redistribute, transmit, assign, sell, broadcast, rent, share, lend, modify, adapt, edit, create derivative works of, sublicense, or otherwise transfer or use any content unless we give you explicit permission to do so in a written agreement signed by a Axl Learn authorized representative. This also applies to content you can access via any of our APIs.</p>
              <p>We generally give a limited duration access license to our Learners when they enroll in a course or other content. However, we reserve the right to revoke any license to access and use any content at any point in time in the event where we decide or are obligated to disable access to the content due to legal or policy reasons, for example, if the course or other content you enrolled in is the object of a copyright complaint, or if we determine it violates our Trust & Safety Guidelines. </p>
              <p>Authors may not grant licenses to their content to Learners directly, and any such direct license shall be null and void and a violation of these Terms.</p>
            </div>
            <div className='terms-points'>
              <h4>3. Payments, Credits, and Refunds</h4>
              <p>When you make a payment, you agree to use a valid payment method. If you aren’t happy with your content, Axl Learn offers a 7-days refund or credit for most content purchases.</p>
              <div className='terms-subpoints'>
                <h5>3.1 Pricing</h5>
                <p>The prices of content on Axl Learn are determined based on the terms of the Author Terms and our Promotions Policy. In some instances, the price of content offered on the Axl Learn website may not be exactly the same as the price offered on our mobile or TV applications, due to mobile platform providers’ pricing systems and their policies around implementing sales and promotions.</p>
                <p>We occasionally run promotions and sales for our content, during which certain content is available at discounted prices for a set period of time. The price applicable to the content will be the price at the time you complete your purchase of the content (at checkout). Any price offered for particular content may also be different when you are logged into your account from the price available to users who aren’t registered or logged in, because some of our promotions are available only to new users.</p>
                <p>If you are logged into your account, the listed currency you see is based on your location when you created your account. If you are not logged into your account, the price currency is based on the country where you are located. We do not enable users to see pricing in other currencies.</p>
                <p>If you are a Learner located in a country where use sales tax, goods and services tax, or value added tax is applicable to consumer sales, we are responsible for collecting and remitting that tax to the proper tax authorities. Depending on your location, the price you see may include such taxes, or tax may be added at checkout.</p>
                <h5>3.2 Payments</h5>
                <p>You agree to pay the fees for content that you purchase, and you authorize us to charge your debit or credit card or process other means of payment (such as direct debit, or mobile wallet) for those fees. Axl Learn works with payment service providers to offer you the most convenient payment methods in your country and to keep your payment information secure. We may update your payment methods using information provided by our payment service providers. Check out our Privacy Policy for more details.</p>
                <p>When you make a purchase, you agree not to use an invalid or unauthorized payment method. You are granted access to the course only on successful payment of the course fees. We reserve the right to disable access to any content for which we have not received adequate payment.</p>
                <h5>3.3 Refunds and Refund Credits</h5>
                <p>If the content you purchased is not what you were expecting, you can request, within 7 calendar days of your purchase of the content, that Axl Learn apply a refund to your account. We reserve the right to apply your refund as a refund credit or a refund to your original payment method, at our discretion, depending on capabilities of our payment service providers, the platform from which you purchased your content (website, mobile or TV app), and other factors. No refund is due to you if you request it after the 7 calendar-day guarantee time limit has passed. Axl Learn also reserves the right to refund Learners beyond the 7 calendar-day limit in cases of suspected or confirmed account fraud.</p>
                <p>If we decide to issue refund credits to your account, they will be automatically applied towards your next content purchase on our website, but can’t be used for purchases in our mobile or TV applications. Refund credits may expire if not used within the specified period and have no cash value, in each case unless otherwise required by applicable law.</p>
                <p>At our discretion, if we believe you are abusing our refund policy, such as if you’ve consumed a significant portion of the content that you want to refund or if you’ve previously refunded the content, we reserve the right to deny your refund, restrict you from other future refunds, ban your account, and/or restrict all future use of the Services. If we ban your account or disable your access to the content due to your violation of these Terms or our Trust & Safety Guidelines, you will not be eligible to receive a refund. </p>
                <h5>3.4 Gift and Promotional Codes</h5>
                <p>Axl Learn or our partners may offer gift and promotional codes to Learners. Certain codes may be redeemed for gift or promotional credits applied to your Axl Learn account, which then may be used to purchase eligible content on our platform, subject to the terms included with your codes. Other codes may be directly redeemable for specific content. Gift and promotional credits can’t be used for purchases in our mobile or TV applications.</p>
                <p>These codes and credits, as well as any promotional value linked to them, may expire if not used within the period specified in your Axl Learn account. Gift and promotional codes offered by Axl Learn may not be refunded for cash, unless otherwise specified in the terms included with your codes or as required by applicable law. Gift and promotional codes offered by a partner are subject to that partner’s refund policies. If you have multiple saved credit amounts, Axl Learn may determine which of your credits to apply to your purchase. Check out our Support Page and any terms included with your codes for more details.</p>
              </div>
            </div>
            <div className='terms-points'>
              <h4>4. Content and Behaviour Rules</h4>
              <p>You can only use Axl Learn for lawful purposes. You’re responsible for all the content that you post on our platform. You should keep the reviews, questions, posts, courses and other content you upload in line with our Trust & Safety Guidelines and the law, and respect the intellectual property rights of others. We can ban your account for repeated or major offenses. If you think someone is infringing your copyright on our platform, let us know.</p>
              <p>You may not access or use the Services or create an account for unlawful purposes. Your use of the Services and behaviour on our platform must comply with applicable local or national laws or regulations of your country. You are solely responsible for the knowledge of and compliance with such laws and regulations that are applicable to you.</p>
              <p>You are solely responsible for any courses, content, and actions you post or take via the platform and Services and their consequences. Make sure you understand all the copyright restrictions set forth in the Author Terms before you submit any content for publication on Axl Learn.</p>
              <p>If we are put on notice that your course or content violates the law or the rights of others (for example, if it is established that it violates intellectual property or image rights of others, or is about an illegal activity), if we discover that your content or behaviour violates our Trust & Safety Guidelines, or if we believe your content or behaviour is unlawful, inappropriate, or objectionable (for example if you impersonate someone else), we may remove your content from our platform. Axl Learn complies with copyright laws. Check out our Intellectual Property Policy for more details.</p>
              <p>Axl Learn has discretion in enforcing these Terms and our Trust & Safety Guidelines. We may restrict or terminate your permission to use our platform and Services or ban your account at any time, with or without notice, for any or no reason, including for any violation of these Terms, if you fail to pay any fees when due, for fraudulent chargeback requests, upon the request of law enforcement or government agencies, for extended periods of inactivity, for unexpected technical issues or problems, if we suspect that you engage in fraudulent or illegal activities, or for any other reason in our sole discretion. Upon any such termination we may delete your account and content, and we may prevent you from further access to the platforms and use of our Services. Your content may still be available on the platforms even if your account is terminated or suspended. You agree that we will have no liability to you or any third party for termination of your account, removal of your content, or blocking of your access to our platforms and services.</p>
              <p>If an Author or Learner has published content that infringes your copyright or trademark rights, please let us know. Our Author Terms require our Authors to follow the law and respect the intellectual property rights of others. For more details on how to file a copyright or trademark infringement claim with us, see our Intellectual Property Policy.</p>
            </div>
            <div className='terms-points'>
              <h4>5. Axl Learn’s Rights to the Content You Post</h4>
              <p>You retain ownership of content you post to our platform, including your courses. We’re allowed to share your content to anyone through any media, including promoting it via advertising on other websites.</p>
              <p>The content you post as a Learner or Author (including courses) remains yours. By posting courses and other content, you allow Axl Learn to reuse and share it but you do not lose any ownership rights you may have over your content. If you are an Author, be sure to understand the content licensing terms that are detailed in the Author Terms.</p>
              <p>When you post content, comments, questions, reviews, and when you submit to us ideas and suggestions for new features or improvements, you authorize Axl Learn to use and share this content with anyone, distribute it and promote it on any platform and in any media, and to make modifications or edits to it as we see fit.</p>
              <p>In legal language, by submitting or posting content on or through the platforms, you grant us a worldwide, non-exclusive, royalty-free license (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display, and distribute your content (including your name and image) in any and all media or distribution methods (existing now or later developed). This includes making your content available to other companies, organizations, or individuals who partner with Axl Learn for the syndication, broadcast, distribution, or publication of content on other media, as well as using your content for marketing purposes. You also waive any rights of privacy, publicity, or other rights of a similar nature applicable to all these uses, to the extent permissible under applicable law. You represent and warrant that you have all the rights, power, and authority necessary to authorize us to use any content that you submit. You also agree to all such uses of your content with no compensation paid to you.</p>
            </div>
            <div className='terms-points'>
              <h4>6. Using Axl Learn at Your Own Risk</h4>
              <p>Anyone can use Axl Learn to create and publish content and Authors and we enable Authors and Learners to interact for teaching and learning. Like other platforms where people can post content and interact, some things can go wrong, and you use Axl Learn at your own risk.</p>
              <p>Our platform model means we do not review or edit the content for legal issues, and we are not in a position to determine the legality of content. We do not exercise any editorial control over the content that is available on the platform and, as such, do not guarantee in any manner the reliability, validity, accuracy, or truthfulness of the content. If you access content, you rely on any information provided by a Course Author at your own risk.</p>
              <p>By using the Services, you may be exposed to content that you consider offensive, indecent, or objectionable. Axl Learn has no responsibility to keep such content from you and no liability for your access or enrolment in any course or other content, to the extent permissible under applicable law. This also applies to any content relating to health, wellness, and physical exercise. You acknowledge the inherent risks and dangers in the strenuous nature of these types of content, and by accessing such content you choose to assume those risks voluntarily, including risk of illness, bodily injury, disability, or death. You assume full responsibility for the choices you make before, during, and after your access to the content.</p>
              <p>When you interact directly with a Learner or an Author, you must be careful about the types of personal information that you share. While we restrict the types of information Authors may request from Learners, we do not control what Learners and Authors do with the information they obtain from other users on the platform. You should not share your email or other personal information about you for your safety.</p>
              <p>We do not hire or employ Authors nor are we responsible or liable for any interactions involved between Authors and Learners. We are not liable for disputes, claims, losses, injuries, or damage of any kind that might arise out of or relate to the conduct of Authors or Learners.</p>
              <p>When you use our Services, you will find links to other websites that we don’t own or control. We are not responsible for the content or any other aspect of these third-party sites, including their collection of information about you. You should also read their terms and conditions and privacy policies.</p>
            </div>
            <div className='terms-points'>
              <h4>7. Axl Learn’s Rights</h4>
              <p>We own the Axl Learn platform and Services, including the website, present or future apps and services, and things like our logos, API, code, and content created by our employees. You can’t tamper with those or use them without authorization.</p>
              <p>All right, title, and interest in and to the Axl Learn platform and Services, including our website, our existing or future applications, our APIs, databases, and the content our employees or partners submit or provide through our Services (but excluding content provided by Authors) are and will remain the exclusive property of Axl Learn and its licensors. Our platforms and services are protected by copyright, trademark, and other laws of both India and foreign countries. Nothing gives you a right to use the Axl Learn name or any of the Axl Learn trademarks, logos, domain names, and other distinctive brand features. Any feedback, comments, or suggestions you may provide regarding Axl Learn or the Services is entirely voluntary and we will be free to use such feedback, comments, or suggestions as we see fit and without any obligation to you.</p>
              <p>You may not do any of the following while accessing or using the Axl Learn platform and Services:</p>
              <ul>
                <li>Access, tamper with, or use non-public areas of the platform (including content storage), Axl Learn’s computer systems, or the technical delivery systems of Axl Learn’s service providers.</li>
                <li>Disable, interfere with, or try to circumvent any of the features of the platforms related to security or probe, scan, or test the vulnerability of any of our systems.</li>
                <li>Copy, modify, create a derivative work of, reverse engineer, reverse assemble, or otherwise attempt to discover any source code of or content on the Axl Learn platform or Services.</li>
                <li>Access or search or attempt to access or search our platform by any means (automated or otherwise) other than through our currently available search functionalities that are provided via our website, mobile apps, or API (and only pursuant to those API terms and conditions). You may not scrape, spider, use a robot, or use other automated means of any kind to access the Services.</li>
                <li>In any way use the Services to send altered, deceptive, or false source-identifying information (such as sending email communications falsely appearing as Axl Learn); or interfere with, or disrupt, (or attempt to do so), the access of any user, host, or network, including, without limitation, sending a virus, overloading, flooding, spamming, or mail-bombing the platforms or services, or in any other manner interfering with or creating an undue burden on the Services.</li>
              </ul>
            </div>
            <div className='terms-points'>
              <h4>8 Free Trials & Purchases</h4>
              <p>Your subscription to a particular course may start with a free trial. The duration of the free trial period of your course subscription will be specified during sign-up. Axl Learn determines free trial eligibility at our sole discretion and may limit eligibility or duration. We reserve the right to terminate the free trial and suspend your subscription if we determine that you aren’t eligible.</p>
              <p>We will charge the course subscription fee when you have reached the end of the free trial period.</p>
            </div>
            <div className='terms-points'>
              <h4>8.4 Payments and Billing</h4>
              <p>The course subscription fee will be listed at the time of your purchase. You can visit our Support Page to learn more about where to find the course fees and dates applicable to your subscription. We may also be required to add taxes to your course subscription fee as described in the “Payments, Credits, and Refunds” section above. Payments are non-refundable and there are no refunds or credits for partially used periods, unless otherwise required by applicable law. Depending on where you are located, you may qualify for a refund. See our Refund Policy for Subscription Plans for additional information.</p>
              <p>To subscribe to a Course Subscription, you must provide a payment method. By subscribing to a Course Subscription and providing your billing information during checkout, you grant us and our payment service providers the right to process payment for the then-applicable fees via the payment method we have on record for you. </p>
              <p>If we are unable to process payment through the payment method we have on file for you, or if you file a chargeback disputing charges made to your payment method and the chargeback is granted, we may suspend or terminate your subscription.</p>
            </div>
            <div className='terms-points'>
              <h4>8.5 Subscription Disclaimers</h4>
              <p>We make no guarantees as to the availability of any specific content in any Subscription Plan or as to any minimum amount of content in any Subscription Plan. At any point in the future, we reserve the right to offer or cease to offer additional features to any Subscription Plan, or to otherwise modify or terminate a Subscription Plan at our sole discretion. These disclaimers are in addition to those listed in the “Disclaimers” section below.</p>
            </div>
            <div className='terms-points'>
              <h4>9. Miscellaneous Legal Terms</h4>
              <p>These Terms are like any other contract, and they have boring but important legal terms that protect us from the countless things that could happen and that clarify the legal relationship between us and you.</p>
              <div className='terms-subpoints'>
                <h5>9.1 Binding Agreement</h5>
                <p>You agree that by registering, accessing, or using our Services, you are agreeing to enter into a legally binding contract with Axl Learn. If you do not agree to these Terms, do not register, access, or otherwise use any of our Services.</p>
                <p>If you are an Author accepting these Terms and using our Services on behalf of a company, organization, government, or other legal entity, you represent and warrant that you are authorized to do so.</p>
                <p>Any version of these Terms in a language other than English is provided for convenience and you understand and agree that the English language will control if there is any conflict.</p>
                <p>These Terms (including any agreements and policies linked from these Terms) constitute the entire agreement between you and us (which include, if you are an Author, the Author Terms and the Promotions Policy).</p>
                <p>If any part of these Terms is found to be invalid or unenforceable by applicable law, then that provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the remainder of these Terms will continue in effect.</p>
                <p>Even if we are delayed in exercising our rights or fail to exercise a right in one case, it doesn’t mean we waive our rights under these Terms, and we may decide to enforce them in the future. If we decide to waive any of our rights in a particular instance, it doesn’t mean we waive our rights generally or in the future.</p>
                <p>The following sections shall survive the expiration or termination of these Terms: Sections 2 (Content Enrollment and Lifetime Access), 5 (Axl Learn’s Rights to Content You Post), 6 (Using Axl Learn at Your Own Risk), 7 (Axl Learn’s Rights), 8.5 (Subscription Disclaimers), 9 (Miscellaneous Legal Terms), and 10 (Dispute Resolution).</p>
                <h5>9.2 Disclaimers</h5>
                <p>It may happen that our platform is down, either for planned maintenance or because something goes down with the site. It may happen that one of our Authors is making misleading statements in their content. It may also happen that we encounter security issues. These are just examples. You accept that you will not have any recourse against us in any of these types of cases where things don’t work out right. In legal, more complete language, <strong>the Services and their content are provided on an “as is” and “as available” basis. We (and our affiliates, suppliers, partners, and agents) make no representations or warranties about the suitability, reliability, availability, timeliness, security, lack of errors, or accuracy of the Services or their content, and expressly disclaim any warranties or conditions (express or implied), including implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement. We (and our affiliates, suppliers, partners, and agents) make no warranty that you will obtain specific results from use of the Services. Your use of the Services (including any content) is entirely at your own risk. Some jurisdictions don’t allow the exclusion of implied warranties, so some of the above exclusions may not apply to you.</strong></p>
                <p>We may decide to cease making available certain features of the Services at any time and for any reason. Under no circumstances will Axl Learn or its affiliates, suppliers, partners or agents be held liable for any damages due to such interruptions or lack of availability of such features.</p>
                <p>We are not responsible for delay or failure of our performance of any of the Services caused by events beyond our reasonable control, like an act of war, hostility, or sabotage; natural disaster; electrical, internet, or telecommunication outage; or government restrictions.</p>
                <h5>9.3 Limitation of Liability</h5>
                <p>There are risks inherent to using our Services, for example, if you access health and wellness content like yoga, and you injure yourself. You fully accept these risks and you agree that you will have no recourse to seek damages against even if you suffer loss or damage from using our platform and Services. In legal, more complete language, <strong>to the extent permitted by law, we (and our group companies, suppliers, partners, and agents) will not be liable for any indirect, incidental, punitive, or consequential damages (including loss of data, revenue, profits, or business opportunities, or personal injury or death), whether arising in contract, warranty, tort, product liability, or otherwise, and even if we’ve been advised of the possibility of damages in advance. Our liability (and the liability of each of our group companies, suppliers, partners, and agents) to you or any third parties under any circumstance is limited to the greater of one hundred dollars ($100) or the amount you have paid us for a course subscription paid before the event giving rise to your claims. Some jurisdictions don’t allow the exclusion or limitation of liability for consequential or incidental damages, so some of the above may not apply to you.</strong></p>
                <h5>9.4 Indemnification</h5>
                <p>If you behave in a way that gets us in legal trouble, we may exercise legal recourse against you. You agree to indemnify, defend (if we so request), and hold harmless Axl Learn, our group companies, and their officers, directors, suppliers, partners, and agents from an against any third-party claims, demands, losses, damages, or expenses (including reasonable attorney fees) arising from (a) the content you post or submit, (b) your use of the Services (c) your violation of these Terms, or (d) your violation of any rights of a third party. Your indemnification obligation will survive the termination of these Terms and your use of the Services.</p>
                <h5>9.5 Governing Law and Jurisdiction</h5>
                <p>When these Terms mention “Axl Learn,” they’re referring to the Axl Learn entity that you’re contracting with. If you’re a Learner, your contracting entity and governing law will generally be determined based on your location.</p>
                <p>If you’re a Learner located anywhere, you’re contracting with Axl Learn India and these Terms are governed by the laws of India, without reference to its choice or conflicts of law principles, and you consent to the exclusive jurisdiction and venue of the courts in Bangalore, India.</p>
                <h5>9.6 Legal Actions and Notices</h5>
                <p>No action, regardless of form, arising out of or relating to this Agreement may be brought by either party more than one (1) year after the cause of action has accrued, except where this limitation cannot be imposed by law.</p>
                <p>Any notice or other communication to be given hereunder will be in writing and given by registered or certified mail return receipt requested, or email (by us to the email associated with your account or by you to notices@Axl Learn.com).</p>
                <h5>9.7 Relationship Between Us</h5>
                <p>You and we agree that no joint venture, partnership, employment, contractor, or agency relationship exists between us.</p>
                <h5>9.8 No Assignment</h5>
                <p>You may not assign or transfer these Terms (or the rights and licenses granted under them). For example, if you registered an account as an employee of a company, your account cannot be transferred to another employee. We may assign these Terms (or the rights and licenses granted under them) to another company or person without restriction. Nothing in these Terms confers any right, benefit, or remedy on any third-party person or entity. You agree that your account is non-transferable and that all rights to your account and other rights under these Terms terminate upon your death.</p>
                <h5>9.9 Sanctions and Export Laws</h5>
                <p>You warrant that you (as an individual or as a representative of any entity on whose behalf you use the Services) aren’t located in, or a resident of, any country that is subject to applicable U.S. trade sanctions or embargoes (such as Cuba, Iran, North Korea, Sudan, Syria, or the Crimea region of Ukraine). You also warrant that you aren’t a person or entity who is named on any U.S. government specially designated national or denied-party list.</p>
                <p>If you become subject to such a restriction during the term of any agreement with Axl Learn, you will notify us within 24 hours, and we will have the right to terminate any further obligations to you, effective immediately and with no further liability to you (but without prejudice to your outstanding obligations to Axl Learn).</p>
                <p>You may not access, use, export, re-export, divert, transfer or disclose any portion of the Services or any related technical information or materials, directly or indirectly, in violation of any United States and other applicable country export control and trade sanctions laws, rules and regulations. You agree not to upload any content or technology (including information on encryption) whose export is specifically controlled under such laws.</p>
              </div>
            </div>
            <div className='terms-points'>
              <h4>10. Dispute Resolution</h4>
              <p>If there’s a dispute, our Support Team is happy to help resolve the issue. Most disputes can be resolved, so before bringing a formal legal case, please first try contacting our Support Team.</p>
              <div className='terms-subpoints'>
                <h5>10.1 Going to Arbitration</h5>
                <p>If we can’t resolve our dispute amicably, you and Axl Learn agree to resolve any claims related to these Terms (or our other legal terms) through final and binding arbitration, regardless of the type of claim or legal theory. If one of us brings a claim in court that should be arbitrated and the other party refuses to arbitrate it, the other party can ask a court to force us both to go to arbitration (compel arbitration). Either of us can also ask a court to halt a court proceeding while an arbitration proceeding is ongoing.</p>
              </div>
            </div>
            <div className='terms-points'>
              <h4>11. Updating These Terms</h4>
              <p>From time to time, we may update these Terms to clarify our practices or to reflect new or different practices (such as when we add new features), and Axl Learn reserves the right in its sole discretion to modify and/or make changes to these Terms at any time. If we make any material change, we will notify you using prominent means, such as by email notice sent to the email address specified in your account or by posting a notice through our Services. Modifications will become effective on the day they are posted unless stated otherwise.</p>
              <p>Your continued use of our Services after changes become effective shall mean that you accept those changes. Any revised Terms shall supersede all previous Terms.</p>
            </div>
            <div className='terms-points'>
              <h4>12. How to Contact Us</h4>
              <p>The best way to get in touch with us is to contact our Support Team. We’d love to hear your questions, concerns, and feedback about our Services.</p>
              <p>Thanks for teaching and learning with us!</p>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default TermsConditions;