import React, { Component } from "react";
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { Modal, SignUpModal, ContactSFModal, ApiModal } from '../modal/modal';
import './header.scss';


import api from '../../../services/Api/Api';

import ChildHeader from "./ChildHeader";
import { updateUser } from "../../../feature/user/UserAction";



class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [],
      module: [],
      isLoading: false,
      isAjax: '',
      searchString: '',
      // logo_render: true,
      qi: 0,
      signModalComplete: false,
      flowdata: {},
      profile: {}
    }
  }

  componentDidMount() {
    this.getHeader();
    document.addEventListener("click", this.handleOnclick);
    api.getProfile().then(res => {
      if (res.data.resCode === 200) {
        this.setState({
          profile: res.data.data.profile
        })
        this.props.setUser(res.data.data.profile);
      }
    }).catch(err => {
      console.log('get profile err in header', err)
    })
  }
  componentWillUnmount() {
    document.removeEventListener("click", this.handleOnclick);
  }



  getHeader() {
    this.setState({
      isLoading: true
    }, () => {
      api.getHeader().then(res => {
        this.setState({
          product: res.data.data.navigation.product,
          module: res.data.data.navigation.module,
          logo: res.data.data.logo,
          logoSmall: res.data.data.logoSmall,
          isLoading: false
        }, () => {
          api.authCheck().then(res => {
            if (res.data.resCode === 200) {
              // console.log('res.data',res.data);
              let qindex = Object.keys(res.data.flowdata).length;
              if (res.data.flow === false && sessionStorage.getItem('isFlow') !== 'done' && qindex < res.data.questions.length) {
                this.setState({
                  signflow: false,
                  questions: res.data.questions,
                  flowdata: res.data.flowdata,
                  qi: qindex
                }, () => {
                  sessionStorage.setItem('isFlow', 'done');
                });

              }

              if (res.data.flow === false && sessionStorage.getItem('isCFlow') !== 'done' && qindex === res.data.questions.length) {
                this.setState({
                  contactInfo: true,
                  flowdata: res.data.flowdata,
                }, () => {
                  sessionStorage.setItem('isCFlow', 'done');
                });

              }

            }
          }).catch(err => {
            console.log('authvalidate err', err)
          });
        });

      }).catch(err => {
        console.log('err', err);
        this.setState({
          isLoading: false
        });
      })
    })

  }


  handleOnclick = () => {
    this.setState({ isAjax: '' });
  }


  preventClose(e) {
    console.log("event ===", e)
    e.stopPropagation();
    if (e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
    }
  }

  serachAndGo(e) {
    e.preventDefault();
    let searchString = this.state.searchString;
    let qValue = searchString.split(' ').join('+');
    window.location.replace('/search?q=' + qValue);
  }


  getMedia(val, stateId) {
    api.getFileFromDrive(val).then(res => {
      if (res.data.resCode === 200) {
        document.getElementById(stateId).src = res.data.data.webContentLink;
      } else {
        document.getElementById(stateId).src = ''
      }
      // }
    }).catch(err => {
      console.log('loader err of ' + stateId, ': ', err)
    })
  }

  getNext(data) {
    let profile = { ...this.state.profile };
    this.setState(prev => ({
      flowdata: {
        ...prev.flowdata,
        [data.category]: data.answers
      }
    }), () => {
      profile.flowdata = this.state.flowdata;
      if (this.state.qi === this.state.questions.length - 1) {
        console.log('profile', profile);
        // reqdata.flowflag = true;
        api.updateProfile({ profile: profile }).then(res => {
          if (res.data.resCode === 200) {
            this.setState({
              signflow: true,
              contactInfo: true
            }, () => {
              sessionStorage.removeItem('isFlow');
              sessionStorage.setItem('isCFlow', 'done');
            });
          }
        }).catch(err => {
          console.error('update profile error signup flow', err)
        })
      } else {
        console.log('profile', profile);
        api.updateProfile({ profile: profile }).then(res => {
          if (res.data.resCode === 200) {
            let lqi = this.state.qi + 1;
            this.setState({
              qi: lqi
            })
          }
        }).catch(err => {
          console.error('update profile error signup flow', err)
        })

      }
    })
  }

  updateContact(data) {
    let profile = { ...this.state.profile };
    profile.contact = {
      phone: data.phone,
      countryCode: data.cc
    };
    profile.flowflag = true;
    profile.flowdata = this.state.flowdata;
    console.log('profile', profile);
    api.updateProfile({ profile: profile }).then(res => {
      if (res.data.resCode === 200) {
        this.setState({
          contactInfo: false,
          resMessage: 'Your profile has been updated successfuly',
          resType: "success",
          resTitle: "Congrates"
        }, () => {
          sessionStorage.removeItem('isCFlow');

        });
      }
    }).catch(err => {
      console.error('update Contact error signup flow', err)
    })
  }
  render() {
    const signupFlow = this.state.signflow === false ? <Modal>
      <SignUpModal
        modalData={{
          resTitle: 'Signup Flow',
          resType: 'primary',
          question: this.state.questions[this.state.qi],

        }}
        dismiss={() => { this.setState({ signflow: true }) }}
        next={(data) => { this.getNext(data) }}
        completed={this.state.signModalComplete}
      />
    </Modal> : null;
    const contactFlow = this.state.contactInfo === true ? <Modal>
      <ContactSFModal

        dismiss={() => { this.setState({ contactInfo: false }) }}
        update={(data) => { this.updateContact(data) }}
      />
    </Modal> : null;
    const apiModal = this.state.resMessage && this.state.resMessage !== '' ? <Modal>
      <ApiModal
        modalData={{
          resTitle: this.state.resTitle,
          resMessage: this.state.resMessage,
          resType: this.state.resType
        }}
        dismiss={() => { this.setState({ resMessage: '', resType: '', resTitle: '' }) }} />
    </Modal> : null;
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg axllearn-navbar" >
          <div className="container-fluid axl-navContainer">
            {/* {this.state.logo_render &&
            <> */}
            <a className="axllearn-logo" href="/"><img id="sitelogo-lrg" src={this.state.logo} alt="logo" /></a>
            <a className="axllearn-logo-sm" href="/"><img id="sitelogo-sm" src={this.state.logoSmall} alt="logo" /></a>
            {/* </>
          } */}
            <button className="btn btn-primary btn-mobileMenu" type="button" data-bs-toggle="offcanvas" data-bs-target="#mobileMenu" aria-controls="mobileMenu">
              <i className="bi bi-list"></i>
            </button>
            {/* <ul className="hd-searchBlock">
              <li>
                <form className="container-fluid" onSubmit={(e) => this.serachAndGo(e)}>
                  <div className="input-group">
                    <input type="text" className="form-control" placeholder="Search by Category, Skill, Course Name" value={this.state.searchString} onChange={(e) => this.setState({ searchString: e.target.value })} />
                    <button className="input-group-text btn btn-primary" type="submit"><i className="bi bi-search"></i></button>
                  </div>
                </form>
              </li>
            </ul> */}
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="mobileMenu" aria-labelledby="mobileMenuLabel">
              <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              <div className="navbar-collapse axl-navContainer-inner" id="navbarSupportedContent">
                {this.state.product.length > 0 &&
                  <ul className="navbar-nav me-auto hd-leftBlock">
                    {this.state.product.map((pe, pi) => {
                      return <li key={uuidv4()} className="nav-item">
                        <a className={`nav-link ${pe.cssClass ? pe.cssClass : ''}`} target={pe.target} href={pe.rUrl}>{pe.label}</a>
                      </li>
                    })}
                  </ul>
                }
                {/* <ul className="navbar-nav me-auto hd-leftBlock">
              <li className="nav-item">
                <NavLink className="nav-item nav-link" to="/courselibrary">My Course Library</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-item nav-link" to="/categories">Categories</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-item nav-link offers-btn" to="/offers">Offers</NavLink>
              </li>
            </ul> */}
                <ChildHeader module={this.state.module} getMedia={this.getMedia} />
                {/* <ul className="hd-rightBlock">
              <li><a href="#" title="Help Email"><i className="bi bi-envelope"></i></a></li>
              <li><a href="#" title="Wishlist"><i className="bi bi-heart"></i></a></li>
              <li><a href="#" title="Your Cart"><i className="bi bi-cart2"></i></a></li>
              <li><a href="#" title="Notifications"><i className="bi bi-bell"></i><span className="badge bell-badge">3</span></a></li>
              <li><a href="#"><i className="bi bi-person-circle"></i></a></li>
              <li><a href="#" className="btn-signup">Signup</a></li>
              <li><a href="#" className="btn-signin">Sign in</a></li>
            </ul> */}
              </div>
            </div>
          </div>
        </nav >
        {signupFlow}
        {contactFlow}
        {apiModal}
      </React.Fragment>
    );
  }
};

const mapDispatchToProps = dispatch => {
  return {
    setUser: (user) => dispatch(updateUser(user))
  }
}

export default connect(null, mapDispatchToProps)(React.memo(Header));
