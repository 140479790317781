import React, { Component } from 'react';
import './careers.scss';

class Careers extends Component {
    render() {
        return (
            <div className="page-content">
                <div class="fluid-banner"><h1>Careers </h1></div>
                <div className="container inner-content">
                    <p>We provide career and learning opportunities for IT freshers in the field of Full Stack technologies. Feel free to apply for Software Developer position if you fit the below criteria -</p>
                    <p><strong>Head - </strong> Eager to learn, develop and be a specialist in the IT industry, with a mindset to solve unique problems. </p>
                    <p><strong>Hands - </strong> Should be itching to make simplified, and organized lines of codes, and be swift in delivering features for the product. </p>
                    <p><strong>Heart - </strong> Should be constantly thinking of innovative ways to add value to yourself, your team, the community, your family, and the product. </p>
                    <p><strong>Feet - </strong> Should be always on the move. Ready to walk, run, and dance while you enjoy the team spirit and seeing the work you do take shape in the product. </p>
                    <p><strong>Lips - </strong> Be ready to communicate and engage with your colleagues, customers, other stakeholders.  </p>
                </div>
            </div>
        )
    }
}

export default Careers;